<adapt-dialog [maxWidth]="400">
    <h3 adapt-dialog-title>Edit coach session</h3>

    <ng-container adapt-dialog-content>
        <label for="selectCoach">Coach</label>
        <adapt-select-coach id="selectCoach"
                            [(coach)]="coachSession.practitioner"
                            [required]="coachIsRequired"></adapt-select-coach>

        <label for="selectStatus"
               class="mt-3">Status</label>
        <dx-select-box [items]="CoachSessionStatuses"
                       [(value)]="coachSession.status"
                       displayExpr="name"
                       valueExpr="status"></dx-select-box>
    </ng-container>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [discardConfirmationInstance]="instance"
                                   [saveIsDisabled]="coachIsRequired && !coachSession.practitioner"></adapt-standard-dialog-buttons>
</adapt-dialog>
