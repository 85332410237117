import { Component, Injector, OnInit } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { IWorkflowRating } from "@common/ADAPT.Common.Model/organisation/workflow-rating";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { PageRouteBuilder } from "@common/route/page-route-builder";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { DateFormats } from "@common/ux/date-formats";
import { PathwayReviewService } from "@common/workflow/components/pathway-review.service";
import { lastValueFrom } from "rxjs";
import { OrganisationsService } from "../../features/organisations/organisations.service";
import { PathwayRatingEditComponent } from "./pathway-review-edit/pathway-rating-edit.component";

@Component({
    selector: "adapt-pathway-review-audit",
    templateUrl: "./pathway-rating-audit.component.html",
    styleUrl: "./pathway-rating-audit.component.scss",
})
export class PathwayRatingAuditComponent extends BaseRoutedComponent implements OnInit {
    public readonly dateFormat = DateFormats.globalize.shortdatetime;

    public reviews: IWorkflowRating[] = [];
    public organisations?: Organisation[];

    public constructor(
        injector: Injector,
        organisationService: OrganisationsService,
        private dialogService: AdaptCommonDialogService,
        public pathwayReviewService: PathwayReviewService,
    ) {
        super(injector);
        organisationService.getOrganisations().subscribe((orgs) => {
            this.organisations = orgs;
        });
    }

    public async ngOnInit() {
        await this.updateData();
        this.notifyActivated();
    }

    public async updateData() {
        this.reviews = await lastValueFrom(this.pathwayReviewService.getAll());
    }

    public getOrganisation(organisationId: any) {
        return this.organisations?.find((o) => o.organisationId === organisationId);
    }

    @Autobind
    public editRating(rating: IWorkflowRating) {
        this.dialogService.open(PathwayRatingEditComponent, rating).subscribe(() => this.updateData());
    }
}

export const pathwayReviewPageRoute = new PageRouteBuilder()
    .usingNgComponent("adapt-pathway-review-audit", PathwayRatingAuditComponent)
    .requiresLogin()
    .atUrl("/tools/pathway-rating-audit")
    .withTitle("Pathway Rating Audit")
    .build();
