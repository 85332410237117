import { Injectable } from "@angular/core";
import { Person, PersonBreezeModel } from "@common/ADAPT.Common.Model/person/person";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { RouteService } from "@common/route/route.service";
import { defer, Observable } from "rxjs";
import { personPageRoute } from "./person-page/person-page.route";

@Injectable({
    providedIn: "root",
})
export class PersonService {
    public static readonly Id = "ADAPT.Nimbus.People.Person.Service";

    constructor(
        private routeService: RouteService,
        private commonDataService: CommonDataService,
    ) {
    }

    public getRouteToPersonDetailPage(person: Person) {
        const params = {
            personId: person.personId,
        };

        return defer(() => this.routeService.getControllerRoute(personPageRoute.id, params));
    }

    public getPerson(personId: number): Observable<Person | undefined> {
        return this.commonDataService.getById(PersonBreezeModel, personId);
    }
}
