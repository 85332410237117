import { Component, OnDestroy, OnInit } from "@angular/core";
import { UserType } from "@common/ADAPT.Common.Model/embed/user-type";
import { ConnectionType } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { Organisation, OrganisationBreezeModel } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Practitioner, PractitionerBreezeModel } from "@common/ADAPT.Common.Model/practitioner/practitioner";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { ChangeManagerService } from "@common/ux/change-manager/change-manager.service";
import { OrganisationsService } from "app/features/organisations/organisations.service";
import { IAddCoach, StakeholderServicesService } from "app/features/tools/stakeholder-services.service";
import { EMPTY, forkJoin, lastValueFrom } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { INewPersonModel } from "../new-coach.interface";

export enum StepName {
    SetPerson = "Select/Create Person",
    CoachDetails = "Coach Details",
    SetSandbox = "Sandbox Organisations",
    Confirmation = "Confirmation",
}

@Component({
    selector: "adapt-new-coach-dialog",
    templateUrl: "./new-coach-dialog.component.html",
    styleUrls: ["./new-coach-dialog.component.scss"],
})
export class NewCoachDialogComponent extends BaseDialogComponent<any> implements OnInit, OnDestroy {
    public readonly dialogName = "NewCoach";

    public stepNames: StepName[] = [StepName.SetPerson, StepName.CoachDetails, StepName.SetSandbox, StepName.Confirmation];
    public currentStepIndex = 0;

    public get currentStep() {
        return this.stepNames[this.currentStepIndex];
    }

    public completed = false;

    public error?: string;

    public person?: Person;
    public temporaryPerson?: INewPersonModel;
    public practitioner?: Practitioner;
    public createdOrganisation?: Organisation;
    public joinOrganisation?: Organisation;
    public readonly StepName = StepName;

    private cleanup?: (() => void);

    constructor(
        private commonDataService: CommonDataService,
        private stakeholderServices: StakeholderServicesService,
        private orgService: OrganisationsService,
        changeManager: ChangeManagerService,
    ) {
        super();

        this.cleanup = changeManager.registerCleanupFunction(async () => {
            await this.cleanUpOrganisation();
            await this.cleanUpPractitioner();
        });
    }

    public async ngOnInit() {
        this.practitioner = await this.createPractitioner();
    }

    public async ngOnDestroy() {
        super.ngOnDestroy();

        if (this.cleanup) {
            this.cleanup();
        }
    }

    public async onPersonChange(person?: Person) {
        // person is already a coach, load their details so we can edit them
        if (person) {
            const coach = await this.orgService.getCoachByPersonId(person.personId);
            if (coach) {
                // need the full practitioner else the coach grid will error out
                const practitioner = await lastValueFrom(this.commonDataService.getById(PractitionerBreezeModel, coach.practitionerId, true));
                if (practitioner) {
                    await this.cleanUpPractitioner();
                    this.practitioner = practitioner;
                }
            }
        } else {
            this.practitioner = await this.createPractitioner();
        }
    }

    public next() {
        this.currentStepIndex++;
    }

    public previous() {
        this.currentStepIndex--;
    }

    public isCurrentStep(stepName: StepName) {
        return this.currentStep === stepName;
    }

    public get canGoNext() {
        switch (this.currentStep) {
            case StepName.SetPerson:
                if (this.person) {
                    return this.person.entityAspect.validateEntity();
                } else if (this.temporaryPerson) {
                  return this.temporaryPerson.valid;
                }
                return false;
            case StepName.CoachDetails:
              return this.practitioner?.entityAspect.validateEntity();
            case StepName.SetSandbox:
                // custom validation errors are not picked up by validateEntity
              return !this.createdOrganisation?.entityAspect.hasValidationErrors;
            default:
                return false;
        }
    }

    @Autobind
    public addCoach() {
        const details: IAddCoach = {
            StartDate: this.practitioner!.startDate,
            EndDate: this.practitioner!.endDate,
            Notes: this.practitioner!.notes,
            PersonId: this.person?.personId,
            PartnerId: this.practitioner?.partner?.partnerId,
        };

        if (this.temporaryPerson) {
            details.UserDetails = {
                firstName: this.temporaryPerson.firstName,
                lastName: this.temporaryPerson.lastName,
                email: this.temporaryPerson.email,
                roleIds: [],
                connectionType: ConnectionType.Coach,
                userType: UserType.Coach,
                hasAccess: true,
            };
        }

        if (this.createdOrganisation) {
            details.NewOrganisation = {
                OrganisationName: this.createdOrganisation.name,
                UrlIdentifier: this.createdOrganisation.urlIdentifier,
            };
        }

        if (this.joinOrganisation) {
            details.OrganisationId = this.joinOrganisation.organisationId;
        }

        this.error = undefined;
        return this.stakeholderServices.createCoach(details).pipe(
            catchError((err) => {
                if (err && err.ModelState) {
                    this.error = ErrorHandlingUtilities.getModelStateError(err.ModelState);
                }
                if (!this.error) {
                    this.error = ErrorHandlingUtilities.getHttpResponseMessage(err);
                }
                return EMPTY;
            }),
            switchMap(async (data) => {
                await this.cleanUpOrganisation();
                await this.cleanUpPractitioner();
                return data;
            }),
            switchMap((data) => {
                return forkJoin([
                    ...data.orgIds.map((o) => this.commonDataService.getById(OrganisationBreezeModel, o)),
                    // force remote grab so navProps are fully primed
                    this.commonDataService.getById(PractitionerBreezeModel, data.PractitionerId, true),
                ]);
            }),
            tap(() => this.completed = true),
        );
    }

    @Autobind
    public async cancel() {
        if (!this.completed) {
            await this.cleanUpOrganisation();
            await this.cleanUpPractitioner();
        }

        this.resolve(null);
    }

    private async createPractitioner() {
        await this.cleanUpPractitioner();
        return lastValueFrom(this.commonDataService.create(PractitionerBreezeModel, {
            startDate: new Date(),
        }));
    }

    private async cleanUpPractitioner() {
        if (this.practitioner && !this.practitioner.isComplete) {
            await lastValueFrom(this.commonDataService.rejectChanges(this.practitioner));
        }
    }

    private async cleanUpOrganisation() {
        if (this.createdOrganisation) {
            await lastValueFrom(this.commonDataService.rejectChanges(this.createdOrganisation));
        }
    }
}
