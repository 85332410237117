import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { ContactType, ContactTypeMetadata } from "@common/ADAPT.Common.Model/embed/contact-type";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ActiveEntityUtilities } from "@common/lib/data/active-entity-utilities";
import { BreezeModelUtilities } from "@common/lib/data/breeze-model-utilities";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { DxUtilities } from "@common/lib/utilities/dx-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { DxGridWrapperHelper } from "@common/ux/base.component/dx-component-wrapper-builder";
import { DateFormats } from "@common/ux/date-formats";
import { OrganisationsService } from "app/features/organisations/organisations.service";
import { InitializedEvent } from "devextreme/ui/data_grid";
import { forkJoin, lastValueFrom } from "rxjs";
import { PeopleService } from "../people.service";

interface IPersonWithContactDetails extends Person {
    [key: string]: string | any;
}

@Component({
    selector: "adapt-people-list",
    templateUrl: "./people-list.component.html",
})
export class PeopleListComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() public filterForOrganisationId?: number;

    public ContactType = ContactType;
    public DateFormats = DateFormats;
    public AllContactTypeMetadata = ContactTypeMetadata.All;

    public dxGridWrapperHelper: DxGridWrapperHelper;

    public people?: IPersonWithContactDetails[];

    constructor(
        private peopleService: PeopleService,
        private organisationsService: OrganisationsService,
        elementRef: ElementRef,
    ) {
        super();

        this.dxGridWrapperHelper = new DxGridWrapperHelper("adapt-people-list", jQuery(elementRef.nativeElement));
    }

    public ngOnInit() {
        this.dxGridWrapperHelper.saveGridState(this.filterForOrganisationId ? `-${this.filterForOrganisationId}` : "-all");
        // setColumnsReady first before loading so that the grid at least shows up without data and show a loading panel instead of blank panel
        this.dxGridWrapperHelper.callGrid((grid) => grid.setColumnsReady());

        forkJoin([
            this.organisationsService.getOrganisations(),
            // TODO: scope these to organisation if provided...
            this.peopleService.getAllPersonDetails(),
            this.peopleService.getAllPersonContacts(),
            this.peopleService.getAllConnections(),
        ]).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.updateData());
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.filterForOrganisationId
            && !changes.filterForOrganisationId.isFirstChange()
            && changes.filterForOrganisationId.currentValue !== changes.filterForOrganisationId.previousValue) {
            this.updateData();
        }
    }

    public onGridInitialized(e: InitializedEvent) {
        this.dxGridWrapperHelper.initialiseGrid(e);
    }

    @Autobind
    public showColumnChooser() {
        this.dxGridWrapperHelper.callGrid((grid) => grid.component.showColumnChooser());
    }

    @Autobind
    public exportAllData() {
        this.dxGridWrapperHelper.callGrid((grid) => DxUtilities.exportGridToExcel("People", grid.component));
    }

    public resetColumns() {
        this.dxGridWrapperHelper.callGrid((grid) => grid.resetState());
    }

    public async updateData() {
        const organisationId = this.filterForOrganisationId;
        this.setData(await lastValueFrom(organisationId
            ? this.peopleService.getPeopleForOrganisationId(organisationId)
            : this.peopleService.getAllPeople()));
    }

    private setData(people: IPersonWithContactDetails[]) {
        for (const person of people) {
            for (const contactTypeMetadata of this.AllContactTypeMetadata) {
                for (const personContact of person.getContactsOfType(contactTypeMetadata.type)) {
                    const contactDetails = person[contactTypeMetadata.name] ?? [];
                    person[contactTypeMetadata.name] = [...contactDetails, personContact.value];
                }
            }

            if (this.filterForOrganisationId) {
                for (const personDetail of person.personDetails) {
                    if (personDetail.organisationId === this.filterForOrganisationId) {
                        person[personDetail.name] = personDetail.value;
                    }

                }
            }
        }

        people.sort(SortUtilities.getSortByFieldFunction("fullName"));

        this.people = people;
    }

    public getContactsOfTypeByName(person: Person, contactTypeName: string) {
        return person.personContacts
            .filter((contact) => ContactTypeMetadata.ByType[contact.contactType].name === contactTypeName);
    }

    public getUniqueActiveOrganisationsFromConnections(connections: Connection[]) {
        const activeOrganisations = connections.filter((c) => c.isActive())
            .map((c) => c.organisation);
        return ArrayUtilities.distinct(activeOrganisations);
    }

    @Autobind
    public calculateActiveCellValue(person: Person) {
        const organisationId = this.filterForOrganisationId;
        return person.connections.some((connection) => organisationId
            ? ActiveEntityUtilities.isCurrentForOrganisation(connection, organisationId)
            : ActiveEntityUtilities.isActive(connection));
    }

    @Autobind
    public calculateConnectionTypeColumnValue(person: Person) {
        const connections = person.connections.filter((c) => c.organisationId === this.filterForOrganisationId);
        const connection = BreezeModelUtilities.getLatestOrActive(connections);
        return connection?.connectionType;
    }

    @Autobind
    public calculateUserTypeColumnValue(person: Person) {
        const connections = person.connections.filter((c) => c.organisationId === this.filterForOrganisationId);
        const connection = BreezeModelUtilities.getLatestOrActive(connections);
        return connection?.userType;
    }

    @Autobind
    public calculateHasPlatformAccessColumnValue(person: Person) {
        const connections = person.connections.filter((c) => c.organisationId === this.filterForOrganisationId);
        const connection = BreezeModelUtilities.getLatestOrActive(connections);
        return connection?.hasAccess;
    }

    @Autobind
    public calculateOrganisationColumnValue(person: Person) {
        return this.getUniqueActiveOrganisationsFromConnections(person.connections)
            .map((o) => o.name)
            .join(", ");
    }
}
