<adapt-dialog [maxWidth]="800">
    <h3 adapt-dialog-title>Select Deep Dive Image</h3>

    <ng-container adapt-dialog-content>
        <dx-file-manager class="deep-dive-image-selector"
                         [fileSystemProvider]="fileSystemProvider"
                         (onSelectedFileOpened)="onSelectedFileOpened($event)"
                         (onSelectionChanged)="onSelectionChanged($event)"
                         [customizeDetailColumns]="customizeDetailColumns"
                         [allowedFileExtensions]="['.png', '.jpg', '.jpeg']"
                         height="100%"
                         selectionMode="single">
            <dxo-upload [chunkSize]="maxAllowedFileSize"
                        [maxFileSize]="maxAllowedFileSize" ></dxo-upload>
            <dxo-permissions [create]="false"
                             [copy]="false"
                             [move]="false"
                             [delete]="false"
                             [rename]="false"
                             [upload]="true"
                             [download]="false">
            </dxo-permissions>
            <dxo-item-view mode="thumbnails"
                           [showParentFolder]="false"></dxo-item-view>
        </dx-file-manager>
    </ng-container>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   saveText="Select & Close"
                                   [discardConfirmationInstance]="instance"></adapt-standard-dialog-buttons>
</adapt-dialog>