<div>
    <div role="alert"
         class="alert alert-info">
        Orphan People are those people with no connections to ANY organisation (mistakenly added or not cleaned up properly)
    </div>

    <button class="btn btn-default"
            [adaptBlockingClick]="doCheck">Check for Orphan People</button>

    <hr />

    <div *ngIf="people">
        <table class="table table-bordered table-striped table-hover table-responsive">
            <thead>
                <tr>
                    <td>PersonId</td>
                    <td>Name</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let user of people">
                    <td>{{user.Id}}</td>
                    <td>{{user.Value}}</td>
                </tr>
            </tbody>
        </table>

        <hr />

        <div role="alert"
             *ngIf="people.length"
             class="alert alert-warning">
            <h3>*** Note: This operation is not recoverable. ***</h3>
        </div>

        <button *ngIf="people.length"
                [adaptBlockingClick]="doDelete"
                adaptButton="delete"> Orphan People</button>

    </div>

    <div role="alert"
         class="alert alert-danger"
         *ngIf="error">{{error}}</div>
</div>
