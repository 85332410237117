export enum CoachType {
    Coach = "Coach",
    Catalyst = "Catalyst",
    TrainingCoach = "TrainingCoach"
}

export class CoachTypeMetadata {
    public static readonly Coach = new CoachTypeMetadata(CoachType.Coach, "Coach");
    public static readonly Catalyst = new CoachTypeMetadata(CoachType.Catalyst, "Catalyst");
    public static readonly TrainingCoach = new CoachTypeMetadata(CoachType.TrainingCoach, "Training Coach");

    public static readonly All = [
        CoachTypeMetadata.Coach,
        CoachTypeMetadata.Catalyst,
        CoachTypeMetadata.TrainingCoach,
    ];

    public static readonly ByType: { [type in CoachType]: CoachTypeMetadata } = {
        [CoachType.Coach]: CoachTypeMetadata.Coach,
        [CoachType.Catalyst]: CoachTypeMetadata.Catalyst,
        [CoachType.TrainingCoach]: CoachTypeMetadata.TrainingCoach,
    };

    public static readonly ByIntValue: { [value: number]: CoachTypeMetadata } = {
        0: CoachTypeMetadata.Coach,
        1: CoachTypeMetadata.Catalyst,
        2: CoachTypeMetadata.TrainingCoach,
    };

    private constructor(
        public readonly type: CoachType,
        public readonly name: string,
    ) { }
}
