<adapt-dialog [maxWidth]="600">
    <h3 adapt-dialog-title>{{isNew ? 'Add' : 'Edit'}} Deep Dive</h3>

    <ng-container adapt-dialog-content>
        <dx-validation-group #validationGroup>
            <form role="form">
                <div class="form-group">
                    <label for="title">Title</label>
                    <dx-text-box id="title"
                                 name="title"
                                 (onValueChanged)="validateGroup()"
                                 [(value)]="deepDive!.title">
                        <dx-validator>
                            <dxi-validation-rule [adaptEntity]="deepDive"
                                                 adaptEntityProperty="title"></dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>

                <div *ngIf="isNew"
                     class="form-group">
                    <label for="type">Type</label>
                    <dx-radio-group id="type"
                                    name="type"
                                    displayExpr="name"
                                    valueExpr="id"
                                    layout="horizontal"
                                    [items]="deepDiveTypeMapping"
                                    (onValueChanged)="onTypeChanged($event)"
                                    [(value)]="deepDive!.type">
                        <dx-validator>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dx-validator>
                    </dx-radio-group>
                </div>

                <div *ngIf="deepDive!.type !== deepDiveType.Scorm"
                     class="form-group">
                    <label for="location">Location</label>
                    <p class="text-muted m-0">Enter a URL from YouTube, Vimeo or other video hosting service.</p>
                    <dx-text-box id="location"
                                 name="location"
                                 placeholder="Paste video URL here"
                                 (onValueChanged)="onLocationChanged()"
                                 [(value)]="deepDive!.location">
                        <dx-validator>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                            <dxi-validation-rule [adaptEntity]="deepDive"
                                                 adaptEntityProperty="location"></dxi-validation-rule>
                            <dxi-validation-rule type="custom"
                                                 [validationCallback]="validateLocationIsUrl"
                                                 message="Must be a valid URL (beginning with http/https)"></dxi-validation-rule>
                            <dxi-validation-rule type="custom"
                                                 [validationCallback]="validateLocationIsUnique"
                                                 message="This location is already in use"></dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>

                    <div class="mt-4"
                         *ngIf="!!deepDive!.location && safeResourceLocation">
                        <label>Location Preview</label>
                        <div>
                            <iframe class="iframe-video"
                                    [src]="safeResourceLocation"></iframe>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label for="group">User Group</label>
                    <dx-select-box id="group"
                                   name="group"
                                   [items]="deepDiveGroupMapping"
                                   displayExpr="name"
                                   valueExpr="id"
                                   [showClearButton]="false"
                                   (onValueChanged)="validateGroup()"
                                   [(value)]="deepDive!.group">
                        <dx-validator>
                            <dxi-validation-rule type="custom"
                                                 [validationCallback]="validateGroupIsSelected"
                                                 message="You must select a user group"></dxi-validation-rule>
                        </dx-validator>
                    </dx-select-box>
                </div>

                <div class="row form-group">
                    <div class="col-12 col-md-6">
                        <label for="durationMinimum">Minimum Duration (minutes)</label>
                        <dx-number-box id="durationMinimum"
                                       name="durationMinimum"
                                       [showSpinButtons]="true"
                                       [showClearButton]="true"
                                       (onValueChanged)="validateGroup()"
                                       (valueChange)="!$event && (deepDive!.durationMaximum = undefined)"
                                       [(value)]="deepDive!.durationMinimum">
                        </dx-number-box>
                    </div>

                    <div class="col-12 col-md-6">
                        <label for="durationMaximum">Maximum Duration (minutes)</label>
                        <dx-number-box id="durationMaximum"
                                       name="durationMaximum"
                                       [min]="deepDive!.durationMinimum"
                                       [disabled]="!deepDive!.durationMinimum"
                                       [showSpinButtons]="true"
                                       [showClearButton]="true"
                                       (onValueChanged)="validateGroup()"
                                       [(value)]="deepDive!.durationMaximum">
                        </dx-number-box>
                    </div>
                </div>
            </form>
        </dx-validation-group>
    </ng-container>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="saveDisabled"
                                   [discardConfirmationInstance]="instance">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
