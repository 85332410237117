import { Injectable, Injector } from "@angular/core";
import { UserType } from "@common/ADAPT.Common.Model/embed/user-type";
import { Connection, ConnectionBreezeModel, RoleInOrganisation } from "@common/ADAPT.Common.Model/organisation/connection";
import { ConnectionType } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { Organisation, OrganisationBreezeModel } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Role, RoleBreezeModel } from "@common/ADAPT.Common.Model/organisation/role";
import { RoleConnection, RoleConnectionBreezeModel } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { RoleType, RoleTypeBreezeModel } from "@common/ADAPT.Common.Model/organisation/role-type";
import { RoleTypeCode } from "@common/ADAPT.Common.Model/organisation/role-type-code";
import { PersonBreezeModel } from "@common/ADAPT.Common.Model/person/person";
import { Practitioner, PractitionerBreezeModel } from "@common/ADAPT.Common.Model/practitioner/practitioner";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { BaseService } from "@common/service/base.service";
import { defer, lastValueFrom, Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class CoachService extends BaseService {

    constructor(
        injector: Injector,
    ) {
        super(injector);
    }

    public getAllCoaches() {
        return this.commonDataService.getAll(PractitionerBreezeModel);
    }

    public getCoach(id: number) {
        return this.commonDataService.getById(PractitionerBreezeModel, id).pipe(
            switchMap((coach) => this.commonDataService.getById(PersonBreezeModel, coach!.personId).pipe(
                map(() => coach),
            )),
        );
    }

    public getAllOrganisationsForCoach(coach: Practitioner): Observable<Organisation[]> {
        return this.commonDataService.getAll(OrganisationBreezeModel)
            .pipe(map((organisations) => organisations.filter((org) =>
                coach.person.connections.some((connection) => connection.organisationId === org.organisationId
                    && connection.isCoachConnection()
                    && connection.isActive()))));
    }

    public getAllCoachConnectionsForOrganisation(organisationId: number): Observable<Connection[]> {
        const predicate = new MethodologyPredicate<Connection>("organisationId", "==", organisationId)
            .and(new MethodologyPredicate<Connection>("connectionType", "==", ConnectionType.Coach));

        return this.commonDataService.getByPredicate(ConnectionBreezeModel, predicate);
    }

    public endCoachConnection(connection: Connection) {
        const endDate = new Date();
        connection.endDate = endDate;
        return this.commonDataService.saveEntities([connection]); //The roleConnections are automatically updated on the backend.
    }

    public createCoachConnection(organisation: Organisation) {
        const startDate = new Date();

        return defer(async () => {
            // create the connection to the org
            const connectionDefaults: Partial<Connection> = {
                organisationId: organisation.organisationId,
                connectionType: ConnectionType.Coach,
                userType: UserType.Coach,
                startDate,
                hasAccess: true,
                roleInOrganisation: RoleInOrganisation.Employee,
            };

            const coachConnection = await lastValueFrom(this.commonDataService.create(ConnectionBreezeModel, connectionDefaults));

            // prime role types so that the filter for the coach roles work when fetching local cache
            const orgPredicate = new MethodologyPredicate<RoleType>("organisationId", "==", organisation.organisationId);
            await lastValueFrom(this.commonDataService.getByPredicate(RoleTypeBreezeModel, orgPredicate));

            // find the coach role that we will attach to a new role connection
            const predicate = new MethodologyPredicate<Role>("roleType.code", "==", RoleTypeCode.Coach)
                .and(orgPredicate);
            const key = `coachRoles${organisation.organisationId}`;
            const coachRoles = await lastValueFrom(this.commonDataService.getWithOptions(RoleBreezeModel, key, {
                predicate,
                navProperty: "",
            }));

            // create the role connection
            const roleConnectionDefaults: Partial<RoleConnection> = {
                roleId: coachRoles[0].roleId,
                connectionId: coachConnection.connectionId,
                startDate,
            };

            await lastValueFrom(this.commonDataService.create(RoleConnectionBreezeModel, roleConnectionDefaults));

            // return the newly created connection
            return coachConnection;
        });
    }

    public deleteCoachConnection(connection: Connection) {
        return this.commonDataService.remove(connection).pipe(
            switchMap(() => this.saveEntities(connection)),
        );
    }
}
