import { Component, Input } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { CommonRouteConstants } from "@common/route/common-route.constants";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { StakeholderServicesService } from "app/features/tools/stakeholder-services.service";
import { lastValueFrom } from "rxjs";

@Component({
    selector: "adapt-activate-account",
    templateUrl: "./activate-account.component.html",
})
export class ActivateAccountComponent {
    @Input() public person!: Person;

    public error?: string;
    public organisation?: Organisation;

    constructor(
        private stakeholderService: StakeholderServicesService,
        private dialogService: AdaptCommonDialogService,
    ) { }

    @Autobind
    public async copyActivationLink() {
        try {
            this.error = undefined;
            const result = await this.stakeholderService.getActivationLink({
                organisationId: this.organisation?.organisationId,
                personId: this.person.personId,
                setInitialPasswordRedirectUrl: this.setUrlBase(CommonRouteConstants.SetInitialPassword),
                welcomeRedirectUrl: this.setUrlBase(CommonRouteConstants.PersonalDashboard),
            });

            await navigator.clipboard.writeText(result);
            await lastValueFrom(this.dialogService.showMessageDialog("Activation Link", "Link copied to clipboard!"));
        } catch (e) {
            this.error = ErrorHandlingUtilities.getHttpResponseMessage(e);
        }
    }

    @Autobind
    public filterOrgs(organisation: Organisation) {
        return this.person.connections.some((i) => i.organisationId === organisation.organisationId && i.isActive() && i.hasAccess);
    }

    private setUrlBase(url: string) {
        return AdaptClientConfiguration.FrontEndBaseUri + url;
    }
}
