import { Component, Input } from "@angular/core";
import { CoachTypeMetadata } from "@common/ADAPT.Common.Model/practitioner/coach-type";
import { Practitioner } from "@common/ADAPT.Common.Model/practitioner/practitioner";

@Component({
    selector: "adapt-new-coach-details",
    templateUrl: "./new-coach-details.component.html",
})
export class NewCoachDetailsComponent {
    @Input() public practitioner!: Practitioner;

    public allTypes = CoachTypeMetadata.All;
}
