import { NgModule } from "@angular/core";
import { mapToCanActivate, RouterModule, Routes } from "@angular/router";
import { IdentityUxModule } from "@common/identity/ux/identity-ux.module";
import { AdaptCommonPagesModule } from "@common/lib/pages/pages.module";
import { environment } from "environments/environment";
import { AdaptAuditModule } from "./audit/audit.module";
import { BaseGuard } from "./base.guard";
import { AdaptCoachModule } from "./features/coaches/coach.module";
import { AdaptOrganisationsModule } from "./features/organisations/organisations.module";
import { AdaptPartnerModule } from "./features/partners/partner.module";
import { AdaptPeopleModule } from "./features/people/people.module";
import { AdaptToolsModule } from "./features/tools/tools.module";
import { AdaptDeepDiveModule } from "./features/workflow/deep-dive/deep-dive.module";
import { landingPageRoute } from "./services/landing-page/landing-page.route";

const routes: Routes = [
    ...IdentityUxModule.Routes,
    ...AdaptCommonPagesModule.Routes,
    ...AdaptCoachModule.Routes,
    ...AdaptOrganisationsModule.Routes,
    ...AdaptPartnerModule.Routes,
    ...AdaptPeopleModule.Routes,
    ...AdaptToolsModule.Routes,
    ...AdaptAuditModule.Routes,
    ...AdaptDeepDiveModule.Routes,
    ...landingPageRoute.routes,
    { path: "", pathMatch: "full", children: [], canActivate: mapToCanActivate([BaseGuard]) },
    { path: "**", redirectTo: "" }, // any other pages -> go to default
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: !environment.production })],
    exports: [RouterModule],
})
export class AppRoutingModule { }
