import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { MeetingAgendaTemplate, MeetingAgendaTemplateBreezeModel } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-template";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IDxSelectBoxValueChangedEvent } from "@common/ux/dx.types";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { DxSelectBoxComponent } from "devextreme-angular";
import { lastValueFrom } from "rxjs";

@Component({
    selector: "adapt-select-meeting-agenda-template",
    templateUrl: "./select-meeting-agenda-template.component.html",
})
export class SelectMeetingAgendaTemplateComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() public organisation?: Organisation;
    @Input() public team?: Team;
    @Output() public meetingAgendaTemplateChange = new EventEmitter<MeetingAgendaTemplate>();

    public meetingAgendaTemplateId?: number;
    public dataSource?: DataSource;

    private meetingAgendaTemplate?: MeetingAgendaTemplate;
    @ViewChild(DxSelectBoxComponent) private selectBoxComponent?: DxSelectBoxComponent;

    constructor(
        private commonDataService: CommonDataService,
    ) {
        super();
    }

    public async ngOnInit(): Promise<void> {
        await this.fetchMeetingAgendaTemplates();
    }

    public async ngOnChanges(changes: SimpleChanges) {
        if (changes.organisation || changes.team) {

            this.meetingAgendaTemplateId = undefined;
            this.meetingAgendaTemplate = undefined;
            this.meetingAgendaTemplateChange.emit(this.meetingAgendaTemplate);
            this.selectBoxComponent?.instance.reset();

            await this.fetchMeetingAgendaTemplates();
        }
    }

    private async fetchMeetingAgendaTemplates() {
        if (!this.organisation) {
            return;
        }

        const predicate = new MethodologyPredicate<MeetingAgendaTemplate>("organisationId", "==", this.organisation.organisationId);

        const meetingAgendaTemplates = await lastValueFrom(this.commonDataService.getByPredicate(MeetingAgendaTemplateBreezeModel, predicate));
        this.dataSource = new DataSource({
            store: new ArrayStore({
                data: meetingAgendaTemplates,
                key: "meetingAgendaTemplateId",
            }),
            filter: (agenda: MeetingAgendaTemplate) => { return this.team ? agenda.teamId === this.team.teamId : true; },
        });
    }

    public async onMeetingAgendaTemplateChanged(e: IDxSelectBoxValueChangedEvent<number>) {
        if (e.value === e.previousValue) {
            return;
        }

        if (e.value) {
            this.meetingAgendaTemplate = await lastValueFrom(this.commonDataService.getById(MeetingAgendaTemplateBreezeModel, e.value));

        } else {
            this.meetingAgendaTemplate = undefined;
            this.meetingAgendaTemplateId = undefined;
        }

        this.meetingAgendaTemplateChange.emit(this.meetingAgendaTemplate);
    }
}
