import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptToolbarModule } from "@common/shell/toolbar/toolbar.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptEditSimpleValueBreezeEntityDialogModule } from "@common/ux/edit-simple-value-breeze-entity-dialog/edit-simple-value-breeze-entity-dialog.module";
import { AdaptEditorViewModule } from "@common/ux/html-editor/editor-view.module";
import { DxDataGridModule, DxSelectBoxModule, DxTextBoxModule } from "devextreme-angular";
import { AdaptCoachSharedModule } from "../coaches/coach-shared.module";
import { PartnerListPageComponent } from "./partner-list-page/partner-list-page.component";
import { displayPartnerListRoute } from "./partner-list-page/partner-list-page-route";
import { SelectPartnerComponent } from "./select-partner/select-partner.component";

@NgModule({
    imports: [
        CommonModule,
        DxDataGridModule,
        DxSelectBoxModule,
        DxTextBoxModule,
        AdaptCommonDialogModule,
        AdaptButtonModule,
        AdaptToolbarModule,
        AdaptCoachSharedModule,
        AdaptEditSimpleValueBreezeEntityDialogModule,
        AdaptEditorViewModule,
    ],
    declarations: [
        PartnerListPageComponent,
        SelectPartnerComponent,
    ],
    exports: [
        PartnerListPageComponent,
        SelectPartnerComponent,
    ],
})
export class AdaptPartnerModule {
    public static readonly Routes = [
        ...displayPartnerListRoute.routes,
    ];
}
