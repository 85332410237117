<div class="row"
     *ngIf="coach$ | async as coach">

    <adapt-display-person-details class="col-12"
                                  [person]="coach.person"></adapt-display-person-details>

    <div class="col-12">
        <div class="card card-info">
            <div class="card-header">
                <h3>Coach Details</h3>
                <div class="card-header-buttons">
                    <button adaptButton="edit"
                            [adaptBlockingClick]="editCoach"></button>
                </div>
            </div>
            <div class="card-body">
                <dl>
                    <label>Active</label>
                    <p>{{coach.startDate | adaptDate }} - {{coach.endDate | adaptDate}}</p>

                    <ng-container *ngIf="coach.notes">
                        <label>Notes:</label>
                        <p [froalaView]="coach.notes"></p>
                    </ng-container>

                    <label>Subscribing</label>
                    <p>{{coach.isSubscribing}}</p>

                    <label>Helpjuice Access</label>
                    <p>{{coach.person.preferences ? documentationSubscriptionLevelLabeller.label(coach.person.preferences.documentationSubscriptionLevel) : 'Unconfigured'}}
                    </p>

                    <label>Coach Type</label>
                    <p>{{displayCoachType(coach!.coachType)}}</p>

                    <label class="mb-2">Accreditations</label>
                    <div class="form-group">
                        <ul *ngIf="coach.isAccreditedRBCoach || coach.isAccreditedOwnerMentor; else noAccreditations"
                            class="list-content">
                            <li *ngIf="coach.isAccreditedRBCoach">Resilient Business Coach</li>
                            <li *ngIf="coach.isAccreditedOwnerMentor">Owner Mentor</li>
                        </ul>
                        <ng-template #noAccreditations>Coach has no accreditations</ng-template>
                    </div>

                    <div *ngIf="coach.partner">
                        <hr />
                        <label>Strategic Partner</label>
                        <p>{{coach.partner.name}}</p>
                    </div>
                </dl>
            </div>
        </div>
    </div>

    <div class="col-12">
        <div class="card card-info">
            <div class="card-header">
                <h3>Active Organisations</h3>
            </div>
            <div class="card-body">
                <adapt-display-simple-organisation-list [coach]="coach"></adapt-display-simple-organisation-list>
            </div>
        </div>
    </div>
</div>
