<div class="row">
    <div class="col-12">
        <div class="alert alert-info">
            <p>
                Manually trigger event scheduler checks,
                which will otherwise be triggered every day by Azure Webjob.
            </p>
            <p>
                Note that Azure Webjob is only available on Azure cloud; so this button will be required to perform
                test outside of the cloud environment.
            </p>
        </div>

        <button class="mt-2 btn btn-primary"
                [adaptBlockingClick]="triggerWebjob">
            Trigger Event Scheduler Checks
        </button>
        <div class="mt-3 alert alert-light"
             *ngIf="eventWebjobTriggerResult">{{eventWebjobTriggerResult}}</div>
    </div>
</div>
