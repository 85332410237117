<div class="row"
     *adaptLoading="!organisation">
    <div class="col-12">
        <div role="alert"
             class="alert alert-danger"
             *ngIf="error">{{error}}</div>

        <div class="card card-borderless">
            <div class="card-header">
                <div class="card-header-buttons"
                     adaptBlockingButtonGroup>
                    <button *ngIf="!isEditingDetails"
                            adaptButton="edit"
                            (click)="editDetails()"></button>
                    <button *ngIf="isEditingDetails"
                            adaptButton="save"
                            [adaptBlockingClick]="saveDetails"></button>
                    <button *ngIf="isEditingDetails"
                            adaptButton="cancel"
                            [adaptBlockingClick]="cancelDetails"></button>
                </div>
            </div>

            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="card card-info">
                            <div class="card-header">
                                <h3 class="card-title">General Details</h3>
                            </div>
                            <div class="card-body">
                                <div role="alert"
                                     class="alert alert-info">
                                    <p>All these are visible to the end user, careful changing them!</p>
                                </div>
                                <form role="form">
                                    <div class="form-group row">
                                        <label for="nameInput"
                                               class="col-md-4">Name</label>
                                        <div class="col-md-8">
                                            <dx-text-box id="nameInput"
                                                         [(value)]="organisation.name"
                                                         [disabled]="!isEditingDetails"></dx-text-box>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="urlIdentifierInput"
                                               class="col-md-4">Url Identifier</label>
                                        <div class="col-md-8">
                                            <dx-text-box id="urlIdentifierInput"
                                                         [(value)]="organisation.urlIdentifier"
                                                         [disabled]="!isEditingDetails"></dx-text-box>
                                        </div>
                                    </div>
                                    <div *ngIf="referrer"
                                         class="form-group row">
                                        <label class="col-md-4">Referrer</label>
                                        <div class="col-md-8">{{referrer}}</div>
                                    </div>
                                    <hr>
                                    <div class="form-group row">
                                        <label for="organisationType"
                                               class="col-md-4">Registration Date</label>
                                        <div class="col-md-8">
                                            {{organisation.name}} was originally registered to {{projectLabel}} on
                                            {{organisation.supplementaryData.registrationDate
                                            ? (organisation.supplementaryData.registrationDate | adaptDate)
                                            : "UNKNOWN DATE"}}.
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="organisationType"
                                               class="col-md-4">License Agreement Acceptance</label>
                                        <div class="col-md-8">
                                            <ng-container *ngIf="account.eulaAcceptedDate; else eulaNotAcceptedTemplate">
                                                Accepted by {{account.eulaPerson ? account.eulaPerson.fullName : "UNKNOWN PERSON"}}
                                                on {{account.eulaAcceptedDate | adaptDate}}.
                                            </ng-container>
                                            <ng-template #eulaNotAcceptedTemplate>
                                                License agreement has NOT been accepted.
                                            </ng-template>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="card-footer text-end">
                                <a target="_blank"
                                   [href]="embedLink">
                                    <i class="fal fa-fw fa-external-link-alt"></i> Open embedADAPT Dashboard
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card card-info">
                            <div class="card-header">
                                <h3 class="card-title">Internal Notes</h3>
                            </div>
                            <div class="card-body">
                                <div role="alert"
                                     class="alert alert-info">
                                    <p>Our internal notes about this organisation, not visible to the user</p>
                                </div>
                                <form role="form">
                                    <div class="form-group row">
                                        <label for="organisationType"
                                               class="col-md-4">Organisation Type:</label>
                                        <div class="col-md-8">
                                            <dx-select-box id="organisationType"
                                                           [(value)]="account.organisationType"
                                                           [disabled]="!isEditingDetails"
                                                           [items]="organisationTypes"
                                                           displayExpr="name"
                                                           valueExpr="id">
                                            </dx-select-box>
                                            <small class="form-text text-muted">Set this for correct classification of organisations -
                                                reporting, commercial model defaults, etc</small>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="agreementExpiry"
                                               class="col-md-4">Agreement Expiry</label>
                                        <div class="col-md-8">
                                            <dx-date-box id="agreementExpiry"
                                                         [value]="organisation!.supplementaryData.agreementExpiryDate"
                                                         (valueChange)="organisation!.supplementaryData.agreementExpiryDate = $any($event)"
                                                         [disabled]="!isEditingDetails">
                                            </dx-date-box>
                                            <small class="form-text text-muted">The date the current commercial model expires, and when a
                                                new commercial agreement should be scheduled</small>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="inputNotes">Notes</label>
                                        <adapt-html-editor *ngIf="isEditingDetails; else readOnlyNotesTemplate"
                                                           id="inputNotes"
                                                           [(contents)]="organisation!.supplementaryData.notes"></adapt-html-editor>
                                        <ng-template #readOnlyNotesTemplate>
                                            <div *ngIf="organisation?.supplementaryData?.notes; else noNotesTemplate"
                                                 class="read-only-notes">
                                                <div [froalaView]="organisation!.supplementaryData.notes"></div>
                                            </div>
                                            <ng-template #noNotesTemplate>
                                                <p>No organisation notes recorded.</p>
                                            </ng-template>
                                        </ng-template>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
