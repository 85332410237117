import { Component, Injector, OnInit } from "@angular/core";
import { DocumentationSubscriptionLevelLabel } from "@common/ADAPT.Common.Model/person/documentation-subscription-level";
import { CoachType, CoachTypeMetadata } from "@common/ADAPT.Common.Model/practitioner/coach-type";
import { Practitioner } from "@common/ADAPT.Common.Model/practitioner/practitioner";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { EMPTY, Observable } from "rxjs";
import { filter, switchMap } from "rxjs/operators";
import { CoachService } from "../coach.service";
import { CoachUiService } from "../coach-ui.service";

@Component({
    selector: "adapt-coach-page",
    templateUrl: "./coach-page.component.html",
})
export class CoachPageComponent extends BaseRoutedComponent implements OnInit {
    public coach$: Observable<Practitioner | undefined> = EMPTY;
    public documentationSubscriptionLevelLabeller = DocumentationSubscriptionLevelLabel;

    public constructor(
        private coachService: CoachService,
        private coachUiService: CoachUiService,
        injector: Injector,
    ) {
        super(injector);
    }

    public ngOnInit() {
        const coachId = this.getRouteParamInt("coachId");
        this.coach$ = this.coachService.getCoach(coachId!);

        this.notifyActivated();
    }

    @Autobind
    public editCoach() {
        return this.coach$.pipe(
            filter((coach) => !!coach),
            switchMap((coach) => this.coachUiService.editCoach(coach!)),
        );
    }

    public displayCoachType(coachType: CoachType) {
        return CoachTypeMetadata.ByType[coachType].name;
    }
}
