<div class="row">
    <div class="col-12 col-md-6 col-xl-4">
        <div class="alert alert-info">
            Re-rank each action in an organisation to have an even spread of values.
            As items are moved around on the board, their ranks naturally get closer to
            each other. If they get too close then this will prevent them from having their
            rank changed correctly.
        </div>

        <adapt-select-organisation (organisationChange)="selectedOrganisation = $event"></adapt-select-organisation>
        <button *ngIf="selectedOrganisation"
                class="mt-2"
                adaptButtonIcon
                [adaptBlockingClick]="rerank">
            Re-rank Actions
        </button>
    </div>
</div>
