import { Component, Injector, OnInit } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { ValueStream } from "@common/ADAPT.Common.Model/organisation/value-stream";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { lastValueFrom } from "rxjs";
import { StakeholderServicesService } from "../stakeholder-services.service";

@Component({
    selector: "adapt-delete-value-stream-page",
    templateUrl: "./delete-value-stream-page.component.html",
})
export class DeleteValueStreamPageComponent extends BaseRoutedComponent implements OnInit {
    public organisation?: Organisation;
    public valueStream?: ValueStream;

    public isMyMathsOk = false;

    public error?: string;

    public constructor(
        private commonDialogService: AdaptCommonDialogService,
        private stakeholderServicesService: StakeholderServicesService,
        private commonDataService: CommonDataService,
        injector: Injector,
    ) {
        super(injector);
    }

    public ngOnInit() {
        this.notifyActivated();
    }

    @Autobind
    public async deleteValueStream() {
        if (!this.organisation || !this.valueStream) {
            return lastValueFrom(this.commonDialogService.showMessageDialog("Error", "Select Organisation AND Value Stream!"));
        }

        if (!this.isMyMathsOk) {
            return lastValueFrom(this.commonDialogService.showMessageDialog("Error", "Check your maths!"));
        }

        const dialogData: IConfirmationDialogData = {
            title: "Delete Value Stream",
            message: "Are you sure you want to delete this value stream?",
            confirmButtonText: "Delete",
        };

        const confirmed = await lastValueFrom(this.commonDialogService.openConfirmationDialogWithBoolean(dialogData));
        if (confirmed) {
            try {
                await this.doDelete();
                await this.notify();
            } catch (e: any) {
                this.error = e;
            }
        }
    }

    private async doDelete() {
        const viewModel = {
            valueStreamId: this.valueStream!.valueStreamId,
        };

        try {
            await this.stakeholderServicesService.deleteValueStream(viewModel);

            //required to remove the deleted items for the cache
            this.commonDataService.detachEntityFromBreeze(this.valueStream!);
        } catch (e) {
            const message = ErrorHandlingUtilities.getHttpResponseMessage(e);
            return Promise.reject(message);
        }
    }

    private async notify() {
        this.error = undefined;
        this.organisation = undefined;
        this.valueStream = undefined;

        return lastValueFrom(this.commonDialogService.showMessageDialog("Success!", "Value Stream was deleted!"));
    }
}
