import { Injectable } from "@angular/core";
import { AdaptClientConfiguration, AdaptProject, AdaptProjectLabel } from "@common/configuration/adapt-client-configuration";
import { NimbusSwitchAbstractUserMenuItem } from "./nimbus-switch-abstract-user-menu-item";

@Injectable()
export class NimbusSwitchToAltoUserMenuItem extends NimbusSwitchAbstractUserMenuItem {
    public constructor() {
        super(`Switch to ${AdaptProjectLabel[AdaptProject.Alto]}`, true, AdaptClientConfiguration.AltoApiBaseUri);
    }
}
