<div class="card card-info">
    <div class="card-header">
        <h4 class="card-title">Generate Account Activation Link</h4>
    </div>
    <div class="card-body">
        <p>This will generate an activation link that you can send to a user.
            <br />Use this when someone hasnt received a welcome email (e.g. our emails are blocked) and they need a link, stat!
            <br /><br />
            <b>NOTE: This will only be available if the user has an active connection to an organisation, has access to that
                organisation(i.e. allowed to log in), and has not already confirmed their email (i.e previously logged in).</b>
        </p>

        <div *ngIf="person && person.methodologyUser && person.methodologyUser.userName && !person.methodologyUser.emailConfirmed"
             class="pb-3">
            <label>Select Organisation</label>
            <adapt-select-organisation [(organisation)]="organisation"
                                       [filter]="filterOrgs"></adapt-select-organisation>
        </div>

        <button [adaptBlockingClick]="copyActivationLink"
                [disabled]="!person || !person.methodologyUser || !person.methodologyUser.userName || !organisation || person.methodologyUser.emailConfirmed"
                adaptButtonIcon="fal fa-fw fa-ski-jump"
                class="btn btn-primary"
                data-test="submit-btn"
                type="submit">
            Copy Activation Link to Clipboard
        </button>

        <div *ngIf="error"
             role="alert"
             class="alert alert-danger my-3">{{error}}
        </div>
    </div>
</div>
