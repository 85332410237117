<div>
    <div role="alert"
         class="alert alert-info">
        Delete a user - and directly associated data ONLY (CI, CVT).
        <p>Related entities (e.g. items, meetings) will NOT be deleted and this will fail if you try (please dont try!).</p>
    </div>

    <div role="alert"
         class="alert alert-warning">
        <h3>*** Note: This operation is not recoverable. ***</h3>
    </div>

    <form role="form">
        <div class="row">
            <div class="col-12 col-lg-8">

                <div class="form-group">
                    <h4>Old Person Id</h4>
                    <dx-number-box [(value)]="personId"
                                   (onValueChanged)="updatePerson()"
                                   placeholder="The PersonId of the user you wish to delete...">
                        <dx-validator>
                            <dxi-validation-rule type="required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-number-box>
                    <h5 *ngIf="person">{{person.fullName}}</h5>
                </div>
                <div class="form-group">
                    <adapt-check-my-maths (isMyMathsOk)="isMyMathsOk = $event"></adapt-check-my-maths>
                </div>
            </div>
        </div>
        <button adaptButton="delete"
                [adaptBlockingClick]="deleteUser"> User</button>
        <div role="alert"
             class="alert alert-danger"
             *ngIf="error">{{error}}</div>
    </form>
</div>
