import { PageRouteBuilder } from "@common/route/page-route-builder";
import { CoachPageComponent } from "./coach-page.component";

export interface IDisplayCoachRouteParams {
    coachId: number;
}

export const displayCoachRoute = new PageRouteBuilder<IDisplayCoachRouteParams>()
    .usingNgComponent("adapt-coach-page", CoachPageComponent)
    .atUrl("/coaches/:coachId")
    .build();
