import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { OrganisationType } from "@common/ADAPT.Common.Model/account/account";
import { Organisation, OrganisationBreezeModel } from "@common/ADAPT.Common.Model/organisation/organisation";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { IDxCheckBoxOnValueChanged } from "@common/ux/dx.types";
import { OrganisationsService } from "app/features/organisations/organisations.service";
import { ValidationError, Validator } from "breeze-client";
import { ValueChangedEvent } from "devextreme/ui/text_box";
import { lastValueFrom } from "rxjs";

@Component({
    selector: "adapt-new-coach-sandbox",
    templateUrl: "./new-coach-sandbox.component.html",
})
export class NewCoachSandboxComponent implements OnInit {
    public createSandbox = false;
    public existingOrganisationUrlIdentifiers: string[] = [];

    @Input() public createdOrganisation?: Organisation;
    @Output() public createdOrganisationChange = new EventEmitter<Organisation | undefined>();

    @Input() public joinOrganisation?: Organisation;
    @Output() public joinOrganisationChange = new EventEmitter<Organisation | undefined>();

    private duplicateUrlIdentifierError?: ValidationError;

    constructor(private orgService: OrganisationsService, private commonDataService: CommonDataService) {
    }

    public async ngOnInit() {
        const organisations: Organisation[] = await this.orgService.getOrganisationsWithAccounts();
        this.existingOrganisationUrlIdentifiers = organisations.map((org) => org.urlIdentifier);

        if (this.createdOrganisation) {
            this.createSandbox = true;
        }
    }

    public async createSandboxChanged(event: IDxCheckBoxOnValueChanged) {
        this.createSandbox = event.value ?? false;
        if (this.createSandbox) {
            if (!this.createdOrganisation) {
                this.createdOrganisation = await lastValueFrom(this.commonDataService.create(OrganisationBreezeModel, {
                    name: "",
                    urlIdentifier: "",
                }));
            }
        } else {
            this.createdOrganisation = undefined;
        }
        this.createdOrganisationChange.emit(this.createdOrganisation);
    }

    public orgNameChanged(event: ValueChangedEvent) {
        this.updateUrlIdentifier(event.value, event.previousValue);
    }

    public filterSandbox(organisation: Organisation) {
        return organisation.account?.organisationType === OrganisationType.Sandbox;
    }

    private updateUrlIdentifier(newOrgName?: string, oldOrgName?: string) {
        if (this.createdOrganisation!.urlIdentifier === this.convertToUrl(oldOrgName)) {
            // if they were matching before the last update
            if (newOrgName) {
                // update url id to match name
                this.createdOrganisation!.urlIdentifier = this.convertToUrl(newOrgName);
            } else {
                // name has been deleted, update url id to match
                this.createdOrganisation!.urlIdentifier = "";
            }
        }

        if (this.existingOrganisationUrlIdentifiers.includes(this.createdOrganisation!.urlIdentifier)) {
            this.duplicateUrlIdentifierError = new ValidationError(
                undefined as unknown as Validator,
                {
                    property: this.createdOrganisation!.entityType.getProperty("urlIdentifier"),
                },
                "Identifier already in use",
                "validUrlIdentifier",
            );
            this.createdOrganisation!.entityAspect.addValidationError(this.duplicateUrlIdentifierError);
        } else {
            if (this.duplicateUrlIdentifierError) {
                this.createdOrganisation!.entityAspect.removeValidationError(this.duplicateUrlIdentifierError);
            }
        }
    }

    private convertToUrl(orgName?: string) {
        // only allow lowercase alphanumerics
        orgName = orgName ? orgName : "";
        return orgName.toLowerCase()
            .replace(/[^a-z0-9]/g, "")
            .substring(0, 20);
    }
}
