import { Component, Injector, OnInit } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { lastValueFrom } from "rxjs";
import { ICacheStatus, StakeholderServicesService } from "../stakeholder-services.service";

@Component({
    selector: "adapt-cache-status-page",
    templateUrl: "cache-status-page.component.html",
})
export class CacheStatusPageComponent extends BaseRoutedComponent implements OnInit {
    public cacheStatuses: ICacheStatus[] = [];
    public error?: string;

    public constructor(
        private stakeholderServicesService: StakeholderServicesService,
        private commonDialogService: AdaptCommonDialogService,
        injector: Injector,
    ) {
        super(injector);
    }

    public ngOnInit() {
        this.notifyActivated();
    }

    @Autobind
    public async doCheck() {
        this.error = undefined;

        try {
            this.cacheStatuses = await this.stakeholderServicesService.getCacheStatus();
        } catch (e) {
            this.error = ErrorHandlingUtilities.getHttpResponseMessage(e);
        }
    }

    @Autobind
    public async doClear() {
        try {
            await this.stakeholderServicesService.clearCache();
            this.cacheStatuses = [];
            await lastValueFrom(this.commonDialogService.showMessageDialog("Cache Clear", "Cache has been cleared!"));
        } catch (e) {
            this.error = ErrorHandlingUtilities.getHttpResponseMessage(e);
        }
    }
}
