import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Organisation } from "./organisation";

export class OrganisationSupplementaryData extends BaseEntity<OrganisationSupplementaryData> {
    public organisationId!: number;
    public notes?: string;
    public agreementExpiryDate?: Date;
    public registrationDate?: Date;
    public cardExpiryWarningSent!: boolean;
    public organisation!: Organisation;
    public customWelcomeEmail?: string;
    public annualObjectiveIndex?: number;
    public quarterlyObjectiveIndex?: number;
}

export const OrganisationSupplementaryDataBreezeModel = new BreezeModelBuilder("OrganisationSupplementaryData", OrganisationSupplementaryData)
    .withPluralName("Organisation Supplementary Data")
    .hasSource()
    .build();
