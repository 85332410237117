import { Component, Injector, OnInit } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { PeopleService } from "app/features/people/people.service";
import { lastValueFrom } from "rxjs";
import { StakeholderServicesService } from "../stakeholder-services.service";

@Component({
    selector: "adapt-delete-user-page",
    templateUrl: "./delete-user-page.component.html",
})
export class DeleteUserPageComponent extends BaseRoutedComponent implements OnInit {
    public personId?: number;
    public person?: Person;

    public isMyMathsOk = false;

    public error?: string;

    public constructor(
        private commonDialogService: AdaptCommonDialogService,
        private stakeholderServicesService: StakeholderServicesService,
        private peopleService: PeopleService,
        injector: Injector,
    ) {
        super(injector);
    }

    public ngOnInit() {
        this.notifyActivated();
    }

    public async updatePerson() {
        this.person = this.personId
            ? await lastValueFrom(this.peopleService.getPersonById(this.personId))
            : undefined;
    }

    @Autobind
    public async deleteUser() {
        if (!this.personId) {
            return lastValueFrom(this.commonDialogService.showMessageDialog("Error", "Select Person Id!"));
        }

        if (!this.person) {
            return lastValueFrom(this.commonDialogService.showMessageDialog("Error", "That personId is not a real person!"));
        }

        if (!this.isMyMathsOk) {
            return lastValueFrom(this.commonDialogService.showMessageDialog("Error", "Check your maths!"));
        }

        const dialogData = { title: "Delete User", message: "Are you sure you want to delete this User?", confirmButtonText: "Delete", cancelButtonText: "Cancel" };
        const confirmed = await lastValueFrom(this.commonDialogService.openConfirmationDialogWithBoolean(dialogData));
        if (confirmed) {
            await this.doDelete();
        }
    }

    private async doDelete() {
        const viewModel = {
            personId: this.personId,
        };

        try {
            await this.stakeholderServicesService.deleteUser(viewModel);

            this.error = undefined;
            this.personId = undefined;

            return lastValueFrom(this.commonDialogService.showMessageDialog("Success!", "User was deleted!"));
        } catch (error) {
            this.error = ErrorHandlingUtilities.getHttpResponseMessage(error);
            return Promise.reject(error);
        }
    }
}
