import { Injectable } from "@angular/core";
import { AdaptClientConfiguration, AdaptProject, AdaptProjectLabel } from "@common/configuration/adapt-client-configuration";
import { NimbusSwitchAbstractUserMenuItem } from "./nimbus-switch-abstract-user-menu-item";

@Injectable()
export class NimbusSwitchToEmbedUserMenuItem extends NimbusSwitchAbstractUserMenuItem {
    public constructor() {
        super(`Switch to ${AdaptProjectLabel[AdaptProject.Cumulus]}`, false, AdaptClientConfiguration.EmbedApiBaseUri);
    }
}
