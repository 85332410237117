import { Component, Injector, OnInit } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { switchMap } from "rxjs/operators";
import { StakeholderServicesService } from "../stakeholder-services.service";

@Component({
    selector: "adapt-rerank-kanban-items",
    templateUrl: "./rerank-kanban-items.component.html",
})
export class RerankKanbanItemsComponent extends BaseRoutedComponent implements OnInit {
    public selectedOrganisation?: Organisation;

    public constructor(
        private stakeholderServices: StakeholderServicesService,
        private dialogService: AdaptCommonDialogService,
        injector: Injector) {
        super(injector);
    }

    public ngOnInit() {
        this.notifyActivated();
    }

    @Autobind
    public rerank() {
        // Button won't be shown if we have no selection
        return this.stakeholderServices.rerankKanbanItems(this.selectedOrganisation!).pipe(
            switchMap(() => this.dialogService.showMessageDialog("Kanban Rerank Complete", "Kanban Rerank Complete")),
        );
    }
}
