import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { EMPTY, Observable } from "rxjs";
import { OrganisationsService } from "../organisations.service";

@Component({
    selector: "adapt-link-organisation",
    templateUrl: "./link-organisation.component.html",
})
export class LinkOrganisationComponent implements OnChanges {
    @Input() public organisation!: Organisation;
    public href$: Observable<string> = EMPTY;

    constructor(private organisationService: OrganisationsService) {
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.organisation.currentValue) {
            this.href$ = this.organisationService.getRouteToOrganisationDetailPage(changes.organisation.currentValue);
        }
    }
}
