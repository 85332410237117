<div class="card card-info">
    <div class="card-header">
        <h4 class="card-title">Login as this user</h4>
    </div>
    <div class="card-body">
        <p>This will log you out of your current session on embedADAPT and log you in as this user.
            <br />This session will remain active for 1 Hour only.
            <br /><br /><b>** Use this to help with diagnosing issues ONLY, not for idle browsing or changing other people's data **</b>
        </p>

        <button [adaptBlockingClick]="loginAs"
                [disabled]="!person || !person.methodologyUser || !person.methodologyUser.userName"
                adaptButtonIcon="fal fa-fw fa-sign-in"
                class="btn btn-primary"
                data-test="submit-btn"
                type="submit">
            Login as this user
        </button>

        <div *ngIf="error"
             role="alert"
             class="alert alert-danger my-3">{{error}}
        </div>
    </div>
</div>
