import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { EMPTY, Observable } from "rxjs";
import { PeopleService } from "../people.service";
import { PersonService } from "../person.service";

@Component({
    selector: "adapt-link-person",
    templateUrl: "./link-person.component.html",
})
export class LinkPersonComponent implements OnChanges {
    @Input() public person?: Person;
    @Input() public personId?: number;

    public href$: Observable<string> = EMPTY;

    constructor(
        private personService: PersonService,
        private peopleService: PeopleService,
    ) {
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.person?.currentValue) {
            this.setHRef(this.person);
        } else if (changes.personId?.currentValue) {
            this.peopleService.getPersonById(changes.personId.currentValue)
                .subscribe((person) => {
                    this.person = person;
                    this.setHRef(this.person);
                });
        }
    }

    private setHRef(person?: Person) {
        this.href$ = person
            ? this.personService.getRouteToPersonDetailPage(person)
            : EMPTY;
    }
}
