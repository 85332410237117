<form role="form">
    <div class="form-group">
        <label for="editStartDate">Start Date</label>
        <dx-date-box id="editStartDate"
                     type="date"
                     [value]="practitioner.startDate"
                     (valueChange)="practitioner.startDate = $any($event)"></dx-date-box>
    </div>

    <div class="form-group">
        <label for="editEndDate">End Date</label>
        <dx-date-box id="editEndDate"
                     type="date"
                     [showClearButton]="true"
                     [min]="practitioner.startDate"
                     [value]="practitioner.endDate"
                     (valueChange)="practitioner.endDate = $any($event)"></dx-date-box>
    </div>

    <div class="form-group">
        <label for="editNotes">Notes</label>
        <adapt-html-editor id="editNotes"
                           [(contents)]="practitioner.notes"></adapt-html-editor>
    </div>

    <div class="form-group">
        <label for="editPartner">Coach Type</label>
        <dx-select-box [items]="allTypes"
                       [width]="350"
                       displayExpr="name"
                       valueExpr="type"
                       [(value)]="practitioner.coachType">
        </dx-select-box>
    </div>

    <div class="form-group">
        <label for="editPartner">Strategic Partner</label>
        <adapt-select-partner id="editPartner"
                              [(partner)]="practitioner.partner">
        </adapt-select-partner>
    </div>
</form>
