import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { DeepDive } from "@common/ADAPT.Common.Model/embed/deep-dive";
import { IdentityService } from "@common/identity/identity.service";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { DeepDiveService } from "@common/workflow/deep-dive.service";
import { breeze } from "breeze-client";
import dxFileUploader, { BeforeSendEvent, ContentReadyEvent, UploadedEvent, UploadErrorEvent, ValueChangedEvent } from "devextreme/ui/file_uploader";
import { lastValueFrom } from "rxjs";
import { tap } from "rxjs/operators";
import toastr from "toastr";
import { MaxAllowedFileSize } from "../deep-dive-constants";

export interface IUploadDeepDiveDialogData {
    deepDive: DeepDive;
    isNew: boolean;
}

@Component({
    selector: "adapt-upload-deep-dive-dialog",
    templateUrl: "./upload-deep-dive-dialog.component.html",
    styleUrls: ["./upload-deep-dive-dialog.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class UploadDeepDiveDialogComponent extends BaseDialogComponent<IUploadDeepDiveDialogData> {
    public readonly dialogName = "DeepDiveUpload";
    public readonly maxAllowedFileSize = MaxAllowedFileSize;

    public uploadInProgress = false;
    public fileSelected = false;

    private accessToken?: string = undefined;
    private uploaderInstance?: dxFileUploader;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public data: IUploadDeepDiveDialogData,
        private deepDiveService: DeepDiveService,
        private commonDataService: CommonDataService,
        private identityService: IdentityService,
        private commonDialogService: AdaptCommonDialogService,
    ) {
        super();
    }

    public onUploaderContentReady(event: ContentReadyEvent) {
        this.uploaderInstance = event.component;

        this.identityService.promiseToGetAccessToken()
            .then((token) => {
                this.accessToken = token;
                this.uploaderInstance?.option("uploadUrl", this.uploadUrl);
                this.uploaderInstance?.option("uploadHeaders", {
                    Accept: "application/json, text/plain, */*",
                    Authorization: "Bearer " + this.accessToken,
                });
            });
    }

    public onValueChanged(event: ValueChangedEvent) {
        this.setErrorMessage();
        this.uploadInProgress = false;
        this.fileSelected = event.value?.length === 1 && (event.component as any)?._files[0]?.isValid();
    }

    public onBeforeSend(event: BeforeSendEvent) {
        event.request.responseType = "json";
    }

    public get uploadUrl() {
        return this.deepDiveService.uploadUri({ location: this.data.deepDive.location });
    }

    public async performUpload() {
        if (this.uploaderInstance) {
            if (!this.data.deepDive.location) {
                this.data.deepDive.location = breeze.core.getUuid();
                try {
                    await lastValueFrom(this.commonDataService.saveEntities([this.data.deepDive]));
                } catch (e) {
                    this.setErrorMessage(ErrorHandlingUtilities.getHttpResponseMessage(e));
                    return;
                }
            }

            this.uploaderInstance.option("uploadUrl", this.uploadUrl);
            this.uploaderInstance.upload();
            this.uploadInProgress = true;
        }
    }

    public async onFileUploaded(event: UploadedEvent) {
        this.uploadInProgress = false;
        toastr.success(event.request.response);
        this.cancel();
    }

    public onUploadError(event: UploadErrorEvent) {
        console.log(event);
        this.setErrorMessage(ErrorHandlingUtilities.getHttpResponseMessage(event.request.response));
    }

    public onCancel() {
        if (this.data.isNew || this.uploadInProgress) {
            const msg = this.uploadInProgress
                ? "You are currently uploading content for this Deep Dive. Are you sure you want to cancel?"
                : "You have not uploaded content for this Deep Dive yet. Are you sure you want to continue?";

            this.commonDialogService.openConfirmationDialog({
                title: "Discarding Upload",
                message: `<p>${msg}</p><p>You can upload content at any time from the menu within the Deep Dive list.</p>`,
                confirmButtonText: "Discard",
                cancelButtonText: "Stay Here",
            }).pipe(
                tap(() => {
                    this.uploaderInstance?.abortUpload();
                    this.cancel();
                }),
            ).subscribe();
        } else {
            this.cancel();
        }
    }
}
