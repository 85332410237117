<div class="card card-info">
    <div class="card-header">
        <h3 class="card-title">Organisations</h3>
    </div>

    <div class="card-body">
        <dx-data-grid [dataSource]="data"
                      [allowColumnReordering]="true"
                      [columnAutoWidth]="true"
                      [rowAlternationEnabled]="true"
                      [showRowLines]="true"
                      [showBorders]="true"
                      [remoteOperations]="false">
            <dxo-filter-row [visible]="true">
            </dxo-filter-row>
            <dxo-pager [showPageSizeSelector]="true"
                       [allowedPageSizes]="[10, 25, 50, 100, 250]">
            </dxo-pager>
            <dxo-paging [enabled]="true"
                        [pageSize]="25">
            </dxo-paging>

            <!-- Columns -->
            <dxi-column caption="Id"
                        dataField="connectionId"
                        dataType="number">
            </dxi-column>

            <dxi-column caption="Organisation"
                        dataField="organisation"
                        dataType="string"
                        cellTemplate="orgCellTemplate">
            </dxi-column>

            <dxi-column caption="Type"
                        dataField="connectionType"
                        dataType="string">
            </dxi-column>

            <dxi-column caption="Start Date"
                        dataField="startDate"
                        dataType="date"
                        [format]="dateFormat"
                        sortOrder="desc">
            </dxi-column>

            <dxi-column caption="End Date"
                        dataField="endDate"
                        dataType="date"
                        [format]="dateFormat">
            </dxi-column>

            <dxi-column caption="Status"
                        [calculateCellValue]="getIsActive"
                        dataField="dummyIsActive"
                        dataType="boolean"
                        trueText="Active"
                        falseText="Inactive"
                        [filterValue]="true">
            </dxi-column>

            <dxi-column caption="Has Platform Access"
                        dataField="hasAccess"
                        dataType="boolean">
            </dxi-column>

            <dxi-column caption="Email Confirmed"
                        [calculateCellValue]="getEmailConfirmed"
                        dataField="dummyEmailConfirmed"
                        dataType="boolean">
            </dxi-column>

            <dxi-column caption="Last Access"
                        [calculateCellValue]="getLastAccess"
                        dataField="dummyLastAccess"
                        dataType="date"
                        [format]="dateFormat">
            </dxi-column>

            <!-- Templates -->
            <div *dxTemplate="let item of 'orgCellTemplate'">
                <adapt-link-organisation [organisation]="item.data.organisation"></adapt-link-organisation>
            </div>
        </dx-data-grid>
    </div>
</div>
