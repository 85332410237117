import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { IUserMenuItem } from "@common/shell/application-bar-user-item-content/user-menu-item";
import { Observable, of } from "rxjs";

export abstract class NimbusSwitchAbstractUserMenuItem implements IUserMenuItem {
    public icon = "fal fa-repeat";
    public href$ = of("");
    public text: string;
    public isShown$: Observable<boolean>;

    public constructor(
        text: string,
        enableWhenIsEmbed: boolean,
        private targetApiBaseUri: string,
    ) {
        this.text = text;
        this.isShown$ = of(
            !!AdaptClientConfiguration.EmbedApiBaseUri &&
            !!AdaptClientConfiguration.AltoApiBaseUri &&
            AdaptClientConfiguration.EmbedApiBaseUri !== AdaptClientConfiguration.AltoApiBaseUri &&
            AdaptClientConfiguration.IsCurrentEmbedApiBaseUri === enableWhenIsEmbed);
    }

    public onClick = () => {
        AdaptClientConfiguration.CurrentApiBaseUri = this.targetApiBaseUri;
        window.location.reload();
    };
}
