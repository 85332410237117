<button adaptButtonIcon="add"
        class="mb-3"
        [adaptBlockingClick]="addNewCoach">Add a coach</button>

<dx-data-grid [dataSource]="connections"
              [allowColumnReordering]="true"
              [allowColumnResizing]="true"
              [columnAutoWidth]="true"
              [rowAlternationEnabled]="true">

    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>

    <dxi-column dataField="person.fullName"
                dataType="string"
                caption="Coach"
                cellTemplate="coachNameLinkCellTemplate"></dxi-column>
    <dxi-column dataField="startDate"
                dataType="date"
                caption="Start Date"
                [format]="dateFormat"></dxi-column>
    <dxi-column dataField="endDate"
                dataType="date"
                caption="End Date"
                [format]="dateFormat"></dxi-column>
    <dxi-column caption="Status"
                dataType="boolean"
                [allowFiltering]="true"
                [calculateCellValue]="calculateActiveCellValue"
                trueText="Active"
                falseText="Inactive"
                [filterValue]="true"></dxi-column>
    <dxi-column caption="Actions"
                cellTemplate="actionsCellTemplate"></dxi-column>

    <div *dxTemplate="let data of 'coachNameLinkCellTemplate'">
        <adapt-link-person [person]="data.data.person"></adapt-link-person>
    </div>

    <div *dxTemplate="let data of 'actionsCellTemplate'">
        <div>
            <button adaptButton="edit"
                    (click)="editPractitionerConnection(data.data)"></button>
            <button *ngIf="!data.data.endDate"
                    adaptButton="end"
                    (click)="endCoachConnection(data.data)"></button>
            <button adaptButton="delete"
                    (click)="deletePractitionerConnection(data.data)"></button>
        </div>
    </div>
</dx-data-grid>
