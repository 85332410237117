import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { RouteEvent } from "@common/route/route-event.enum";
import { ICommonShellComponentOptions } from "@common/shell/common-shell/common-shell.component";
import { ShellUiService } from "@common/shell/shell-ui.service";
import { UserService } from "@common/user/user.service";
import { landingPageRoute } from "../landing-page/landing-page.route";

@Component({
    selector: "adapt-nimbus-shell-wrapper",
    templateUrl: "./nimbus-shell-wrapper.component.html",
    styleUrls: ["./nimbus-shell-wrapper.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class NimbusShellWrapperComponent implements OnInit {
    public readonly ExtraSidebarClasses =
        AdaptClientConfiguration.AdaptProjectName === "Nimbus" && !AdaptClientConfiguration.IsCurrentEmbedApiBaseUri
            ? "nimbus-alto"
            : undefined;

    public commonShellOptions: ICommonShellComponentOptions = {
        applicationBarOptions: {
            defaultMastheadImageSrc: "/content/images/logo.svg",
            navClass: AdaptClientConfiguration.IsCurrentEmbedApiBaseUri
                ? "navbar-dark navbar-nimbus"
                : "navbar-dark navbar-nimbus-alto",
            textAfterLogo: AdaptClientConfiguration.IsCurrentEmbedApiBaseUri
                ? "for embedADAPT"
                : "for adapt HQ",
        },
        disableReleaseNotification: true,
        viewFinishedLoadingEvent: RouteEvent.ComponentActivated,
        viewIsLoadedAfterInitialisation: false,
    };

    public constructor(
        private userService: UserService,
        private shellUiService: ShellUiService,
    ) {
    }

    public ngOnInit(): void {
        // cumulus would have this done in the organisation.service after permissions are initialised.
        // For nimbus, organisation service won't be loaded and we are not relying on any permissions
        // so notify here for the identityUx to proceed.
        this.userService.personEntityUpdated$.subscribe(() => {
            this.userService.notifyUserChanged();
            // sidebar won't be visible by default anymore as it caused a errors if there is no organisation
            // for the brief few digiest cycles in cumulus before setting it to false due to noOrg.
            // So need to manually set it here - for nimbus, users are not logging into an organisation - so sidebar
            // queries are not org specifics
            this.shellUiService.setSidebarIsVisible(true);
        });

        landingPageRoute.getRoute()
            .subscribe((url) => this.commonShellOptions.applicationBarOptions!.mastheadHref = url);
    }
}
