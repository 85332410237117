import moment from "moment";

export class DataAuditFilterBuilder {
    public auditFIlter: string = "true";

    public setStartDate(startDate: Date): DataAuditFilterBuilder {
        const startTime = moment(startDate).startOf("day").toISOString();
        this.auditFIlter += ` and Timestamp gt datetime'${startTime}'`;
        return this;
    }

    public setEndDate(endDate: Date): DataAuditFilterBuilder {
        const endTime = moment(endDate).endOf("day").toISOString();
        this.auditFIlter += ` and Timestamp lt datetime'${endTime}'`;
        return this;
    }

    public setPersonId(personId: number): DataAuditFilterBuilder {
        if (personId !== 0) {
            this.auditFIlter += ` and PersonId eq ${personId}`;
        }
        return this;
    }

    public setOrganisationId(organisationId: number): DataAuditFilterBuilder {
        if (organisationId !== 0) {
            this.auditFIlter += ` and PartitionKey eq '${organisationId}'`;
        }
        return this;
    }

    public setChangeTypes(added: boolean, modified: boolean, deleted: boolean): DataAuditFilterBuilder {
        let changeTypeFilter = "false";
        if (added) {
            changeTypeFilter = `${changeTypeFilter} or ChangeType eq 'Added'`;
        }
        if (deleted) {
            changeTypeFilter = `${changeTypeFilter} or ChangeType eq 'Deleted'`;
        }
        if (modified) {
            changeTypeFilter = `${changeTypeFilter} or ChangeType eq 'Modified'`;
        }
        this.auditFIlter += ` and (${changeTypeFilter})`;
        return this;
    }

    public setData(dataFilter: string): DataAuditFilterBuilder {
        if (dataFilter !== "") {
            this.auditFIlter += ` and (Data eq '${dataFilter}')`;
        }
        return this;
    }

    public setEntityIds(entityIds: string): DataAuditFilterBuilder {
        if (entityIds !== "") {
            const ids = entityIds.replace(/\s/g, "").split(",");
            let entIdFilter = "false";
            ids.forEach((id) => {
                entIdFilter = `${entIdFilter} or EntityId eq '${id}'`;
            });
            this.auditFIlter += ` and (${entIdFilter})`;
        }
        return this;
    }

    public setEntityTypes(entityTypes: string): DataAuditFilterBuilder {
        if (entityTypes !== "") {
            const types = entityTypes.replace(/\s/g, "").split(",");
            let entTypeFilter = "false";
            types.forEach((type) => {
                entTypeFilter = `${entTypeFilter} or EntityType eq '${type}'`;
            });
            this.auditFIlter += ` and (${entTypeFilter})`;
        }
        return this;
    }

    public build(): string {
        return this.auditFIlter;
    }
}
