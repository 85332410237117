import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { SystemEntity, SystemEntityBreezeModel } from "@common/ADAPT.Common.Model/organisation/system-entity";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IDxSelectBoxValueChangedEvent } from "@common/ux/dx.types";
import { NimbusDataService } from "app/services/nimbus-data.service";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { lastValueFrom } from "rxjs";

@Component({
    selector: "adapt-select-system",
    templateUrl: "./select-system.component.html",
})
export class SelectSystemComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() public organisation!: Organisation;
    @Output() public systemChange = new EventEmitter<SystemEntity>();

    @Input() public filter?: (system: SystemEntity) => boolean;

    public systemEntityId?: number;
    public dataSource?: DataSource;

    private system?: SystemEntity;

    public constructor(
        private commonDataService: CommonDataService,
        private nimbusDataService: NimbusDataService,
    ) {
        super();
    }

    public ngOnInit() {
        this.setDataSource();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.organisation) {
            this.onOrganisationChanged();
        }
    }

    private async setDataSource() {
        const teams = await this.fetchSystems();

        this.dataSource = new DataSource({
            store: new ArrayStore({
                data: teams,
                key: "teamId",
            }),
            filter: this.filter,
        });
    }

    private async fetchSystems() {
        if (!this.organisation) {
            throw new Error("Organisation must be set");
        }

        const systems = await lastValueFrom(this.nimbusDataService.getSystemsByOrganisation(this.organisation));
        return systems.sort((a, b) => a.name.localeCompare(b.name));
    }

    private onOrganisationChanged() {
        this.system = undefined;
        this.systemEntityId = undefined;

        this.systemChange.emit(this.system);

        this.setDataSource();
    }

    public async onSystemEntityIdChanged(e: IDxSelectBoxValueChangedEvent<number>) {
        if (e.value === e.previousValue) {
            return;
        }

        if (e.value) {
            this.system = await lastValueFrom(this.commonDataService.getById(SystemEntityBreezeModel, e.value));
        } else {
            this.system = undefined;
            this.systemEntityId = undefined;
        }

        this.systemChange.emit(this.system);
    }
}
