import { Component, Injector, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Board } from "@common/ADAPT.Common.Model/organisation/board";
import { Item, ItemBreezeModel } from "@common/ADAPT.Common.Model/organisation/item";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { lastValueFrom } from "rxjs";

@Component({
    selector: "adapt-move-board-page",
    templateUrl: "./move-board-page.component.html",
    styleUrls: ["./move-board-page.component.scss"],
})
export class MoveBoardPageComponent extends BaseRoutedComponent implements OnInit, OnChanges {
    public isMyMathsOk = false;
    public organisation?: Organisation;
    public sourceTeam?: Team;
    public destTeam?: Team;
    public board?: Board;
    public isComplete = false;
    public affectedItems: Item[] = [];
    public error?: string;
    public loading = false;

    public constructor(
        private commonDataService: CommonDataService,
        private commonDialogService: AdaptCommonDialogService,
        injector: Injector,
    ) {
        super(injector);
    }

    public ngOnInit() {
        this.notifyActivated();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.organisation) {
            this.onOrganisationChanged();
        }

        if (changes.isMyMathsOk) {
            this.validateSelection();
        }
    }

    @Autobind
    public async promiseToMoveBoard() {
        if (this.board && this.destTeam) {
            this.board.teamId = this.destTeam.teamId;

            try {
                await lastValueFrom(this.commonDataService.save());
                this.error = "";
                await lastValueFrom(this.commonDialogService.showMessageDialog("Success!", "Board has been moved successfully."));
            } catch (e) {
                this.error = ErrorHandlingUtilities.getHttpResponseMessage(e);
            }
        } else {
            this.error = "Unable to move board without board or destination team selected";
        }
    }

    @Autobind
    public async onBoardSelectionChanged(board?: Board) {
        this.loading = true;
        this.board = board;
        this.validateSelection();

        if (board) {
            const predicate = new MethodologyPredicate<Item>("boardId", "==", board.boardId);
            this.affectedItems = await lastValueFrom(this.commonDataService.getByPredicate(ItemBreezeModel, predicate));
        } else {
            this.affectedItems = [];
        }

        this.loading = false;
    }

    @Autobind
    public excludeSourceTeam(team: Team) {
        return team !== this.sourceTeam && team.isActive();
    }

    @Autobind
    private onOrganisationChanged() {
        this.sourceTeam = undefined;
        this.board = undefined;
        this.destTeam = undefined;
        this.affectedItems = [];

        this.validateSelection();
    }

    @Autobind
    private validateSelection() {
        this.isComplete = this.hasAllRequiredFields();
    }

    public hasAllRequiredFields() {
        return !!this.organisation && !!this.board && !!this.destTeam;
    }

    @Autobind
    public onSourceTeamChanged(sourceTeam: Team) {
        this.sourceTeam = sourceTeam;

        if (sourceTeam === this.destTeam) {
            this.destTeam = undefined;
        }

        this.validateSelection();
    }

    @Autobind
    public onDestTeamChanged(destTeam: Team) {
        this.destTeam = destTeam;

        this.validateSelection();
    }
}
