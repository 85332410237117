<div class="d-flex row">
    <dx-radio-group [items]="PersonActionSelection"
                    [(value)]="actionSelection"
                    (onValueChanged)="onPersonActionChanged()"
                    valueExpr="value"
                    displayExpr="text"></dx-radio-group>
</div>

<div class="d-flex mt-3 row">
    <dx-validation-group #validationGroup>
        <ng-container *ngIf="actionSelection === PersonAction.Existing; else createNewPerson">
            <div class="col">
                <div class="form-group">
                    <adapt-select-person [(person)]="person"
                                         (personChange)="personChange.emit($event)"></adapt-select-person>
                </div>
            </div>
        </ng-container>
    </dx-validation-group>
</div>

<ng-template #createNewPerson>
    <div class="col-12 col-md-6 form-group">
        <label>First Name</label>
        <dx-text-box [(value)]="temporaryPerson!.firstName"
                     (onValueChanged)="validateGroup()"
                     placeholder="Enter first name">
            <dx-validator>
                <dxi-validation-rule type="required"
                                     message="First name is required"></dxi-validation-rule>
            </dx-validator>
        </dx-text-box>
    </div>
    <div class="col-12 col-md-6 form-group">
        <label>Surname</label>
        <dx-text-box [(value)]="temporaryPerson!.lastName"
                     (onValueChanged)="validateGroup()"
                     placeholder="Enter surname">
            <dx-validator>
                <dxi-validation-rule type="required"
                                     message="Surname is required"></dxi-validation-rule>
            </dx-validator>
        </dx-text-box>
    </div>
    <div class="col-12 col-md-6 form-group">
        <label>Email</label>
        <dx-text-box mode="email"
                     [(value)]="temporaryPerson!.email"
                     (onValueChanged)="validateGroup()"
                     placeholder="Enter email">
            <dx-validator>
                <dxi-validation-rule type="required"
                                     message="Email is required"></dxi-validation-rule>
                <dxi-validation-rule type="email"></dxi-validation-rule>
            </dx-validator>
        </dx-text-box>
    </div>
</ng-template>
