<div class="row">
    <div class="col-12 col-md-6 col-xl-4">
        <div class="alert alert-info">
            Show the ASP.Net roles with additional privileges allocated to our users.
            <ul>
                <li>Stakeholders get access to runADAPT</li>
                <li>Administrator is a role that should not be allocated to anyone</li>
            </ul>
            We don't list people allocated to the standard ASP.Net User role, since everyone should have that role.
        </div>

        <button class="mt-2"
                adaptButtonIcon
                [adaptBlockingClick]="fetch">
            Fetch users with privileged ASP.Net roles
        </button>

        <div *ngIf="personRoles">
            <dx-data-grid [dataSource]="personRoles"
                          [allowColumnReordering]="true"
                          [allowColumnResizing]="true"
                          [columnAutoWidth]="true"
                          [rowAlternationEnabled]="true">
                <dxo-group-panel [visible]="true"></dxo-group-panel>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-header-filter [visible]="true"></dxo-header-filter>
                <dxo-paging [pageSize]="100"></dxo-paging>

                <dxi-column dataField="RoleName"
                            caption="ASP.Net Role"
                            [groupIndex]="0"></dxi-column>
                <dxi-column dataField="PersonName"
                            caption="Person Name"></dxi-column>
                <dxi-column dataField="PersonId"></dxi-column>
            </dx-data-grid>
        </div>
    </div>
</div>
