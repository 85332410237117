import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Team, TeamBreezeModel } from "@common/ADAPT.Common.Model/organisation/team";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IDxSelectBoxValueChangedEvent } from "@common/ux/dx.types";
import { NimbusDataService } from "app/services/nimbus-data.service";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { lastValueFrom } from "rxjs";

@Component({
    selector: "adapt-select-team",
    templateUrl: "./select-team.component.html",
})
export class SelectTeamComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() public organisation?: Organisation;
    @Input() public showClearButton = false;
    @Output() public teamChange = new EventEmitter<Team>();

    @Input() public filter?: (team: Team) => boolean;

    public teamId?: number;

    private team?: Team;

    public dataSource?: DataSource;

    public constructor(
        private commonDataService: CommonDataService,
        private nimbusDataService: NimbusDataService,
    ) {
        super();
    }

    public ngOnInit() {
        this.setDataSource();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.organisation) {
            this.onOrganisationChanged();
        }
    }

    private async setDataSource() {
        const teams = await this.fetchTeams();

        this.dataSource = new DataSource({
            store: new ArrayStore({
                data: teams,
                key: "teamId",
            }),
            filter: this.filter,
        });
    }

    private fetchTeams() {
        if (!this.organisation) {
            return lastValueFrom(this.commonDataService.getAll(TeamBreezeModel));
        }

        return lastValueFrom(this.nimbusDataService.getTeamsByOrganisation(this.organisation));
    }

    private onOrganisationChanged() {
        this.team = undefined;
        this.teamId = undefined;

        this.teamChange.emit(this.team);

        this.setDataSource();
    }

    public async onTeamIdChanged(e: IDxSelectBoxValueChangedEvent<number>) {
        if (e.value === e.previousValue) {
            return;
        }

        if (e.value) {
            this.team = await lastValueFrom(this.commonDataService.getById(TeamBreezeModel, e.value));
        } else {
            this.team = undefined;
            this.teamId = undefined;
        }

        this.teamChange.emit(this.team);
    }
}
