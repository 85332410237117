import { HttpClient } from "@angular/common/http";
import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { DeepDive } from "@common/ADAPT.Common.Model/embed/deep-dive";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { DeepDiveService } from "@common/workflow/deep-dive.service";
import CustomFileSystemProvider from "devextreme/file_management/custom_provider";
import { dxFileManagerDetailsColumn, SelectedFileOpenedEvent, SelectionChangedEvent } from "devextreme/ui/file_manager";
import { from, lastValueFrom } from "rxjs";
import { MaxAllowedFileSize } from "../deep-dive-constants";

interface IAssetFile {
    Name: string;
    Path: string;
    Size: number;
}

@Component({
    selector: "adapt-select-deep-dive-image-dialog",
    templateUrl: "./select-deep-dive-image-dialog.component.html",
    styleUrls: ["./select-deep-dive-image-dialog.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class SelectDeepDiveImageDialogComponent extends BaseDialogWithDiscardConfirmationComponent<DeepDive> {
    public readonly dialogName = "DeepDiveSelectImage";
    public readonly entitiesToConfirm = [this.deepDive];
    public readonly autoResolveData = this.deepDive;

    public readonly maxAllowedFileSize = MaxAllowedFileSize;

    public fileSystemProvider: CustomFileSystemProvider;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public deepDive: DeepDive,
        private deepDiveService: DeepDiveService,
        private httpClient: HttpClient,
    ) {
        super();

        this.fileSystemProvider = new CustomFileSystemProvider({
            isDirectoryExpr: () => false,
            keyExpr: "Name",
            nameExpr: "Name",
            sizeExpr: "Size",
            getItems: this.getFiles,
            thumbnailExpr: (file: IAssetFile) => `${this.deepDive.extensions.assetUrl}/${file.Name}`,
            uploadFileChunk: this.uploadFile,
        });
    }

    @Autobind
    public getFiles() {
        return lastValueFrom(this.httpClient.get<IAssetFile[]>(this.deepDiveService.listAssetsUri({ location: this.deepDive.extensions.storageSlug })));
    }

    @Autobind
    public uploadFile(file: File) {
        const formData = new FormData();
        formData.append("file", file);
        return lastValueFrom(this.httpClient.post(this.deepDiveService.uploadAssetUri({ location: this.deepDive.extensions.storageSlug }), formData));
    }

    @Autobind
    public customizeDetailColumns(columns: dxFileManagerDetailsColumn[]) {
        const dateColumn = columns.find((i) => i.caption === "Date Modified");
        if (dateColumn) {
            dateColumn.visible = false;
        }
        return columns;
    }

    public onSelectedFileOpened(event: SelectedFileOpenedEvent) {
        this.deepDive.image = event.file.name;
        from(this.saveAndClose()).pipe(
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public onSelectionChanged(event: SelectionChangedEvent) {
        const file = ArrayUtilities.getSingleFromArray(event.selectedItems);
        if (file) {
            this.deepDive.image = file.name;
        }
    }
}
