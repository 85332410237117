import { Component, Input } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { AuditService, IAuthAudit } from "../audit.service";

@Component({
    selector: "adapt-auth-audit",
    templateUrl: "./auth-audit.component.html",
})
export class AuthAuditComponent {
    @Input() public person?: Person;
    @Input() public organisation?: Organisation;
    @Input() public showUserFilter?: boolean;

    public startDate = new Date();
    public endDate = new Date();
    public authList: IAuthAudit[] = [];

    public actionLogin = true;
    public actionLogout = true;

    public resultSuccess = true;
    public resultFailure = true;

    public reasonUnknownUsr = true;
    public reasonIncorrectPswd = true;
    public reasonOther = true;

    public errorMessage?: string;

    constructor(private auditService: AuditService) { }

    @Autobind
    public async getAuthResults() {
        this.authList = [];
        const userName = this.person?.methodologyUser?.userName ?? "";

        const filters = {
            userName,
            startDate: this.startDate,
            endDate: this.endDate,

            actionLogin: this.actionLogin,
            actionLogout: this.actionLogout,

            resultSuccess: this.resultSuccess,
            resultFailure: this.resultFailure,

            reasonUnknownUsr: this.reasonUnknownUsr,
            reasonIncorrectPswd: this.reasonIncorrectPswd,
            reasonOther: this.reasonOther,
        };

        this.authList = await this.auditService.getFilteredAuths(filters);
    }

    @Autobind
    public personFilter(person: Person) {
        // connections may not be present if loading page directly
        if (!this.organisation || person.connections.length === 0) {
            return true;
        }

        return person.connections.some((c) => c.organisationId === this.organisation?.organisationId);
    }
}
