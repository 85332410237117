<div class="row"
     *adaptLoading="!account">
    <!-- payment options -->
    <div class="col-md-6">
        <div class="card card-info">
            <div class="card-header">
                <h3 class="card-title">Payment terms</h3>
                <div class="card-header-buttons"
                     adaptBlockingButtonGroup>
                    <button *ngIf="!editPaymentMode && !editBillingDetailsMode"
                            adaptButton="edit"
                            (click)="editPayment()"></button>
                    <button *ngIf="editPaymentMode"
                            adaptButton="save"
                            [adaptBlockingClick]="savePayment"></button>
                    <button *ngIf="editPaymentMode"
                            adaptButton="cancel"
                            [adaptBlockingClick]="cancelPayment"></button>
                </div>
            </div>
            <div class="card-body">

                <div role="alert"
                     class="alert alert-danger"
                     *ngIf="errorMessage">{{errorMessage}}
                </div>

                <form name="paymentTermsForm">
                    <div class="form-group">
                        <label for="subscriptionStatus">Subscription status:</label>
                        <div class="lead pt-2 d-flex align-items-center gap-3">
                            <b>{{account!.status}}</b>
                            <ng-container *ngIf="editPaymentMode">
                                <i class="fal fa-arrow-right-long-to-line"
                                   adaptTooltip="select a transition on the right"></i>
                                <dx-select-box [items]="ValidStatusTransitions[account!.status]"
                                               (valueChange)="onStatusTransitionSelected($event)"></dx-select-box>
                            </ng-container>
                        </div>
                        <div *ngIf="editPaymentMode && !statusTransition"
                             class="mt-2 small text-muted">(Note: if you want to commence or cancel a subscription, please use the data
                            platform)</div>
                    </div>

                    <div *ngIf="account!.extensions.hasSubscriptionSubStatus"
                         class="form-group">
                        <label for="subscriptionSubStatus">Subscription sub status:</label>
                        <div class="lead pt-2"><b>{{account!.subStatus}}</b></div>
                        <small *ngIf="account!.extensions.isExpiredTrial"
                               class="d-block form-text text-muted mt-2">This trial expired on {{account!.nextSubscriptionInvoiceDate |
                            adaptDate}}.</small>
                    </div>

                    <div *ngIf="isArchiving"
                         role="alert"
                         class="mt-3 alert alert-danger">
                        <p>Impact of archiving an organisation:</p>
                        <ul>
                            <li>Organisation account status will be set to inactive</li>
                            <li>All roles &amp; users will have their edit privileges removed</li>
                            <ul>
                                <li>Users can still log in</li>
                                <li>Users can read all data they have currently entered</li>
                                <li>Users can not add/remove people from the organisation</li>
                            </ul>
                            <li>All coach connections to the organisaton will be ended</li>
                        </ul>
                        <p>The organisation will be archived after clicking 'Save'.</p>
                    </div>

                    <div *ngIf="billingCommencing"
                         class="alert alert-warning">
                        Billing for this organisation will commence when the next billing job runs (10am AWST).
                        If needed, adjust the commencement date and the billing day before saving.
                    </div>

                    <div>
                        <hr />

                        <div class="form-group">
                            <label for="inputStartDate"
                                   *ngIf="account!.extensions.isTrial; else notTrial">Trial end date</label>
                            <ng-template #notTrial>
                                <label for="inputStartDate"
                                       *ngIf="account!.extensions.isPendingCancellation; else notPendingCancellation">
                                    Cancelled subscription end date</label>
                                <ng-template #notPendingCancellation>
                                    <label for="inputStartDate">Next subscription charge date</label>
                                </ng-template>
                            </ng-template>
                            <dx-date-box id="inputStartDate"
                                         style="width: 200px;"
                                         name="inputStartDate"
                                         [value]="account!.nextSubscriptionInvoiceDate"
                                         (valueChange)="account!.nextSubscriptionInvoiceDate = $any($event)"
                                         [disabled]="!editPaymentMode || (account!.extensions.isTrial && statusTransition !== SubscriptionStatusTransition.ExtendTrial)"></dx-date-box>
                            <span class="small"
                                  *ngIf="account!.extensions.isTrial && editPaymentMode">
                                (Can only be changed by selecting 'Extend trial' on the subscription status actions above)</span>
                            <span class="small"
                                  *ngIf="account!.extensions.isActive && editPaymentMode">
                                (Change the date here ONLY if you want to extend an existing customers payment period)</span>
                        </div>

                        <hr />

                        <div class="form-group">
                            <dx-check-box id="inputTaxExempt"
                                          [value]="account!.taxExempt"
                                          (valueChange)="account!.taxExempt = $any($event)"
                                          [disabled]="!editPaymentMode"
                                          text="Is GST Exempt?"></dx-check-box>
                            <small class="d-block form-text text-muted">(Companies registered outside of Australia should be GST
                                Exempt)</small>
                        </div>

                        <hr />

                        <div class="form-group mt-3">
                            <label for="inputPaymentMethod">Payment method:</label>
                            <dx-select-box id="inputPaymentMethod"
                                           name="inputPaymentMethod"
                                           [(value)]="account!.paymentMethod"
                                           (valueChange)="onPaymentMethodChanged()"
                                           [items]="paymentMethods"
                                           valueExpr="id"
                                           displayExpr="name"
                                           [disabled]="!editPaymentMode"
                                           [width]="200"></dx-select-box>
                            <small *ngIf="account!.paymentMethod === PaymentMethod.Invoice"
                                   class="d-block form-text text-muted">(Invoiced means that the accounts team at adapt invoices the
                                customer. The platform does not handle ANY subscription amounts or charging)</small>
                        </div>

                        <ng-container *ngIf="!isInvoiced">
                            <div class="form-group mt-3">
                                <label for="inputPaymentFrequency">Payment frequency:</label>
                                <dx-select-box id="inputPaymentFrequency"
                                               name="inputPaymentFrequency"
                                               [(value)]="account!.billingPeriod"
                                               [items]="billingPeriods"
                                               valueExpr="id"
                                               displayExpr="name"
                                               [disabled]="!editPaymentMode || !!account!.pricingModel?.pricingModelUsers?.length || isInvoiced"
                                               [width]="200">
                                </dx-select-box>
                                <small *ngIf="editPaymentMode && account!.pricingModel?.pricingModelUsers?.length"
                                       class="text-muted">(Monthly payment frequency only available when pricing model has a per user
                                    charge)</small>
                            </div>

                            <label for="pricingModel">Pricing model</label>
                            <dx-select-box id="pricingModel"
                                           name="pricingModel"
                                           [showClearButton]="true"
                                           [(value)]="account!.pricingModelId"
                                           (valueChange)="onPricingModelChanged()"
                                           [items]="pricingModels"
                                           valueExpr="pricingModelId"
                                           displayExpr="name"
                                           [disabled]="!editPaymentMode || isInvoiced"
                                           [width]="200"></dx-select-box>

                            <div class="form-group mt-3">
                                <label for="currentMonthlyFee">Additional monthly fee</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">{{account!.currency?.shortName}}</span>
                                    </div>
                                    <dx-number-box id="currentMonthlyFee"
                                                   name="currentMonthlyFee"
                                                   [min]="0"
                                                   [(value)]="account!.monthlyFeeDollars"
                                                   [disabled]="!editPaymentMode || isInvoiced"></dx-number-box>

                                </div>
                                <small *ngIf="editPaymentMode"
                                       class="text-muted">(Add any additional custom/agreed upon fees here that aren't covered by a pricing
                                    model)</small>
                            </div>
                        </ng-container>

                        <div *ngIf="!account!.extensions.isFree"
                             class="alert alert-warning mt-3">
                            <div class=" form-group">
                                <label>Monthly cost</label>
                                <adapt-display-pricing-breakdown [pricingModel]="account!.pricingModel"
                                                                 [organisation]="organisation"></adapt-display-pricing-breakdown>
                            </div>

                            <div class="form-group mt-3">
                                <label for="currentMonthlyFee">Yearly cost</label>
                                <div class="input-group gap-1">
                                    <b>{{account!.extensions.annualSubscriptionCost | currency:account!.currency?.code?.toUpperCase()}}</b>
                                    ex GST
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <!-- billing details -->
    <div class="col-md-6">
        <div *ngIf="account!.extensions.isActive || account!.extensions.isTrial || account!.extensions.isPendingCancellation"
             class="card card-info">
            <div class="card-header">
                <h3 class="card-title">Billing contact</h3>
                <div class="card-header-buttons"
                     adaptBlockingButtonGroup>
                    <button *ngIf="!editPaymentMode && !editBillingDetailsMode"
                            adaptButton="edit"
                            (click)="editBillingDetails()"></button>
                    <button *ngIf="editBillingDetailsMode"
                            adaptButton="save"
                            [adaptBlockingClick]="saveBillingDetails"></button>
                    <button *ngIf="editBillingDetailsMode"
                            adaptButton="cancel"
                            [adaptBlockingClick]="cancelBillingDetails"></button>
                </div>
            </div>
            <div class="card-body">
                <form>
                    <div class="form-group">
                        <label for="inputBillingName">Billing name:</label>
                        <dx-text-box id="inputBillingName"
                                     name="inputBillingName"
                                     [(value)]="account!.contactName"
                                     [disabled]="!editBillingDetailsMode">
                            <dx-validator>
                                <dxi-validation-rule [adaptEntity]="account"
                                                     adaptEntityProperty="contactName"></dxi-validation-rule>
                            </dx-validator>
                        </dx-text-box>
                    </div>
                    <div class="form-group">
                        <label for="inputBillingEmail">Billing email:</label>
                        <dx-text-box id="inputBillingEmail"
                                     name="inputBillingEmail"
                                     [(value)]="account!.contactEmail"
                                     [disabled]="!editBillingDetailsMode">
                            <dx-validator>
                                <dxi-validation-rule type="email"></dxi-validation-rule>
                                <dxi-validation-rule [adaptEntity]="account"
                                                     adaptEntityProperty="contactEmail"></dxi-validation-rule>
                            </dx-validator>
                        </dx-text-box>
                    </div>
                    <div class="form-group">
                        <label for="inputBillingAddress">Postal address:</label>
                        <dx-text-area id="inputBillingAddress"
                                      name="inputBillingAddress"
                                      [(value)]="account!.contactAddress"
                                      [inputAttr]="{ rows: 5 }"
                                      [disabled]="!editBillingDetailsMode">
                            <dx-validator>
                                <dxi-validation-rule [adaptEntity]="account"
                                                     adaptEntityProperty="contactAddress"></dxi-validation-rule>
                            </dx-validator>
                        </dx-text-area>
                    </div>
                </form>
            </div>
        </div>

        <div *ngIf="account!.extensions.isBilledUsingCreditCard"
             class="card card-info">
            <div class="card-header">
                <h3 class="card-title">Payment processor customer details</h3>
            </div>
            <div class="card-body">
                <label>Default payment card</label>

                <div class="mb-3">
                    <div *ngIf="!account!.extensions.isCreditCardSet; else hasCreditCard"
                         class="alert alert-warning">This account does not have a paymentProcessorCustomerId set!</div>

                    <ng-template #hasCreditCard>
                        <div *adaptLoading="cardDetailsLoading">
                            <dd *ngIf="cardDetails">
                                <div *ngIf="cardDetails.name">Name: {{cardDetails.name}}</div>
                                <div>{{cardDetails.brand}} **** **** **** {{cardDetails.last4}}</div>
                                <div>Exp: {{cardDetails.expiryMonth}} / {{cardDetails.expiryYear}}</div>
                            </dd>

                            <ng-container *ngIf="!cardDetails">No default card exists for this paymentProcessorCustomerId.</ng-container>
                        </div>
                    </ng-template>
                </div>

                <button class="btn btn-primary"
                        [adaptBlockingClick]="removeDefaultCard"
                        [disabled]="!cardDetails">Remove default card</button>
            </div>
            <div *ngIf="account!.extensions.isCreditCardSet"
                 class="card-footer text-end">
                <a href="https://dashboard.stripe.com/customers/{{account!.paymentProcessorCustomerId}}"
                   target="_blank">
                    <i class="fal fa-fw fa-external-link-alt"></i>
                    Open Stripe dashboard for customer <code>{{account!.paymentProcessorCustomerId}}</code>
                </a>
            </div>
        </div>
    </div>
</div>
