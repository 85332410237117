import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { UserType } from "@common/ADAPT.Common.Model/embed/user-type";
import { ConnectionType } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { DxValidationGroupComponent } from "devextreme-angular";
import { INewPersonModel, PersonAction } from "../new-coach.interface";

@Component({
    selector: "adapt-new-coach-person",
    templateUrl: "./new-coach-person.component.html",
})
export class NewCoachPersonComponent implements OnInit {
    @Input() public person?: Person;
    @Output() public personChange = new EventEmitter<Person>();

    // temporary person holds the person info when creating a new person.
    // the model doesn't line up with a Person object (such as no email field on Person)
    // so needs to be handled differently.
    @Input() public temporaryPerson?: INewPersonModel;
    @Output() public temporaryPersonChange = new EventEmitter<INewPersonModel>();

    public actionSelection = PersonAction.Existing;

    @ViewChild("validationGroup") public validationGroup: DxValidationGroupComponent | undefined;

    public PersonAction = PersonAction;
    public readonly PersonActionSelection = [
        {
            text: "Select an existing person",
            value: PersonAction.Existing,
        },
        {
            text: "Create new person",
            value: PersonAction.CreateNew,
        },
    ];

    public ngOnInit() {
        if (this.temporaryPerson) {
            this.actionSelection = PersonAction.CreateNew;
        } else {
            this.actionSelection = PersonAction.Existing;
        }
    }

    public createTemporaryPerson(): INewPersonModel {
        return {
            firstName: "",
            lastName: "",
            email: "",
            roleIds: [],
            connectionType: ConnectionType.Coach,
            userType: UserType.Coach,
            hasAccess: true,
            valid: false,
        };
    }

    public validateGroup() {
        this.temporaryPerson!.valid = this.validationGroup?.instance.validate().isValid ?? false;
        // this.temporaryPersonChange.emit(this.temporaryPerson);
    }

    public onPersonActionChanged() {
        this.person = undefined;
        this.temporaryPerson = undefined;

        if (this.actionSelection === PersonAction.CreateNew) {
            this.temporaryPerson = this.createTemporaryPerson();
        }

        this.personChange.emit(this.person);
        this.temporaryPersonChange.emit(this.temporaryPerson);
    }
}
