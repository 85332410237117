import { Component, Injector, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Role, RoleBreezeModel } from "@common/ADAPT.Common.Model/organisation/role";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { NimbusDataService } from "app/services/nimbus-data.service";
import { lastValueFrom } from "rxjs";
import { IRoleMapping, ISystemRole, StakeholderServicesService } from "../stakeholder-services.service";

export enum RoleAction {
    Existing,
    CreateNew
}

@Component({
    selector: "adapt-copy-team-page",
    templateUrl: "./copy-team-page.component.html",
})
export class CopyTeamPageComponent extends BaseRoutedComponent implements OnInit, OnChanges {
    public readonly RoleAction = RoleAction;
    public readonly RoleActionSelection = [
        {
            text: "Select an existing role",
            value: RoleAction.Existing,
        },
        {
            text: "Create a new role",
            value: RoleAction.CreateNew,
        },
    ];

    public isMyMathsOk = false;
    public sourceOrganisation?: Organisation;
    public team?: Team;
    public destOrganisation?: Organisation;
    public isComplete = false;
    public error?: string;
    public loading = false;
    public extendedCopy = false;

    public destTeamName?: string;

    public roleAssignments?: IRoleMapping[];
    public processMapRoles?: ISystemRole[];

    private destOrganisationRoles?: Role[];

    private isCompleteUpdater = this.createThrottledUpdater<boolean>((isComplete) => this.isComplete = isComplete);

    public constructor(
        private commonDataService: CommonDataService,
        private nimbusDataService: NimbusDataService,
        private commonDialogService: AdaptCommonDialogService,
        private stakeholderServices: StakeholderServicesService,
        injector: Injector,
    ) {
        super(injector);
    }

    public ngOnInit() {
        this.notifyActivated();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.isMyMathsOk) {
            this.validateSelection();
        }
    }

    public roleFilter(role: Role) {
        return !role.teamId && (role.extensions.isCulturalLeaderRole() || !role.extensions.hasAccessPermissions());
    }

    @Autobind
    public async promiseToCopyTeam() {
        this.error = "";

        try {
            const teamId = await lastValueFrom(this.stakeholderServices.copyTeam({
                TeamId: this.team!.teamId,
                DestinationOrganisationId: this.destOrganisation!.organisationId,
                RoleIdMapping: this.roleAssignments ?? [],
                DestinationTeamName: this.destTeamName,
                ExtendedCopy: this.extendedCopy,
            }));

            const url = new URL(`/${this.destOrganisation?.urlIdentifier}/team/${teamId}/dashboard`, AdaptClientConfiguration.FrontEndBaseUri);

            await lastValueFrom(this.commonDialogService.showMessageDialog("Success!", `
                <p>Team has been copied successfully.</p>
                <p><a target="_blank" href="${url}">${url}</a></p>
            `));
        } catch (e) {
            this.error = ErrorHandlingUtilities.getHttpResponseMessage(e);
        }
    }

    @Autobind
    public excludeSourceOrganisation(organisation: Organisation) {
        return organisation !== this.sourceOrganisation;
    }

    @Autobind
    private validateSelection() {
        this.isCompleteUpdater.next(this.hasAllRequiredFields());
    }

    public hasAllRequiredFields() {
        return !!this.sourceOrganisation
            && !!this.team
            && !!this.destOrganisation
            && !!this.destTeamName
            && (this.roleAssignments?.every((a) => !!a.DestinationRoleId || !!a.DestinationRoleName) ?? false);
    }

    @Autobind
    public async onTeamChanged(team: Team) {
        const previousTeam = this.team;
        this.team = team;

        if (this.team && this.team.teamId) {
            // only update dest team name if not manually changed
            if (!this.destTeamName || this.destTeamName === previousTeam?.name) {
                this.destTeamName = this.team.name;
            }

            const systemLocations = await lastValueFrom(this.nimbusDataService.getSystemLocationsForTeam(this.team));
            const processMapRoles = await Promise.all(systemLocations
                .map((loc) => loc.systemEntityId)
                .map((systemEntityId) => this.stakeholderServices.getProcessMapRolesFromSystem({ systemEntityId })));

            this.processMapRoles = ArrayUtilities.distinct(processMapRoles.flat());
        }

        if (this.destOrganisation) {
            this.roleAssignments = [];
            this.processMapRoles?.forEach((role) => this.getAssignedRole(role.RoleId));
        }

        this.validateSelection();
    }

    @Autobind
    public async onDestinationOrganisationChanged(destOrganisation: Organisation) {
        this.destOrganisation = destOrganisation;

        this.roleAssignments = [];
        this.processMapRoles?.forEach((role) => this.getAssignedRole(role.RoleId));

        const allDestinationOrganisationRoles = await lastValueFrom(this.nimbusDataService.getRolesByOrganisation(this.destOrganisation));
        this.destOrganisationRoles = allDestinationOrganisationRoles.filter(this.roleFilter);

        this.validateSelection();
    }

    public getAssignedRole(sourceRoleId: number) {
        let assignment = this.roleAssignments!.find((i) => i.SourceRoleId === sourceRoleId);

        if (!assignment) {
            assignment = { SourceRoleId: sourceRoleId };
            this.roleAssignments!.push(assignment);
        }

        this.validateSelection();

        return assignment;
    }

    public updateAssignedRole(assignment: IRoleMapping, newRole?: Role) {
        assignment.DestinationRoleId = newRole?.roleId;
        assignment.DestinationRoleName = undefined;

        this.validateSelection();
    }

    public updateAssignedRoleName(assignment: IRoleMapping, newRoleName?: string) {
        assignment.DestinationRoleId = undefined;
        assignment.DestinationRoleName = newRoleName ?? "";

        this.validateSelection();
    }

    public updateTeamName(newTeamName?: string) {
        this.destTeamName = newTeamName;

        this.validateSelection();
    }

    public async updateAssignment(assignment: IRoleMapping, value: RoleAction) {
        assignment.DestinationRoleId = undefined;
        assignment.DestinationRoleName = undefined;

        const role = await lastValueFrom(this.commonDataService.getById(RoleBreezeModel, assignment.SourceRoleId));

        if (value === RoleAction.CreateNew) {
            assignment.DestinationRoleName = role?.label ?? "New role name";
        } else {
            const matchingRole = role?.label
                ? this.destOrganisationRoles?.find((r) => r.label.includes(role.label))
                : undefined;
            assignment.DestinationRoleId = matchingRole?.roleId;
        }

        this.validateSelection();
    }

    public getAssignmentAction(assignment: IRoleMapping) {
        return assignment.DestinationRoleName === undefined
            ? RoleAction.Existing
            : RoleAction.CreateNew;
    }
}
