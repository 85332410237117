<dx-tab-panel *ngIf="isInitialised"
              [items]="items"
              [selectedItem]="selectedTab"
              (onContentReady)="onContentReady()"
              (selectedItemChange)="onSelectedItemChanged($event)">
    <div *dxTemplate="let item of 'generalTemplate'">
        <adapt-organisation-details [organisation]="organisation">
        </adapt-organisation-details>
    </div>

    <div *dxTemplate="let item of 'accountTemplate'">
        <adapt-account-details [organisation]="organisation"></adapt-account-details>
    </div>

    <div *dxTemplate="let item of 'paymentsTemplate'">
        <adapt-payments [organisation]="organisation"></adapt-payments>
    </div>

    <div *dxTemplate="let item of 'peopleTemplate'">
        <adapt-people-list [filterForOrganisationId]="organisation?.organisationId"></adapt-people-list>
    </div>

    <div *dxTemplate="let item of 'coachTemplate'">
        <adapt-coach-relationships [organisation]="organisation"></adapt-coach-relationships>
    </div>

    <div *dxTemplate="let name of 'dataTemplate'">
        <adapt-data-audit [organisation]="organisation"
                          [showUserFilter]="true"></adapt-data-audit>
    </div>

    <div *dxTemplate="let name of 'pathwayTemplate'">
        <adapt-pathways [organisation]="organisation"></adapt-pathways>
    </div>

    <div *dxTemplate="let name of 'coachSessionsTemplate'">
        <ng-template #sessionActionTemplate
                     let-coachSession
                     let-updated="updated">
            <adapt-coach-session-actions [coachSession]="coachSession"
                                         (updated)="updated()"></adapt-coach-session-actions>
        </ng-template>

        <ng-template #sessionCoachTemplate
                     let-coach>
            <adapt-link-coach [coach]="coach"></adapt-link-coach>
        </ng-template>

        <ng-template #sessionPersonTemplate
                     let-person>
            <adapt-link-person [person]="person"></adapt-link-person>
        </ng-template>

        <adapt-coach-sessions-grid [organisation]="organisation"
                                   [showInternalData]="true"
                                   [actionTemplate]="sessionActionTemplate"
                                   [coachTemplate]="sessionCoachTemplate"
                                   [personTemplate]="sessionPersonTemplate"></adapt-coach-sessions-grid>
    </div>
</dx-tab-panel>
