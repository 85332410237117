import { BaseActiveEntity } from "../base-active-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Partner } from "../partner/partner";
import { Person } from "../person/person";
import { CoachType } from "./coach-type";

export class Practitioner extends BaseActiveEntity<Practitioner> {
    public practitionerId!: number;
    public personId!: number;
    public notes?: string;
    public startDate!: Date;
    public endDate?: Date;
    public partnerId?: number;
    public isSubscribing!: boolean;
    public coachType!: CoachType;
    public isAccreditedRBCoach!: boolean;
    public isAccreditedOwnerMentor!: boolean;

    public person!: Person;
    public partner?: Partner;
}

export const PractitionerBreezeModel = new BreezeModelBuilder("Practitioner", Practitioner)
    .hasSource()
    .withIdField()
    .withSingularName("Coach")
    .withPluralNameAndNoSourceOverride("Coaches")
    .alwaysFetchingNestedNavigationProperty("person.methodologyUser")
    .alwaysFetchingNestedNavigationProperty("person.personContacts")
    .alwaysFetchingNestedNavigationProperty("person.connections")
    .alwaysFetchingNestedNavigationProperty("person.preferences")
    .build();
