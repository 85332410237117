<adapt-dialog [maxWidth]="400">
    <h3 adapt-dialog-title>{{isNew ? 'Add' : 'Edit'}} Coach Connection</h3>

    <ng-container adapt-dialog-content>
        <form role="form">
            <div class="form-group">
                <label for="selectCoach">Coach</label>
                <adapt-select-coach *ngIf="isNew; else editConnectionPersonTemplate"
                                    id="selectCoach"
                                    [(person)]="connection.person"
                                    [required]="true"></adapt-select-coach>
                <ng-template #editConnectionPersonTemplate>
                    <adapt-link-person id="selectCoach"
                                       class="d-block"
                                       [person]="connection.person"></adapt-link-person>
                </ng-template>
            </div>
            <div class="form-group">
                <label for="startDate">Start Date</label>
                <dx-date-box id="startDate"
                             type="date"
                             [value]="connection.startDate"
                             (valueChange)="connection.startDate = $any($event)"></dx-date-box>
            </div>
            <div class="form-group">
                <label for="endDate">End Date</label>
                <dx-date-box id="endDate"
                             type="date"
                             [min]="connection.startDate"
                             [value]="connection.endDate"
                             (valueChange)="connection.endDate = $any($event)"></dx-date-box>
            </div>
        </form>
    </ng-container>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [discardConfirmationInstance]="instance">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
