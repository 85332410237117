import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { IdentityService } from "@common/identity/identity.service";
import { loginPageRoute } from "@common/identity/ux/login-page/login-page.route";
import { IGuard } from "@common/route/guard.interface";
import { lastValueFrom } from "rxjs";
import { landingPageRoute } from "./services/landing-page/landing-page.route";

@Injectable({
    providedIn: "root",
})
export class BaseGuard implements IGuard {
    public static readonly Id = BaseGuard;

    constructor(
        private router: Router,
        private identityService: IdentityService,
    ) { }

    public async canActivate() {
        const isLoggedIn = await this.identityService.promiseToCheckIsLoggedIn();
        const route = isLoggedIn
            ? await lastValueFrom(landingPageRoute.getRoute())
            : await lastValueFrom(loginPageRoute.getRoute());

        return this.router.parseUrl(route);
    }
}
