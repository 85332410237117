import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Board, BoardBreezeModel } from "@common/ADAPT.Common.Model/organisation/board";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { lastValueFrom } from "rxjs";

@Component({
    selector: "adapt-select-board",
    templateUrl: "./select-board.component.html",
})
export class SelectBoardComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() public team?: Team;
    @Output() public boardChange = new EventEmitter<Board>();

    public board?: Board;

    public boards?: Board[];

    public constructor(
        private commonDataService: CommonDataService,
    ) {
        super();
    }

    public async ngOnInit() {
        await this.fetchBoards();
    }

    public async ngOnChanges(changes: SimpleChanges) {
        if (changes.team) {
            await this.onTeamChanged();
        }
    }

    private async fetchBoards() {
        let predicate: MethodologyPredicate<Board> | undefined;

        if (this.team) {
            predicate = new MethodologyPredicate<Board>("teamId", "==", this.team.teamId);
        }

        this.boards = await lastValueFrom(this.commonDataService.getByPredicate(BoardBreezeModel, predicate));
    }

    public onBoardIdChanged() {
        this.boardChange.emit(this.board);
    }

    private async onTeamChanged() {
        this.board = undefined;

        this.boardChange.emit(this.board);

        await this.fetchBoards();
    }
}
