<div class="d-flex">
    <div class="form-group pe-3">
        <label for="inputStartDate">Filter start date</label>
        <dx-date-box id="inputStartDate"
                     style="width: 200px;"
                     name="inputStartDate"
                     [value]="startDate"
                     (valueChange)="startDate = $any($event)"></dx-date-box>
    </div>

    <div class="form-group pe-3">
        <label for="inputEndDate">Filter end date</label>
        <dx-date-box id="inputEndDate"
                     style="width: 200px;"
                     name="inputEndDate"
                     [value]="endDate"
                     (valueChange)="endDate = $any($event)"></dx-date-box>
    </div>
</div>

<div *ngIf="showUserFilter"
     class="form-group">
    <label for="selectPerson">Person</label>
    <adapt-select-person id="selectPerson"
                         [filter]="personFilter"
                         [(person)]="person"></adapt-select-person>
</div>

<div *ngIf="showOrgFilter"
     class="form-group">
    <label for="selectOrg">Organisation</label>
    <adapt-select-organisation id="selectOrg"
                               [(organisation)]="organisation"></adapt-select-organisation>
</div>


<div id="changeTypeFilter"
     class="form-group">
    <label for="changeTypeFilter">Change Type</label>
    <div class="d-flex">
        <div class="form-group pe-3">
            <dx-check-box id="filterChangeTypeAdded"
                          text="Added"
                          [value]="changeTypeAdded"
                          (valueChange)="changeTypeAdded = $any($event)"></dx-check-box>
        </div>

        <div class="form-group pe-3">
            <dx-check-box id="filterChangeTypeModified"
                          text="Modified"
                          [value]="changeTypeModified"
                          (valueChange)="changeTypeModified = $any($event)"></dx-check-box>
        </div>

        <div class="form-group pe-3">
            <dx-check-box id="filterChangeTypeDeleted"
                          text="Deleted"
                          [value]="changeTypeDeleted"
                          (valueChange)="changeTypeDeleted = $any($event)"></dx-check-box>
        </div>
    </div>
</div>

<div class="form-group">
    <label for="inputData">Data (Single value only):</label>
    <dx-text-box id="inputData"
                 name="inputData"
                 [(value)]="dataFilter">
    </dx-text-box>
</div>

<div class="form-group">
    <label for="inputEntId">Entity ID (CSV):</label>
    <dx-text-box id="inputEntId"
                 name="inputEntId"
                 [(value)]="entIdFilter">
    </dx-text-box>
</div>

<div class="form-group">
    <label for="inputEntType">Entity Type (CSV):</label>
    <dx-text-box id="inputEntType"
                 name="inputEntType"
                 [(value)]="entTypeFilter">
    </dx-text-box>
</div>

<div class="form-group">
    <button adaptButtonIcon="fal fa-fw fa-sign-in"
            class="btn btn-primary"
            data-test="submit-btn"
            type="submit"
            [adaptBlockingClick]="getDataResults">
        FETCH!
    </button>
</div>

<dx-data-grid [dataSource]="dataList"
              [allowColumnReordering]="true"
              [allowColumnResizing]="true"
              [columnAutoWidth]="true"
              [rowAlternationEnabled]="true"
              [showRowLines]="true"
              [showBorders]="true">
    <!-- Options -->
    <dxo-header-filter [visible]="true">
    </dxo-header-filter>
    <dxo-filter-row [visible]="true">
    </dxo-filter-row>
    <dxo-column-chooser [enabled]="true">
    </dxo-column-chooser>
    <dxo-pager [showPageSizeSelector]="true"
               [allowedPageSizes]="[10, 25, 50, 100, 250]">
    </dxo-pager>

    <!-- Columns -->
    <dxi-column caption="Organisation ID"
                dataField="partitionKey"
                dataType="number"
                [visible]="!this.organisation">
    </dxi-column>

    <dxi-column caption="Timestamp"
                dataField="timestamp"
                dataType="string"
                sortOrder="desc"
                [sortIndex]="0">
    </dxi-column>

    <dxi-column caption="Change Type"
                dataField="ChangeType"
                dataType="string">
    </dxi-column>

    <dxi-column caption="Entity ID"
                dataField="EntityId"
                dataType="string">
    </dxi-column>

    <dxi-column caption="Entity Type"
                dataField="EntityType"
                dataType="int">
    </dxi-column>

    <dxi-column caption="Person ID"
                dataField="PersonId"
                dataType="string"
                cellTemplate="personCellTemplate">
    </dxi-column>

    <dxi-column caption="Data"
                dataField="Data"
                dataType="string">
    </dxi-column>

    <dxi-column caption="User Agent"
                dataField="UserAgent"
                dataType="string"
                [visible]="false">
    </dxi-column>

    <dxi-column caption="Organisation Confidence"
                dataField="OrgConfidence"
                dataType="string"
                [visible]="false">
    </dxi-column>

    <div *dxTemplate="let item of 'personCellTemplate'">
        <adapt-link-person [personId]="item.data.PersonId"></adapt-link-person>
    </div>

</dx-data-grid>
