<div adaptToolbarContent>
    <button adaptButtonIcon="add"
            [adaptBlockingClick]="addNewDeepDive">Add new Deep Dive</button>
</div>

<ng-container *adaptLoadingUntilFirstEmit="let deepDives of deepDives$">
    <dx-data-grid class="deep-dive-grid"
                  [dataSource]="deepDives"
                  [allowColumnReordering]="true"
                  [allowColumnResizing]="true"
                  [columnAutoWidth]="true"
                  [rowAlternationEnabled]="true">
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-paging [pageSize]="25"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true"
                   [allowedPageSizes]="[10, 25, 50, 100, 250]"></dxo-pager>

        <dxi-column dataField="deepDiveId"
                    caption="Id"
                    width="40"></dxi-column>
        <dxi-column dataField="type"
                    caption="Type"
                    width="80">
            <dxo-lookup [dataSource]="deepDiveTypeMapping"
                        displayExpr="name"
                        valueExpr="id"></dxo-lookup>
        </dxi-column>
        <dxi-column dataField="title"
                    caption="Title"></dxi-column>
        <dxi-column dataField="location"
                    cellTemplate="locationCellTemplate"
                    width="160"
                    caption="Location"></dxi-column>
        <dxi-column dataField="group"
                    caption="User Group">
            <dxo-lookup [dataSource]="deepDiveGroupMapping"
                        displayExpr="name"
                        valueExpr="id"></dxo-lookup>
        </dxi-column>
        <dxi-column dataField="lastUpdated"
                    caption="Last Updated"
                    cellTemplate="lastUpdatedCellTemplate"></dxi-column>
        <dxi-column caption="Preview"
                    cellTemplate="previewCellTemplate"
                    [allowSorting]="false"
                    [allowReordering]="false"
                    [allowFiltering]="false"
                    [allowResizing]="true"
                    [width]="450"></dxi-column>
        <dxi-column caption=""
                    dataType="string"
                    dataField="actions"
                    cellTemplate="actionCellTemplate"
                    [allowSorting]="false"
                    [allowReordering]="false"
                    [allowHiding]="false"
                    [allowFiltering]="false"
                    [allowResizing]="false"
                    [fixed]="true"
                    fixedPosition="right"
                    [width]="50">
        </dxi-column>

        <div *dxTemplate="let item of 'locationCellTemplate'"
             class="location-cell">
            <span *ngIf="!item.data.location">No content uploaded yet.</span>
            <a *ngIf="item.data.location"
               [href]="item.data.extensions.frameUrl"
               target="_blank">{{ item.data.extensions.displayUrl }}</a>
        </div>

        <div *dxTemplate="let item of 'lastUpdatedCellTemplate'">
            {{ item.data.lastUpdated | adaptDate }}
        </div>

        <div *dxTemplate="let item of 'previewCellTemplate'">
            <span *ngIf="!item.data.location">No content uploaded yet.</span>
            <adapt-deep-dive *ngIf="item.data.location"
                             [deepDive]="item.data"></adapt-deep-dive>
        </div>

        <div *dxTemplate="let item of 'actionCellTemplate'">
            <adapt-deep-dive-actions [deepDive]="item.data"></adapt-deep-dive-actions>
        </div>
    </dx-data-grid>
</ng-container>
