import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptEntityValidatorModule } from "@common/ux/entity-validator/entity-validator.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { DxCheckBoxModule, DxDataGridModule, DxDateBoxModule, DxRadioGroupModule, DxSelectBoxModule, DxTextBoxModule, DxValidationGroupModule, DxValidatorModule } from "devextreme-angular";
import { AdaptOrganisationsModule } from "../organisations/organisations.module";
import { OrganisationsService } from "../organisations/organisations.service";
import { AdaptOrganisationsSharedModule } from "../organisations/organisations-shared.module";
import { AdaptPartnerModule } from "../partners/partner.module";
import { AdaptPeopleModule } from "../people/people.module";
import { AdaptPeopleSharedModule } from "../people/people-shared.module";
import { AdaptToolsModule } from "../tools/tools.module";
import { CoachConnectionDialogComponent } from "./coach-connection-dialog/coach-connection-dialog.component";
import { CoachDetailsDialogComponent } from "./coach-details-dialog/coach-details-dialog.component";
import { CoachListPageComponent } from "./coach-list-page/coach-list-page.component";
import { displayCoachListRoute } from "./coach-list-page/coach-list-page-route";
import { CoachPageComponent } from "./coach-page/coach-page.component";
import { displayCoachRoute } from "./coach-page/coach-page-route";
import { AdaptCoachSharedModule } from "./coach-shared.module";
import { NewCoachConfirmationComponent } from "./new-coach/new-coach-confirmation/new-coach-confirmation.component";
import { NewCoachDetailsComponent } from "./new-coach/new-coach-details/new-coach-details.component";
import { NewCoachDialogComponent } from "./new-coach/new-coach-dialog/new-coach-dialog.component";
import { NewCoachPersonComponent } from "./new-coach/new-coach-person/new-coach-person.component";
import { NewCoachSandboxComponent } from "./new-coach/new-coach-sandbox/new-coach-sandbox.component";

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DxDataGridModule,
        DxDateBoxModule,
        DxSelectBoxModule,
        AdaptOrganisationsModule,
        AdaptShellModule,
        AdaptButtonModule,
        AdaptDateModule,
        AdaptPeopleModule,
        AdaptHtmlEditorModule,
        AdaptOrganisationsSharedModule,
        AdaptCommonDialogModule,
        AdaptCoachSharedModule,
        AdaptPartnerModule,
        AdaptCommonDialogModule,
        DxRadioGroupModule,
        DxTextBoxModule,
        DxValidatorModule,
        DxValidationGroupModule,
        DxCheckBoxModule,
        AdaptToolsModule,
        AdaptEntityValidatorModule,
        AdaptTooltipModule,
        AdaptPeopleSharedModule,
    ],
    exports: [
        CoachListPageComponent,
        CoachPageComponent,
    ],
    declarations: [
        CoachDetailsDialogComponent,
        CoachListPageComponent,
        CoachPageComponent,
        CoachConnectionDialogComponent,
        NewCoachDialogComponent,
        NewCoachPersonComponent,
        NewCoachDetailsComponent,
        NewCoachSandboxComponent,
        NewCoachConfirmationComponent,
    ],
    providers: [
        OrganisationsService,
    ],
})
export class AdaptCoachModule {
    public static readonly Routes = [
        ...displayCoachListRoute.routes,
        ...displayCoachRoute.routes,
    ];
}
