<div class="card card-info">
    <div class="card-header">
        <h4 class="card-title">Reset Password</h4>
    </div>

    <div class="card-body">
        <div class="form-group">
            <dx-text-box mode="password"
                         placeholder="New password"
                         [(value)]="password"></dx-text-box>
        </div>

        <div class="form-group">
            <dx-text-box mode="password"
                         placeholder="Confirm password"
                         [(value)]="confirmPassword"></dx-text-box>
        </div>

        <button class="btn btn-primary"
                [adaptBlockingClick]="resetPassword">Reset Password</button>
    </div>
</div>
