import { Injectable, Injector } from "@angular/core";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { DocumentationSubscriptionLevel } from "@common/ADAPT.Common.Model/person/documentation-subscription-level";
import { PersonPreferencesBreezeModel } from "@common/ADAPT.Common.Model/person/person-preferences";
import { Practitioner } from "@common/ADAPT.Common.Model/practitioner/practitioner";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseService } from "@common/service/base.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { EMPTY, lastValueFrom, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { CoachService } from "./coach.service";
import { CoachConnectionDialogComponent } from "./coach-connection-dialog/coach-connection-dialog.component";
import { CoachDetailsDialogComponent } from "./coach-details-dialog/coach-details-dialog.component";
import { NewCoachDialogComponent } from "./new-coach/new-coach-dialog/new-coach-dialog.component";

@Injectable({
    providedIn: "root",
})
export class CoachUiService extends BaseService {

    constructor(
        injector: Injector,
        private dialogService: AdaptCommonDialogService,
        private coachService: CoachService,
    ) {
        super(injector);
    }

    @Autobind
    public addNewCoachConnection(organisation: Organisation) {
        return this.coachService.createCoachConnection(organisation).pipe(
            switchMap((connection) => this.editCoachConnection(connection)),
        );
    }

    @Autobind
    public editCoachConnection(connection: Connection) {
        return this.dialogService.open(CoachConnectionDialogComponent, connection);
    }

    @Autobind
    public async editCoach(coach: Practitioner) {
        if (!coach.person.preferences) {
            await lastValueFrom(this.commonDataService.create(PersonPreferencesBreezeModel, {
                person: coach.person,
                documentationSubscriptionLevel: DocumentationSubscriptionLevel.Anonymous,
            }));
        }

        return lastValueFrom(this.dialogService.open(CoachDetailsDialogComponent, coach), { defaultValue: undefined });
    }

    @Autobind
    public addNewCoach() {
        return this.dialogService.open(NewCoachDialogComponent);
    }

    @Autobind
    public endCoachConnection(connection: Connection) {
        const dialogData = {
            title: "End Coach Connection",
            message: `Are you sure you would like to end ${connection.person.fullName}'s connection with ${connection.organisation.name}?`,
        };

        return this.dialogService.openConfirmationDialogWithBoolean(dialogData).pipe(
            switchMap((dialogResult) => dialogResult ? of(dialogResult) : EMPTY),
            switchMap(() => this.coachService.endCoachConnection(connection)),
            switchMap(() => this.dialogService.showMessageDialog("End Coach Connection", "The coach connection has been successfully ended.")),
        );
    }

    @Autobind
    public deleteCoachConnection(connection: Connection) {
        const dialogData = {
            title: "Delete Coach Connection",
            message: `Are you sure you would like to delete ${connection.person.fullName}'s connection with ${connection.organisation.name}?`,
        };

        return this.dialogService.openConfirmationDialogWithBoolean(dialogData).pipe(
            switchMap((dialogResult) => dialogResult ? of(dialogResult) : EMPTY),
            switchMap(() => this.coachService.deleteCoachConnection(connection)),
            switchMap(() => this.dialogService.showMessageDialog("Delete Coach Connection", "The coach connection has been successfully deleted.")),
        );
    }
}
