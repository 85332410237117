import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptEditorViewModule } from "@common/ux/html-editor/editor-view.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { RatingStarsModule } from "@common/ux/rating-stars/rating-stars.module";
import { FroalaEditorModule } from "angular-froala-wysiwyg";
import { AdaptPeopleSharedModule } from "app/features/people/people-shared.module";
import { AdaptToolsModule } from "app/features/tools/tools.module";
import { DxCheckBoxModule, DxDataGridModule, DxDateBoxModule, DxSelectBoxModule, DxTextBoxModule } from "devextreme-angular";
import { AdaptOrganisationsSharedModule } from "../features/organisations/organisations-shared.module";
import { AuthAuditComponent } from "./auth-audit/auth-audit.component";
import { AuthAuditPageComponent } from "./auth-audit-page/auth-audit-page.component";
import { authAuditPageRoute } from "./auth-audit-page/auth-audit-page.route";
import { DataAuditComponent } from "./data-audit/data-audit.component";
import { PathwayRatingAuditComponent, pathwayReviewPageRoute } from "./pathway-rating-audit/pathway-rating-audit.component";
import { PathwayRatingEditComponent } from "./pathway-rating-audit/pathway-review-edit/pathway-rating-edit.component";

@NgModule({
    declarations: [
        AuthAuditComponent,
        DataAuditComponent,
        AuthAuditPageComponent,
        PathwayRatingAuditComponent,
        PathwayRatingEditComponent,
    ],
    exports: [
        AuthAuditComponent,
        DataAuditComponent,
    ],
    imports: [
        CommonModule,
        AdaptButtonModule,
        DxCheckBoxModule,
        DxDataGridModule,
        DxDateBoxModule,
        DxTextBoxModule,
        AdaptToolsModule,
        AdaptPeopleSharedModule,
        AdaptOrganisationsSharedModule,
        RatingStarsModule,
        AdaptCommonDialogModule,
        FroalaEditorModule,
        AdaptEditorViewModule,
        AdaptHtmlEditorModule,
        DxSelectBoxModule,
    ],
})
export class AdaptAuditModule {
    public static readonly Routes = [
        ...authAuditPageRoute.routes,
        ...pathwayReviewPageRoute.routes,
    ];
}
