import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ContactType } from "@common/ADAPT.Common.Model/embed/contact-type";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { PersonContact } from "@common/ADAPT.Common.Model/person/person-contact";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { StakeholderServicesService } from "app/features/tools/stakeholder-services.service";
import { EMPTY } from "rxjs";

@Component({
    selector: "adapt-display-person-details",
    templateUrl: "./display-person-details.component.html",
})
export class DisplayPersonDetailsComponent implements OnChanges {
    public editMode = false;
    @Input() public person!: Person;
    public errorMessage?: string;
    public emails: PersonContact[] = [];
    public addresses: PersonContact[] = [];
    public phones: PersonContact[] = [];
    public logInEmailAddress = "";

    public constructor(private stakeholderServices: StakeholderServicesService) { }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.person.currentValue) {
            this.setLogInEmailAddress();
            this.phones = this.person.getContactsOfType(ContactType.Phone);
            this.emails = this.person.getContactsOfType(ContactType.Email);
            this.addresses = this.person.getContactsOfType(ContactType.Address);
        }
    }

    public editPerson() {
        this.editMode = true;
    }

    @Autobind
    public cancelPersonEdit() {
        this.errorMessage = undefined;
        this.editMode = false;
        this.setLogInEmailAddress();
        return EMPTY;
    }

    @Autobind
    public savePerson() {
        if (this.person.methodologyUser && this.person.methodologyUser.userName === this.logInEmailAddress) {
            this.editMode = false;
            return EMPTY;
        }

        this.errorMessage = undefined;

        const viewModel: any = {
            userId: this.person.userId,
            emailAddress: this.logInEmailAddress,
        };

        return this.stakeholderServices.updateLogInEmailAddress(viewModel)
            .then(() => alert("Refresh page to see changes"))
            .catch((e: any) => this.errorMessage = ErrorHandlingUtilities.getHttpResponseMessage(e));
    }

    private setLogInEmailAddress() {
        if (this.person && this.person.methodologyUser) {
            this.logInEmailAddress = this.person.methodologyUser.userName;
        }
    }
}
