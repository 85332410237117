import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptImplementationKitModule } from "@common/implementation-kit/implementation-kit.module";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptEntityValidatorModule } from "@common/ux/entity-validator/entity-validator.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptMenuModule } from "@common/ux/menu/menu.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptCommonWorkflowModule } from "@common/workflow/workflow.module";
import { DxDataGridModule, DxFileManagerModule, DxFileUploaderModule, DxNumberBoxModule, DxRadioGroupModule, DxSelectBoxModule, DxTextBoxModule, DxValidationGroupModule, DxValidatorModule } from "devextreme-angular";
import { DeepDiveActionsComponent } from "./deep-dive-actions/deep-dive-actions.component";
import { DeepDiveDetailsDialogComponent } from "./deep-dive-details-dialog/deep-dive-details-dialog.component";
import { DeepDiveListPageComponent } from "./deep-dive-list-page/deep-dive-list-page.component";
import { deepDiveListPageRoute } from "./deep-dive-list-page/deep-dive-list-page.route";
import { SelectDeepDiveImageDialogComponent } from "./select-deep-dive-image-dialog/select-deep-dive-image-dialog.component";
import { UploadDeepDiveDialogComponent } from "./upload-deep-dive-dialog/upload-deep-dive-dialog.component";


@NgModule({
    imports: [
        CommonModule,
        AdaptLoadingSpinnerModule,
        AdaptButtonModule,
        AdaptCommonDialogModule,
        AdaptDateModule,
        AdaptEntityValidatorModule,
        AdaptImplementationKitModule,
        AdaptMenuModule,
        AdaptShellModule,
        AdaptTooltipModule,
        AdaptCommonWorkflowModule,
        DxDataGridModule,
        DxTextBoxModule,
        DxValidatorModule,
        DxSelectBoxModule,
        DxNumberBoxModule,
        DxValidationGroupModule,
        DxFileUploaderModule,
        DxRadioGroupModule,
        DxFileManagerModule,
    ],
    declarations: [
        DeepDiveListPageComponent,
        DeepDiveDetailsDialogComponent,
        DeepDiveActionsComponent,
        UploadDeepDiveDialogComponent,
        SelectDeepDiveImageDialogComponent,
    ],
    exports: [
        DeepDiveListPageComponent,
        DeepDiveDetailsDialogComponent,
        DeepDiveActionsComponent,
    ],
})
export class AdaptDeepDiveModule {
    public static readonly Routes = [
        ...deepDiveListPageRoute.routes,
    ];
}
