import { IBreezeModel } from "@common/lib/data/breeze-model.interface";
import { BaseEntity } from "../base-entity";

export class Currency extends BaseEntity<Currency> {
    public currencyId!: number;
    public name!: string;
    public shortName!: string;
    public code!: string;
}

export const CurrencyBreezeModel: IBreezeModel<Currency> = {
    identifier: "currencies",
    singularName: "Currency",
    pluralName: "Currencies",
    toType: "Currency",
    source: "Currencies",
    idField: "CurrencyId",
    register: Currency,
};
