import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Partner } from "@common/ADAPT.Common.Model/partner/partner";
import { IDxSelectBoxSelectionChangedEvent } from "@common/ux/dx.types";
import { EMPTY, Observable } from "rxjs";
import { PartnerService } from "../partner.service";

@Component({
    selector: "adapt-select-partner",
    templateUrl: "./select-partner.component.html",
})
export class SelectPartnerComponent implements OnInit {
    @Input() public partner!: Partner;
    @Output() public partnerChange = new EventEmitter<Partner>();

    public partners: Observable<Partner[]> = EMPTY;

    constructor(
        private partnerService: PartnerService,
    ) {
    }

    public ngOnInit() {
        this.partners = this.partnerService.getAllPartners();
    }

    public onSelectionChanged(e: IDxSelectBoxSelectionChangedEvent<Partner>) {
        this.partnerChange.emit(e.selectedItem);
    }
}
