<div class="d-flex">
    <div class="form-group pe-3">
        <label for="inputStartDate">Filter start date</label>
        <dx-date-box id="inputStartDate"
                     style="width: 200px;"
                     name="inputStartDate"
                     [value]="startDate"
                     (valueChange)="startDate = $any($event)"></dx-date-box>
    </div>

    <div class="form-group pe-3">
        <label for="inputEndDate">Filter end date</label>
        <dx-date-box id="inputEndDate"
                     style="width: 200px;"
                     name="inputEndDate"
                     [value]="endDate"
                     (valueChange)="endDate = $any($event)"></dx-date-box>
    </div>
</div>

<div *ngIf="showUserFilter"
     class="form-group">
    <label for="selectPerson">Person</label>
    <adapt-select-person id="selectPerson"
                         [filter]="personFilter"
                         [(person)]="person"></adapt-select-person>
</div>

<div id="actionFilter"
     class="form-group">
    <label for="actionFilter">Action</label>
    <div class="d-flex">
        <div class="form-group pe-3">
            <dx-check-box id="filterActionLogin"
                          text="Login"
                          [value]="actionLogin"
                          (valueChange)="actionLogin = $any($event)"></dx-check-box>
        </div>

        <div class="form-group pe-3">
            <dx-check-box id="filterActionLogout"
                          text="Logout"
                          [value]="actionLogout"
                          (valueChange)="actionLogout = $any($event)"></dx-check-box>
        </div>
    </div>

</div>

<div id="resultFilter"
     class="form-group">
    <label for="resultFilter">Result</label>
    <div class="d-flex">
        <div class="form-group pe-3">
            <dx-check-box id="filterResultSuccess"
                          text="Success"
                          [value]="resultSuccess"
                          (valueChange)="resultSuccess = $any($event)"></dx-check-box>
        </div>

        <div class="form-group pe-3">
            <dx-check-box id="filterResultFailure"
                          text="Failure"
                          [value]="resultFailure"
                          (valueChange)="resultFailure = $any($event)"></dx-check-box>
        </div>
    </div>
</div>

<div id="reasonFilter"
     class="form-group">
    <label for="reasonFilter">Failure Reason</label>
    <div class="d-flex">
        <div class="form-group pe-3">
            <dx-check-box id="filterReasonUnknownUsr"
                          text="Unknown user"
                          [value]="reasonUnknownUsr"
                          (valueChange)="reasonUnknownUsr = $any($event)"></dx-check-box>
        </div>

        <div class="form-group pe-3">
            <dx-check-box id="filterReasonIncorrectPswd"
                          text="Incorrect password"
                          [value]="reasonIncorrectPswd"
                          (valueChange)="reasonIncorrectPswd = $any($event)"></dx-check-box>
        </div>

        <div class="form-group pe-3">
            <dx-check-box id="filterReasonOther"
                          text="Other"
                          [value]="reasonOther"
                          (valueChange)="reasonOther = $any($event)"></dx-check-box>
        </div>
    </div>
</div>

<div class="form-group">
    <button adaptButtonIcon="fal fa-fw fa-sign-in"
            class="btn btn-primary"
            data-test="submit-btn"
            type="submit"
            [adaptBlockingClick]="getAuthResults">FETCH!
    </button>
</div>

<dx-data-grid [dataSource]="authList"
              [allowColumnReordering]="true"
              [allowColumnResizing]="true"
              [columnAutoWidth]="true"
              [rowAlternationEnabled]="true"
              [showRowLines]="true"
              [showBorders]="true">
    <!-- Options -->
    <dxo-header-filter [visible]="true">
    </dxo-header-filter>
    <dxo-filter-row [visible]="true">
    </dxo-filter-row>
    <dxo-column-chooser [enabled]="false">
    </dxo-column-chooser>
    <dxo-pager [showPageSizeSelector]="true"
               [allowedPageSizes]="[10, 25, 50, 100, 250]">
    </dxo-pager>

    <!-- Columns -->
    <dxi-column caption="User Email"
                dataField="partitionKey"
                dataType="string">
    </dxi-column>

    <dxi-column caption="Timestamp"
                dataField="rowKey"
                dataType="string"
                sortOrder="desc"
                [sortIndex]="0">
    </dxi-column>

    <dxi-column caption="Action"
                dataField="Action"
                dataType="string">
    </dxi-column>

    <dxi-column caption="Result"
                dataField="Result"
                dataType="string">
    </dxi-column>

    <dxi-column caption="Failure Reason"
                dataField="Reason"
                dataType="string">
    </dxi-column>

    <dxi-column caption="Origin"
                dataField="Origin"
                dataType="string">
    </dxi-column>

</dx-data-grid>
