import { Component, Injector, OnInit, ViewChild } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { catchError, filter, switchMap } from "rxjs/operators";
import { SelectOrganisationComponent } from "../select-organisation/select-organisation.component";
import { StakeholderServicesService } from "../stakeholder-services.service";

@Component({
    selector: "adapt-delete-organistion-page",
    templateUrl: "./delete-organisation-page.component.html",
})
export class DeleteOrganisationPageComponent extends BaseRoutedComponent implements OnInit {
    public organisation?: Organisation;
    public isMyMathsOk = false;
    public error?: string;

    @ViewChild(SelectOrganisationComponent)
    public selectOrganisationComponent?: SelectOrganisationComponent;

    public constructor(
        private commonDataService: CommonDataService,
        private stakeholderServicesService: StakeholderServicesService,
        private commonDialogService: AdaptCommonDialogService,
        injector: Injector,
    ) {
        super(injector);
    }

    public ngOnInit() {
        this.notifyActivated();
    }

    @Autobind
    public deleteOrg() {
        if (!this.organisation) {
            return this.commonDialogService.showMessageDialog("Error", "Select Organisation!!!!");
        }

        if (!this.isMyMathsOk) {
            return this.commonDialogService.showMessageDialog("Error", "Check your maths!");
        }

        const viewModel = {
            organisationId: this.organisation.organisationId,
        };

        const dialogData: IConfirmationDialogData = {
            title: "Delete Organisation",
            message: "Are you sure you want to delete this organisation?",
            confirmButtonText: "Delete",
        };

        return this.commonDialogService.openConfirmationDialogWithBoolean(dialogData).pipe(
            filter((dialogResult) => !!dialogResult),
            switchMap(() => this.stakeholderServicesService.deleteOrganisation(viewModel)),
            switchMap(() => this.notify()),
            catchError((e) => this.error = ErrorHandlingUtilities.getHttpResponseMessage(e)),
        );
    }

    private notify() {
        // org has been deleted at this point, remove from breeze
        this.commonDataService.detachEntityFromBreeze(this.organisation!);

        this.error = undefined;
        this.organisation = undefined;

        this.selectOrganisationComponent!.reload();

        return this.commonDialogService.showMessageDialog("Success!", "Organisation was deleted!");
    }
}
