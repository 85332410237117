<div adaptToolbarContent>
    <span class="dx-datagrid-export-button">
        <button adaptButtonIcon="dx-icon dx-icon-xlsxfile"
                adaptTooltip="Export all data"
                (click)="exportAllData()"></button>
    </span>
    <button adaptButtonIcon="dx-icon dx-icon-column-chooser"
            adaptTooltip="Column chooser"
            (click)="showColumnChooser()"></button>
    <button adaptButtonIcon="dx-icon dx-icon-revert"
            adaptTooltip="Reset columns"
            (click)="resetColumns()"></button>
</div>

<dx-data-grid [dataSource]="people"
              [stateStoring]="dxGridWrapperHelper.stateStoringOptions"
              [allowColumnReordering]="true"
              [allowColumnResizing]="true"
              [columnAutoWidth]="true"
              [rowAlternationEnabled]="true"
              [showRowLines]="true"
              [showBorders]="true"
              (onInitialized)="onGridInitialized($event)">
    <!-- Options -->
    <dxo-header-filter [visible]="true">
    </dxo-header-filter>
    <dxo-filter-row [visible]="true">
    </dxo-filter-row>
    <dxo-column-chooser [enabled]="false">
    </dxo-column-chooser>
    <dxo-pager [showPageSizeSelector]="true"
               [allowedPageSizes]="[10, 25, 50, 100, 250]">
    </dxo-pager>

    <!-- Columns -->
    <dxi-column caption="Id"
                dataField="personId"
                dataType="number">
    </dxi-column>

    <dxi-column caption="Full Name"
                dataField="fullName"
                dataType="string"
                cellTemplate="nameCellTemplate">
    </dxi-column>

    <dxi-column caption="Active Organisations"
                [calculateCellValue]="calculateOrganisationColumnValue"
                cellTemplate="organisationCellTemplate"
                dataType="string"
                dataField="dummy_organisations"
                [allowFiltering]="true"
                [visible]="false">
    </dxi-column>

    <dxi-column *ngFor="let contactTypeMetadata of AllContactTypeMetadata"
                [caption]="contactTypeMetadata.name"
                cellTemplate="contactDetailCellTemplate"
                [allowFiltering]="true"
                [allowHeaderFiltering]="false"
                [allowSorting]="false"
                dataType="string"
                [dataField]="contactTypeMetadata.name"
                [visible]="contactTypeMetadata.type !== ContactType.Address && contactTypeMetadata.type !== ContactType.Messaging">
    </dxi-column>

    <dxi-column *ngIf="filterForOrganisationId"
                caption="User Type"
                [calculateCellValue]="calculateUserTypeColumnValue"
                dataField="dummy_userType"
                dataType="string"
                [visible]="true">
    </dxi-column>

    <dxi-column *ngIf="filterForOrganisationId"
                caption="Has Platform Access"
                [calculateCellValue]="calculateHasPlatformAccessColumnValue"
                dataField="dummy_hasAccess"
                dataType="boolean"
                [visible]="true">
    </dxi-column>

    <dxi-column *ngIf="filterForOrganisationId"
                caption="Last Access"
                dataField="LastAccess"
                dataType="date"
                [format]="DateFormats.globalize.short">
    </dxi-column>

    <dxi-column caption="Status"
                [calculateCellValue]="calculateActiveCellValue"
                dataType="boolean"
                dataField="dummy_status"
                trueText="Active"
                falseText="Inactive"
                [allowSorting]="true"
                [showEditorAlways]="false"
                [filterValue]="true"
                [allowFiltering]="true"
                [visible]="true">
    </dxi-column>

    <dxi-column *ngIf="filterForOrganisationId"
                caption="Connection Type"
                [calculateCellValue]="calculateConnectionTypeColumnValue"
                dataField="dummy_connectionType"
                dataType="string"
                [visible]="false">
    </dxi-column>

    <!-- Templates -->
    <div *dxTemplate="let item of 'nameCellTemplate'">
        <adapt-link-person [person]="item.data"></adapt-link-person>
    </div>

    <div *dxTemplate="let item of 'contactDetailCellTemplate'">
        <ul>
            <li *ngFor="let detail of getContactsOfTypeByName(item.data, item.column.caption)">
                <adapt-contact-detail [detail]="detail"></adapt-contact-detail>
            </li>
        </ul>
    </div>

    <div *dxTemplate="let item of 'organisationCellTemplate'">
        <ul>
            <li *ngFor="let organisation of getUniqueActiveOrganisationsFromConnections(item.data.connections)">
                <adapt-link-organisation [organisation]="organisation"></adapt-link-organisation>
            </li>
        </ul>
    </div>
</dx-data-grid>
