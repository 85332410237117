import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { ValueStream, ValueStreamBreezeModel } from "@common/ADAPT.Common.Model/organisation/value-stream";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { NimbusDataService } from "app/services/nimbus-data.service";
import { lastValueFrom } from "rxjs";

interface ISelectValueStreamChanges extends SimpleChanges {
    organisation: SimpleChange;
    valueStream: SimpleChange;
}

@Component({
    selector: "adapt-select-value-stream",
    templateUrl: "./select-value-stream.component.html",
})
export class SelectValueStreamComponent implements OnInit, OnChanges {
    @Input() public organisation?: Organisation;
    @Input() public valueStream?: ValueStream;
    @Output() public valueStreamChange = new EventEmitter<ValueStream>();

    public valueStreamId?: number;

    public valueStreams?: ValueStream[];

    public constructor(
        private commonDataService: CommonDataService,
        private nimbusDataService: NimbusDataService,
    ) { }

    public ngOnInit() {
        this.fetchValueStreams();
    }

    public ngOnChanges(changes: ISelectValueStreamChanges) {
        if (changes.valueStream) {
            this.updateValueStreamId(changes.valueStream.currentValue);
        }

        if (changes.organisation) {
            this.onOrganisationChanged(changes.organisation.currentValue);
        }
    }

    public async onValueStreamIdChanged(value: number) {
        this.valueStreamId = value;
        this.valueStream = this.valueStreamId
            ? await lastValueFrom(this.commonDataService.getById(ValueStreamBreezeModel, this.valueStreamId))
            : undefined;
        this.valueStreamChange.emit(this.valueStream);
    }

    private updateValueStreamId(valueStream?: ValueStream) {
        this.valueStreamId = valueStream?.valueStreamId;
    }

    private onOrganisationChanged(newValue: Organisation) {
        if (this.valueStream && newValue
            && this.valueStream.organisationId !== newValue.organisationId) {
            this.valueStreamId = undefined;
        }

        this.fetchValueStreams();
    }

    private async fetchValueStreams() {
        this.valueStreams = await lastValueFrom(this.organisation
            ? this.nimbusDataService.getValueStreamsByOrganisation(this.organisation)
            : this.commonDataService.getAll(ValueStreamBreezeModel));
    }
}
