<dx-select-box [dataSource]="dataSource"
               (onValueChanged)="onSurveyChanged($event)"
               [(value)]="survey"
               displayExpr="name"
               [grouped]="true">
    <div *dxTemplate="let item of 'item'">
        <div class="d-flex justify-content-between">
            <div><span *ngIf="item.team">{{item.team.name }} -</span> {{item.name}}</div>
            <small>{{item.startTime | adaptDateTime}}</small>
        </div>
    </div>
</dx-select-box>
