import { Component, Injector, OnInit, ViewEncapsulation } from "@angular/core";
import { DeepDive, DeepDiveGroupMapping, DeepDiveTypeMapping } from "@common/ADAPT.Common.Model/embed/deep-dive";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { DeepDiveService } from "@common/workflow/deep-dive.service";
import { EMPTY, Observable } from "rxjs";
import { debounceTime, startWith, switchMap } from "rxjs/operators";
import { DeepDiveUiService } from "../deep-dive-ui.service";

@Component({
    selector: "adapt-deep-dive-list-page",
    templateUrl: "./deep-dive-list-page.component.html",
    styleUrls: ["./deep-dive-list-page.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class DeepDiveListPageComponent extends BaseRoutedComponent implements OnInit {
    public deepDives$: Observable<DeepDive[]> = EMPTY;
    public deepDiveGroupMapping = DeepDiveGroupMapping;
    public deepDiveTypeMapping = DeepDiveTypeMapping;

    constructor(
        injector: Injector,
        private deepDiveService: DeepDiveService,
        private deepDiveUiService: DeepDiveUiService,
        private rxjsBreezeService: RxjsBreezeService,
    ) {
        super(injector);
    }

    public ngOnInit() {
        this.deepDives$ = this.emitWhenDeepDivesShouldBeFetched();
        this.notifyActivated();
    }

    @Autobind
    public addNewDeepDive() {
        return this.deepDiveUiService.addNewDeepDive();
    }

    @Autobind
    private emitWhenDeepDivesShouldBeFetched() {
        return this.rxjsBreezeService.entityTypeChanged(DeepDive).pipe(
            startWith(undefined),
            debounceTime(100),
            switchMap(() => this.deepDiveService.getAllDeepDives()),
        );
    }
}
