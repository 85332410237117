<div>
    <div role="alert"
         class="alert alert-info">
        Orphan Users are those accounts that aren't attached to any people records (mistakenly added or not cleaned up properly)
    </div>

    <button class="btn btn-default"
            [adaptBlockingClick]="doCheck">Check for Orphan Users</button>

    <hr />

    <div *ngIf="users">
        <table class="table table-bordered table-striped table-hover table-responsive">
            <thead>
                <tr>
                    <td>UserId</td>
                    <td>Email</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let user of users">
                    <td>{{user.Id}}</td>
                    <td>{{user.Value}}</td>
                </tr>
            </tbody>
        </table>

        <hr />

        <div role="alert"
             *ngIf="users.length"
             class="alert alert-warning">
            <h3>*** Note: This operation is not recoverable. ***</h3>
        </div>

        <button *ngIf="users.length"
                [adaptBlockingClick]="doDelete"
                adaptButton="delete"> Orphan Users</button>

    </div>

    <div role="alert"
         class="alert alert-danger"
         *ngIf="error">{{error}}</div>
</div>
