import { Injectable, Injector } from "@angular/core";
import { DeepDive, DeepDiveType } from "@common/ADAPT.Common.Model/embed/deep-dive";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { BaseService } from "@common/service/base.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { DeepDiveService } from "@common/workflow/deep-dive.service";
import { of, timer } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { DeepDiveDetailsDialogComponent } from "./deep-dive-details-dialog/deep-dive-details-dialog.component";
import { SelectDeepDiveImageDialogComponent } from "./select-deep-dive-image-dialog/select-deep-dive-image-dialog.component";
import { IUploadDeepDiveDialogData, UploadDeepDiveDialogComponent } from "./upload-deep-dive-dialog/upload-deep-dive-dialog.component";

@Injectable({
    providedIn: "root",
})
export class DeepDiveUiService extends BaseService {
    constructor(
        injector: Injector,
        private dialogService: AdaptCommonDialogService,
        private deepDiveService: DeepDiveService,
    ) {
        super(injector);
    }

    @Autobind
    public addNewDeepDive() {
        return this.deepDiveService.createDeepDive().pipe(
            switchMap((deepDive) => this.editDeepDive(deepDive)),
            // hack to get dialog z-index to reset.
            // if we don't do this, the confirm upload cancel dialog will be below the upload dialog.
            switchMap((deepDive) => timer(0).pipe(map(() => deepDive))),
            switchMap((deepDive) => deepDive.type === DeepDiveType.Scorm
                ? this.uploadDeepDiveContent(deepDive, true)
                : of(deepDive)),
        );
    }

    @Autobind
    public editDeepDive(deepDive: DeepDive) {
        return this.dialogService.open(DeepDiveDetailsDialogComponent, deepDive);
    }

    @Autobind
    public uploadDeepDiveContent(deepDive: DeepDive, isNew: boolean) {
        return this.dialogService.open(UploadDeepDiveDialogComponent, { deepDive, isNew } as IUploadDeepDiveDialogData);
    }

    @Autobind
    public selectDeepDiveImage(deepDive: DeepDive) {
        return this.dialogService.open(SelectDeepDiveImageDialogComponent, deepDive);
    }

    @Autobind
    public deleteDeepDive(deepDive: DeepDive) {
        const storageWarning = deepDive.type === DeepDiveType.Scorm
            ? "The content saved within Azure will also be deleted if it exists."
            : "";

        return this.dialogService.openConfirmationDialog({
            title: "Delete Deep Dive",
            message: `Are you sure you wish to delete the <b>${deepDive.title}</b> deep dive? ${storageWarning}`,
            checkboxMessage: "Confirm that you really want to delete this deep dive",
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
        }).pipe(
            switchMap(() => this.deepDiveService.deleteDeepDive(deepDive)),
            catchError((err) => this.dialogService.showErrorDialog(
                "Unable to delete deep dive",
                `<p>The following error has been thrown from the server while attempting to delete the deep dive:</p>
                 <blockquote>${ErrorHandlingUtilities.getHttpResponseMessage(err)}</blockquote>`,
            )),
        );
    }
}
