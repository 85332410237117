<div>
    <div role="alert"
         class="alert alert-info">
        Rebuild our test organisations that are used within embedADAPT (Los Pollos, Vamonos).
        <br /><br />
        This process will take around 5 minutes - it will likely timeout, so wait for the seed complete email to be sure.
    </div>

    <form>
        <button adaptButtonIcon="refresh"
                [adaptBlockingClick]="startDatabaseSeed">Start Test Organisations Seed</button>

        <div role="alert"
             class="alert alert-danger"
             *ngIf="error">{{error}}</div>
    </form>
</div>
