import { Component, Injector, OnInit } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { IPersonAspNetRole, StakeholderServicesService } from "../stakeholder-services.service";

@Component({
    selector: "adapt-list-privileged-users",
    templateUrl: "./list-privileged-users-page.component.html",
})
export class ListPrivilegedUsersPageComponent extends BaseRoutedComponent implements OnInit {
    public personRoles?: IPersonAspNetRole[];

    public constructor(private stakeholderServices: StakeholderServicesService, injector: Injector) {
        super(injector);
    }

    public ngOnInit() {
        this.notifyActivated();
    }

    @Autobind
    public async fetch() {
        this.personRoles = await this.stakeholderServices.getPrivilegedUserRoles();
        this.personRoles = this.personRoles.sort((a, b) => a.PersonId - b.PersonId ? 1 : -1);
    }
}
