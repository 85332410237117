<div>
    <div adaptBlockingButtonGroup>
        <button [adaptBlockingClick]="doCheck"
                adaptButton="default">Get cache status</button>
        <button [adaptBlockingClick]="doClear"
                adaptButton="default">Clear cache</button>
    </div>

    <div *ngFor="let cache of cacheStatuses">
        <hr />

        <h3>{{cache.Name}}</h3>
        <table class="table table-bordered table-striped table-hover table-responsive">
            <thead>
                <tr>
                    <td style="width:60%">Key</td>
                    <td>Value</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let status of cache.Values">
                    <td>{{status.Id}}</td>
                    <td>{{status.Value}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div role="alert"
         class="alert alert-danger"
         *ngIf="error">{{error}}</div>
</div>
