import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptPaymentProcessingModule } from "@common/payment-processing/payment-processing.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { DxDataGridModule, DxTemplateModule } from "devextreme-angular";
import { DxiColumnModule, DxoFilterRowModule, DxoHeaderFilterModule, DxoPagerModule, DxoPagingModule } from "devextreme-angular/ui/nested";
import { LinkOrganisationComponent } from "./link-organisation/link-organisation.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AdaptDateModule,
        AdaptLoadingSpinnerModule,
        AdaptPaymentProcessingModule,
        AdaptButtonModule,
        DxDataGridModule,
        DxTemplateModule,
        DxiColumnModule,
        DxoFilterRowModule,
        DxoHeaderFilterModule,
        DxoPagerModule,
        DxoPagingModule,
    ],
    exports: [
        LinkOrganisationComponent,
    ],
    declarations: [
        LinkOrganisationComponent,
    ],
})
export class AdaptOrganisationsSharedModule {
}
