<div class="move-board-component">
    <div role="alert"
         class="alert alert-info">
        Move a board from one team to another.
    </div>

    <form role="form">
        <div class="row">
            <div class="col-12 col-lg-8">
                <div class="form-group">
                    <h4>Organisation</h4>
                    <adapt-select-organisation (organisationChange)="organisation = $event"></adapt-select-organisation>
                </div>

                <div *ngIf="organisation">
                    <div class="form-group">
                        <h4>Source Team</h4>
                        <adapt-select-team [organisation]="organisation"
                                           (teamChange)="onSourceTeamChanged($event)"></adapt-select-team>
                    </div>

                    <div *ngIf="sourceTeam">
                        <div class="form-group">
                            <h4>Board</h4>
                            <adapt-select-board [team]="sourceTeam"
                                                (boardChange)="onBoardSelectionChanged($event)"></adapt-select-board>
                        </div>

                        <div class="form-group"
                             *ngIf="!loading && affectedItems.length > 0">
                            <h4>Impact Statement</h4>
                            <div class="impact-statement">
                                <span>This board contains {{affectedItems.length}} items</span>
                            </div>
                        </div>

                        <div *ngIf="board"
                             class="form-group">
                            <h4>Destination Team</h4>
                            <adapt-select-team [organisation]="organisation"
                                               (teamChange)="onDestTeamChanged($event)"
                                               [filter]="excludeSourceTeam"></adapt-select-team>
                        </div>
                    </div>
                </div>
                <div *ngIf="hasAllRequiredFields()"
                     class="form-group">
                    <adapt-check-my-maths (isMyMathsOk)="isMyMathsOk=$event">
                    </adapt-check-my-maths>
                </div>
            </div>
        </div>

        <button [adaptBlockingClick]="promiseToMoveBoard"
                [disabled]="!isMyMathsOk || !isComplete"
                adaptButton="move"> Board</button>

        <div *ngIf="error"
             role="alert"
             class="alert alert-danger">{{error}}</div>
    </form>
</div>
