<dx-select-box [value]="coach"
               (valueChange)="itemChange($event)"
               [items]="items"
               [showClearButton]="!required"
               displayExpr="person.fullName"
               [searchEnabled]="true"
               [searchExpr]="$any('person.fullName')"
               placeholder="Select Coach...">
    <dx-validator [validationGroup]="validationGroup">
        <dxi-validation-rule *ngIf="required"
                             type="required"
                             message="Coach is required"></dxi-validation-rule>
    </dx-validator>
</dx-select-box>
