import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Feature } from "@common/ADAPT.Common.Model/embed/feature";
import { FeatureStatus } from "@common/ADAPT.Common.Model/organisation/feature-status";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { concat, from, Observable, of } from "rxjs";
import { filter, map, mergeMap, toArray } from "rxjs/operators";
import { FeatureService } from "../feature.service";

@Component({
    selector: "adapt-edit-organisation-features",
    templateUrl: "./edit-organisation-features.component.html",
})
export class EditOrganisationFeaturesComponent implements OnChanges {
    @Input() public organisation?: Organisation;

    public allFeatures$: Observable<Feature[]>;
    public initiallyEnabledFeatures: Feature[] = [];
    public initialOrganisationFeatureStatuses: FeatureStatus[] = [];
    private addedFeatures = new Set<Feature>();
    private removedFeatures = new Set<Feature>();

    public constructor(
        private featureService: FeatureService,
    ) {
        this.allFeatures$ = this.featureService.getOrganisationalFeatures();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.organisation) {
            this.intialiseState();
        }
    }

    public intialiseState() {
        const featureStatuses$ = this.organisation
            ? this.featureService.getFeatureStatusesForOrganisation(this.organisation)
            : of([]);

        featureStatuses$.subscribe((statuses) => {
            this.initialOrganisationFeatureStatuses = statuses;
            this.initiallyEnabledFeatures = statuses.map((s) => s.feature);
        });
    }

    public addFeatureToOrganisation(feature: Feature) {
        this.removedFeatures.delete(feature);
        this.addedFeatures.add(feature);
    }

    public removeFeatureFromOrganisation(feature: Feature) {
        this.addedFeatures.delete(feature);
        this.removedFeatures.add(feature);
    }

    public getEntitiesToSave() {
        const featureStatusesToAdd$ = from(this.addedFeatures).pipe(
            filter((feature) => this.initiallyEnabledFeatures.indexOf(feature) < 0),
            mergeMap((feature) => {
                return this.featureService.addFeatureStatus(feature.name, this.assertedOrganisation);
            }),
        );

        const definedFeatureStatusFilter = ObjectUtilities.createIsInstanceFilter(FeatureStatus);
        const removedFeatureStatuses$ = from(this.removedFeatures).pipe(
            map((f) => {
                return this.initialOrganisationFeatureStatuses.find((fs) => fs.feature === f);
            }),
            filter(definedFeatureStatusFilter),
            mergeMap((featureStatus) => {
                return this.featureService.removeFeatureStatus(featureStatus);
            }),
            filter(definedFeatureStatusFilter),
        );

        return concat(featureStatusesToAdd$, removedFeatureStatuses$).pipe(
            toArray(),
        );
    }

    private get assertedOrganisation() {
        if (!this.organisation) {
            throw new Error("Organisation should be defined");
        }
        return this.organisation;
    }
}
