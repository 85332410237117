import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Practitioner } from "@common/ADAPT.Common.Model/practitioner/practitioner";
import { EMPTY, Observable } from "rxjs";
import { displayCoachRoute } from "../coach-page/coach-page-route";

@Component({
    selector: "adapt-link-coach",
    templateUrl: "./link-coach.component.html",
})
export class LinkCoachComponent implements OnChanges {
    @Input() public coach?: Practitioner;
    public href$: Observable<string> = EMPTY;

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.coach && this.coach) {
            this.href$ = displayCoachRoute.getRoute({
                coachId: this.coach!.practitionerId,
            });
        }
    }
}
