import { IUserDetailBindingModel } from "@common/identity/user-detail-binding-model.interface";

export interface INewPersonModel extends IUserDetailBindingModel {
    valid: boolean
}

export enum PersonAction {
    Existing,
    CreateNew
}
