<!-- TODO Make this reusable between features and feature permissions so that
    configure-role-access will be able to use it when it is converted to Angular -->
<dx-accordion class="select-features-accordion"
              [dataSource]="accordionData$ | async"
              itemTitleTemplate="itemTitleTemplate"
              itemTemplate="itemTemplate"
              [collapsible]="true"
              [deferRendering]="false">
    <div *dxTemplate="let item of 'itemTitleTemplate'"
         class="module-template">
        <div class="title-content">
            {{translatorService.translateFeatureModule(item.module)}}
            <div class="count-summary">
                ({{item.enabledFeatureCount + '/' + item.totalFeatureCount}} features)
            </div>
        </div>
    </div>
    <div *dxTemplate="let item of 'itemTemplate'"
         class="module-body">
        <!-- TODO Human readable feature names -->
        <dx-check-box *ngFor="let featureRow of item.featureRows"
                      [text]="featureRow.feature.name"
                      [value]="featureRow.isEnabled"
                      (valueChange)="featureStatusChanged(item, featureRow.feature, $any($event))"></dx-check-box>
    </div>
</dx-accordion>
