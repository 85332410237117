<h3>Person</h3>
<p *ngIf="person">Adding existing person <strong>{{person.fullName}}</strong> as a coach.</p>
<div *ngIf="temporaryPerson">
    Adding a new person as a coach.
    <ng-container *ngIf="!createdOrganisation && !joinOrganisation">
        There are no organisations selected for this coach so <b>the welcome email will not be sent.</b>
        They will not be able to login until they are invited to an organisation.
    </ng-container>

    <div class="row my-1">
        <div class="col-12 col-md-2">First Name:</div>
        <div class="col-12 col-md-10">{{(person || temporaryPerson)!.firstName}}</div>
    </div>
    <div class="row mb-1">
        <div class="col-12 col-md-2">Surname:</div>
        <div class="col-12 col-md-10">{{(person || temporaryPerson)!.lastName}}</div>
    </div>
    <div *ngIf="temporaryPerson"
         class="row mb-1">
        <div class="col-12 col-md-2">Email:</div>
        <div class="col-12 col-md-10">{{temporaryPerson.email}}</div>
    </div>
</div>

<h3 class="mt-3">Coach Details</h3>
<div class="row mb-1">
    <div class="col-12 col-md-2">Start Date:</div>
    <div class="col-12 col-md-10">{{practitioner.startDate | adaptDate }}</div>
</div>
<div *ngIf="practitioner.endDate"
     class="row mb-1">
    <div class="col-12 col-md-2">End Date:</div>
    <div class="col-12 col-md-10">{{practitioner.endDate | adaptDate }}</div>
</div>
<div *ngIf="practitioner.notes"
     class="row mb-1">
    <div class="col-12 col-md-2">Notes:</div>
    <div class="col-12 col-md-10"
         [froalaView]="practitioner.notes"></div>
</div>
<div *ngIf="practitioner.partner"
     class="row mb-1">
    <div class="col-12 col-md-2">Strategic Partner:</div>
    <div class="col-12 col-md-10">{{practitioner.partner.name}}</div>
</div>

<h3 class="mt-3">Sandbox Organisations</h3>
<div *ngIf="!joinOrganisation && !createdOrganisation">
    No organisations selected for the new coach.
    <b *ngIf="temporaryPerson">Welcome email will not be sent.</b>
</div>
<div *ngIf="createdOrganisation">Creating new sandbox organisation <b>{{createdOrganisation.name}}</b>,
    and joining as an <b>Employee</b> with the following roles: <b>Organisation Leader</b>, <b>Adapt Administrator</b>.
</div>
<div *ngIf="joinOrganisation">Joining existing sandbox organisation <b>{{joinOrganisation.name}}</b> as a <b>Coach</b>.</div>
