import { Injectable } from "@angular/core";
import { NavigationTab } from "@common/shell/common-sidebar/navigation-tab";
import { IComponentRender } from "@common/ux/render-component/component-render.interface";
import { of } from "rxjs";
import { NimbusNavigationService } from "./nimbus-navigation.service";
import { NimbusSidebarContentComponent } from "./nimbus-sidebar-content/nimbus-sidebar-content.component";

@Injectable()
export class NimbusSidebar extends NavigationTab {
    public id = "nimbus-sidebar";
    public label = "runADAPT Sidebar";
    public ordinal = 0;
    public content: IComponentRender<NimbusSidebarContentComponent> = {
        component: NimbusSidebarContentComponent,
    };

    // If there's only one tab, the sidebar won't show the icon, so just put a placeholder
    public icon: IComponentRender<NimbusSidebarContentComponent> = {
        component: NimbusSidebarContentComponent,
    };

    protected recheckForFocusedTab$ = of(undefined);

    protected getHierarchyId() {
        return NimbusNavigationService.HierarchyId;
    }
}
