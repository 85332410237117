import { Component, ElementRef, Injector, OnInit } from "@angular/core";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { DocumentationSubscriptionLevelLabel } from "@common/ADAPT.Common.Model/person/documentation-subscription-level";
import { CoachTypeMetadata } from "@common/ADAPT.Common.Model/practitioner/coach-type";
import { Practitioner } from "@common/ADAPT.Common.Model/practitioner/practitioner";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ActiveEntityUtilities } from "@common/lib/data/active-entity-utilities";
import { DxUtilities } from "@common/lib/utilities/dx-utilities";
import { DxGridWrapperHelper } from "@common/ux/base.component/dx-component-wrapper-builder";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { DateFormats } from "@common/ux/date-formats";
import { OrganisationsService } from "app/features/organisations/organisations.service";
import { PartnerService } from "app/features/partners/partner.service";
import { ColumnHeaderFilter, InitializedEvent } from "devextreme/ui/data_grid";
import { tap } from "rxjs/operators";
import { CoachService } from "../coach.service";
import { CoachUiService } from "../coach-ui.service";

interface ICoachListData {
    coach: Practitioner;
    connections: Connection[];
}
// duplicated from people-grid.component.ts
interface IGridHeaderItem {
    text: string;
    value: any[];
}
@Component({
    selector: "adapt-coach-list-page",
    templateUrl: "./coach-list-page.component.html",
})
export class CoachListPageComponent extends BaseRoutedComponent implements OnInit {
    public static readonly GridName = "adaptCoachGrid";

    public coaches: ICoachListData[] = [];
    public dateFormat = DateFormats.globalize.short;

    public gridHelper: DxGridWrapperHelper;

    constructor(
        injector: Injector,
        elementRef: ElementRef<HTMLElement>,
        private coachService: CoachService,
        private coachUiService: CoachUiService,
        private partnerService: PartnerService,
        private organisationService: OrganisationsService,
    ) {
        super(injector);

        this.gridHelper = new DxGridWrapperHelper(CoachListPageComponent.GridName, jQuery(elementRef.nativeElement));
    }

    public ngOnInit() {
        this.gridHelper.saveGridState(CoachListPageComponent.GridName);
        // setColumnsReady first before loading so that the grid at least shows up without data and show a loading panel instead of blank panel
        this.gridHelper.callGrid((grid) => grid.setColumnsReady());

        // prime the organisations and partners, no need to wait for it to finish
        this.organisationService.getOrganisations()
            .subscribe();
        this.partnerService.getAllPartners()
            .subscribe();
        this.updateData();

        this.notifyActivated();
    }

    @Autobind
    public addNewCoach() {
        return this.coachUiService.addNewCoach().pipe(
            tap(() => this.updateData()),
        );
    }

    public calculateCoachNameCellValue(data: ICoachListData) {
        return data.coach.person.fullName;
    }

    public calculateOrganisationsCellValue(data: ICoachListData) {
        return data.connections
            .map(getOrganisationName)
            .join(",");

        function getOrganisationName(connection: Connection) {
            return connection.organisation !== null
                ? connection.organisation.name
                : "";
        }
    }

    public calculateActiveCellValue(data: ICoachListData) {
        return ActiveEntityUtilities.isActive(data.coach);
    }

    public calculatePartnerCellValue(data: ICoachListData) {
        return data.coach.partner
            ? data.coach.partner.name
            : undefined;
    }

    public calculateSubscriptionLevelCellValue(data: ICoachListData) {
        return data.coach.person.preferences
            ? DocumentationSubscriptionLevelLabel.label(data.coach.person.preferences.documentationSubscriptionLevel)
            : "";
    }

    private updateData() {
        return this.coachService.getAllCoaches()
            .subscribe((coaches) => {
                this.coaches = coaches.map((coach) => ({
                    coach,
                    connections: coach.person.connections.filter((connection) => connection.isCoachConnection() && connection.isActive()),
                } as ICoachListData));
            });
    }

    @Autobind
    public onGridInitialized(e: InitializedEvent) {
        this.gridHelper.initialiseGrid(e);
    }

    @Autobind
    public resetGridState() {
        this.gridHelper.callGrid((grid) => grid.resetState());
    }

    @Autobind
    public showColumnChooser() {
        this.gridHelper.callGrid((grid) => grid.component.showColumnChooser());
    }

    @Autobind
    public exportAllData() {
        this.gridHelper.callGrid((grid) => DxUtilities.exportGridToExcel("coaches", grid.component));
    }


    public displayCoachType(rowData: ICoachListData) {
        return CoachTypeMetadata.ByType[rowData.coach.coachType].name;
    }

    public get accreditationFilter() {
        return {
            dataSource: [
                {
                    text: "(Blanks)",
                    value: [
                        ["coach.isAccreditedRBCoach", "=", false],
                        "and",
                        ["coach.isAccreditedOwnerMentor", "=", false],
                    ],
                },
                {
                    text: "Resilient Business Coach",
                    value: ["coach.isAccreditedRBCoach", "=", true],
                },
                {
                    text: "Owner Mentor",
                    value: ["coach.isAccreditedOwnerMentor", "=", true],
                },
            ] as IGridHeaderItem[],
        } as ColumnHeaderFilter;
    }
}
