import { Component, Inject } from "@angular/core";
import { CoachSession } from "@common/ADAPT.Common.Model/organisation/coach-session";
import { CoachSessionStatusMetadata } from "@common/ADAPT.Common.Model/organisation/coach-session-status";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";

@Component({
    selector: "adapt-edit-coach-session-dialog",
    templateUrl: "./edit-coach-session-dialog.component.html",
})
export class EditCoachSessionDialogComponent extends BaseDialogWithDiscardConfirmationComponent<CoachSession> {
    public readonly dialogName = "CoachSession";
    protected readonly autoResolveData = this.coachSession;
    protected readonly entitiesToConfirm = [this.coachSession];

    public readonly CoachSessionStatuses = CoachSessionStatusMetadata.All;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public coachSession: CoachSession,
    ) {
        super();
    }

    public get coachIsRequired() {
        return CoachSessionStatusMetadata.CoachRequiredStatuses.includes(CoachSessionStatusMetadata.ByType[this.coachSession.status]);
    }
}
