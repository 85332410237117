import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { OrganisationsService } from "app/features/organisations/organisations.service";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";

@Component({
    selector: "adapt-select-organisation",
    templateUrl: "./select-organisation.component.html",
})
export class SelectOrganisationComponent implements OnInit, OnChanges {
    @Input() public filter?: (entity: Organisation) => boolean;
    @Input() public sort?: keyof Organisation | string | { getter: string, desc?: boolean } | ((org: Organisation) => any) = "name";
    @Input() public showClearButton: boolean = false;

    @Input() public organisation?: Organisation;
    @Output() public organisationChange = new EventEmitter<Organisation>();

    public dataSource?: DataSource;

    public constructor(private orgService: OrganisationsService) { }

    public async ngOnInit() {
        return this.setDataSource(true);
    }

    public async ngOnChanges(changes: SimpleChanges) {
        if (changes.filter) {
            this.setDataSource();
        }
    }

    public async onOrganisationChanged(newValue?: Organisation) {
        if (newValue) {
            this.organisationChange.emit(newValue);
            return;
        }

        // normally don't want to emit undefined here, but when showing clear button, undefined is an expected value
        if (this.showClearButton) {
            this.organisationChange.emit(undefined);
        }
    }

    private async setDataSource(forceRemote = false) {
        const organisations = await this.orgService.getOrganisationsWithAccounts(forceRemote);

        this.dataSource = new DataSource({
            store: new ArrayStore({ data: organisations }),
            filter: this.filter,
            sort: this.sort as any,
        });
    }

    public reload() {
        this.setDataSource(true);
    }
}
