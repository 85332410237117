import { Component, Injector, OnInit } from "@angular/core";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";

@Component({
    selector: "adapt-landing-page",
    templateUrl: "./landing-page.component.html",
})
export class LandingPageComponent extends BaseRoutedComponent implements OnInit {
    constructor(injector: Injector) {
        super(injector);
    }

    public ngOnInit() {
        this.notifyActivated();
    }
}
