import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { PeopleService } from "app/features/people/people.service";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";

export type ISelectPersonFilter = (person: Person) => boolean;

@Component({
    selector: "adapt-select-person",
    templateUrl: "./select-person.component.html",
})
export class SelectPersonComponent implements OnInit, OnChanges {
    private allPeople: Person[] = [];

    @Input() public person?: Person;
    @Output() public personChange = new EventEmitter<Person>();

    @Input() public required: boolean = false;
    @Input() public filter?: ISelectPersonFilter;

    public dataSource?: DataSource;

    constructor(private peopleService: PeopleService) { }

    public ngOnInit() {
        this.peopleService.getAllPeople().subscribe((people) => {
            this.allPeople = people;
            this.reload();
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.allPeople.length) {
            if ((changes.filter && !changes.filter.firstChange)
                || (changes.teamGroup && !changes.teamGroup.firstChange)) {
                this.reload();
            }
        }
    }

    public reload() {
        const filteredPeople = this.filter
            ? this.allPeople.filter(this.filter)
            : this.allPeople;

        if (this.person && filteredPeople.indexOf(this.person) < 0) {
            filteredPeople.push(this.person);
        }

        const orderedPeople = filteredPeople.sort((person1, person2) => person1.fullName < person2.fullName ? -1 : 1);

        this.dataSource = new DataSource({
            store: new ArrayStore({
                data: orderedPeople,
                key: "personId",
            }),
        });
    }

    public itemChange(selection: Person) {
        this.personChange.emit(selection);
    }
}
