import { Injectable, Injector } from "@angular/core";
import { WorkflowConnection, WorkflowConnectionBreezeModel } from "@common/ADAPT.Common.Model/organisation/workflow-connection";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { BaseService } from "@common/service/base.service";

@Injectable({
    providedIn: "root",
})
export class WorkflowService extends BaseService {
    public constructor(injector: Injector) {
        super(injector);
    }

    public getWorkflowConnectionsByOrg(organisationId: number) {
        const predicate = new MethodologyPredicate<WorkflowConnection>("organisationId", "==", organisationId);
        return this.commonDataService.getWithOptions(WorkflowConnectionBreezeModel, predicate.getKey(WorkflowConnectionBreezeModel.identifier), {
            predicate,
            navProperty: "team,statuses,connection",
        });
    }
}
