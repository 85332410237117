<dx-data-grid [dataSource]="organisations"
              [allowColumnReordering]="true"
              [allowColumnResizing]="true"
              [columnAutoWidth]="true"
              [rowAlternationEnabled]="true">

    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>

    <dxi-column dataField="organisationId"
                caption="Id"
                width="80"></dxi-column>
    <dxi-column dataField="name"
                caption="Name"
                cellTemplate="organisationCellTemplate"></dxi-column>
    <dxi-column caption="embedADAPT"
                dataField="urlIdentifier"
                cellTemplate="embedADAPTCellTemplate"></dxi-column>

    <div *dxTemplate="let data of 'organisationCellTemplate'">
        <adapt-link-organisation [organisation]="data.data"></adapt-link-organisation>
    </div>

    <div *dxTemplate="let data of 'embedADAPTCellTemplate'">
        <adapt-link-organisation-embed [organisation]="data.data"></adapt-link-organisation-embed>
    </div>
</dx-data-grid>
