<div class="row">
    <div class="col-12">
        <div class="alert alert-info">
            <p>
                Manually trigger survey scheduler checks,
                which will otherwise be triggered every half an hour by Azure Webjob.
            </p>
            <p>
                Note that Azure Webjob is only available on Azure cloud; so this button will be required to perform
                test outside of the cloud environment.
            </p>
            <p>
                In production, this functionality can also be used to fast-track checks so that we do not want to wait for
                at most half an hour, especially when we schedule a survey with start time in the past.
            </p>
        </div>

        <button class="mt-2 btn btn-primary"
                [adaptBlockingClick]="triggerWebjob">
            Trigger Survey Scheduler Checks
        </button>
        <div class="mt-3 alert alert-light"
             *ngIf="surveyWebjobTriggerResult">{{surveyWebjobTriggerResult}}</div>
    </div>
</div>
