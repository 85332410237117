import { Component, Input } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { StakeholderServicesService } from "app/features/tools/stakeholder-services.service";

@Component({
    selector: "adapt-login-as",
    templateUrl: "./login-as.component.html",
})
export class LoginAsComponent {
    @Input() public person!: Person;

    public error?: string;

    constructor(private stakeholderService: StakeholderServicesService) { }

    @Autobind
    public async loginAs() {
        const accessToken = await this.getAccessToken();
        if (accessToken) {
            const url = new URL("/account/login", AdaptClientConfiguration.FrontEndBaseUri);
            url.hash = `userName=${this.person.methodologyUser?.userName}&accessToken=${accessToken}`;
            window.open(url.toString(), "_blank");
        }
    }

    public async getAccessToken() {
        try {
            this.error = undefined;
            const result = await this.stakeholderService.loginAs({
                email: this.person.methodologyUser?.userName,
            });
            return result.AccessToken;
        } catch (e) {
            this.error = ErrorHandlingUtilities.getHttpResponseMessage(e);
        }
    }
}
