<div role="alert"
     class="alert alert-info">
    Copy a meeting agenda template between orgs
</div>

<form role="form">
    <div class="row">
        <div class="col-12 col-lg-8">
            <div class="form-group">
                <h4>Source Organisation</h4>
                <adapt-select-organisation (organisationChange)="onSourceOrganisationChanged($event)"></adapt-select-organisation>
            </div>

            <div *ngIf="sourceOrganisation"
                 class="form-group">
                <h4>Source Team</h4>
                <small>(optional)</small>
                <adapt-select-team [organisation]="sourceOrganisation"
                                   [showClearButton]="true"
                                   (teamChange)="sourceTeam = $event"></adapt-select-team>
            </div>

            <div *ngIf="sourceOrganisation"
                 class="form-group">
                <h4>Meeting Agenda</h4>
                <adapt-select-meeting-agenda-template [organisation]="sourceOrganisation"
                                                      [team]="sourceTeam"
                                                      (meetingAgendaTemplateChange)="updateAgendaTemplate($event)"></adapt-select-meeting-agenda-template>
            </div>

            <div *ngIf="meetingAgendaTemplate"
                 class="form-group">
                <h4>Destination Organisation</h4>
                <adapt-select-organisation (organisationChange)="destinationOrganisation = $event"
                                           [filter]="filterSourceOrganisation"></adapt-select-organisation>
            </div>

            <div *ngIf="destinationOrganisation"
                 class="form-group">
                <h4>Destination Team</h4>
                <div class="alert alert-info">Leave blank if the agenda is going to an Organisation</div>
                <adapt-select-team [organisation]="destinationOrganisation"
                                   [showClearButton]="true"
                                   (teamChange)="destinationTeam = $event"></adapt-select-team>
            </div>
            <div *ngIf="isComplete"
                 class="form-group">
                <adapt-check-my-maths (isMyMathsOk)="isMyMathsOk=$event">
                </adapt-check-my-maths>
            </div>

            <button [adaptBlockingClick]="promiseToCopyMeetingTemplate"
                    [disabled]="!isMyMathsOk || !isComplete"
                    adaptButton="copy"> meeting template</button>

            <div *ngIf="error"
                 role="alert"
                 class="alert alert-danger">{{error}}</div>
        </div>
    </div>
</form>
