import { Component, Injector, OnInit, ViewChild } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { dxTabPanelItem } from "devextreme/ui/tab_panel";
import { lastValueFrom } from "rxjs";
import { OrganisationsService } from "../organisations.service";
import { PaymentsComponent } from "../payments/payments.component";

@Component({
    selector: "adapt-organisation-page",
    templateUrl: "./organisation-page.component.html",
})
export class OrganisationPageComponent extends BaseRoutedComponent implements OnInit {
    private static TabParam = "tab";

    public items = [
        {
            title: "General",
            template: "generalTemplate",
        },
        {
            title: "Commercial Model",
            template: "accountTemplate",
        },
        {
            title: "Payments",
            template: "paymentsTemplate",
        },
        {
            title: "People",
            template: "peopleTemplate",
        },
        {
            title: "Coach",
            template: "coachTemplate",
        },
        {
            title: "Data Audit",
            template: "dataTemplate",
        },
        {
            title: "Coach sessions",
            template: "coachSessionsTemplate",
        },
    ];

    public organisation?: Organisation;


    public selectedTab = this.items[0];

    @ViewChild(PaymentsComponent) private paymentsComponent?: PaymentsComponent;

    constructor(
        private organisationsService: OrganisationsService,
        injector: Injector,
    ) {
        super(injector);

        if (!AdaptClientConfiguration.IsCurrentEmbedApiBaseUri) {
            this.items.push({
                title: "Pathway Status",
                template: "pathwayTemplate",
            });
        }
    }

    public async ngOnInit() {
        const urlIdentifier = this.routeService.getRouteParam("organisationUrlIdentifier");
        this.organisation = await this.organisationsService.getOrganisationByUrlIdentifier(urlIdentifier!);

        if (!this.organisation) {
            await this.routeService.gotoHome();
            return;
        }

        await this.organisationsService.getSupplementaryDataForOrganisation(this.organisation.organisationId);
        await this.organisationsService.getAccountsForOrganisation(this.organisation.organisationId);
        await lastValueFrom(this.organisationsService.getCurrencies());

        this.isInitialised = true;
        this.notifyActivated();
    }

    public onContentReady() {
        const tabTitle = this.routeService.getSearchParameterValue(OrganisationPageComponent.TabParam);
        const tab = this.items.find((i) => i.title === tabTitle);

        if (tab) {
            this.selectedTab = tab;
        }
    }

    public onSelectedItemChanged(item: dxTabPanelItem) {

        const tabName = this.routeService.getSearchParameterValue(OrganisationPageComponent.TabParam);
        if (tabName !== item.title) {
            this.setSearchParameterValue(OrganisationPageComponent.TabParam, item.title);
        }

        if (item.template === "paymentsTemplate") {
            // this will only be defined if paymentsComponent tab has been previously loaded, which is what we want
            // as the initial loading will update this anyway. This is to handle case where pricing model changed
            // and next invoice is outdated.
            this.paymentsComponent?.updateNextInvoice();
        }
    }
}
