import { Component } from "@angular/core";
import { NavigationHierarchyService } from "@common/route/navigation-hierarchy.service";
import { INavigationNode } from "@common/route/navigation-node.interface";
import { Observable } from "rxjs";
import { NimbusNavigationService } from "../nimbus-navigation.service";

@Component({
    selector: "adapt-nimbus-sidebar-content",
    template: `
        <adapt-display-link-hierarchy class="d-block mt-3"
                                      [hierarchy]="nimbusHierarchyNode$ | async"></adapt-display-link-hierarchy>
    `,
})
export class NimbusSidebarContentComponent {
    public nimbusHierarchyNode$: Observable<INavigationNode | undefined>;

    public constructor(
        private navHierarchyService: NavigationHierarchyService,
    ) {
        this.nimbusHierarchyNode$ = this.navHierarchyService.hierarchyChanged(NimbusNavigationService.HierarchyId);
    }
}
