import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { OrganisationsService } from "../organisations.service";

@Component({
    selector: "adapt-link-organisation-embed",
    templateUrl: "./link-organisation-embed.component.html",
})
export class LinkOrganisationEmbedComponent implements OnChanges {
    @Input() public organisation!: Organisation;
    public href: string = "";

    constructor(private organisationService: OrganisationsService) {
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.organisation.currentValue) {
            this.href = this.organisationService.getOrganisationEmbedUrl(changes.organisation.currentValue);
        }
    }
}
