import { Component, Injector, OnInit, ViewChild } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Survey, SurveyTypeLabel } from "@common/ADAPT.Common.Model/organisation/survey";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { lastValueFrom } from "rxjs";
import { SelectSurveyComponent } from "../select-survey/select-survey.component";
import { StakeholderServicesService } from "../stakeholder-services.service";

@Component({
    selector: "adapt-delete-survey-page",
    templateUrl: "./delete-survey-page.component.html",
})
export class DeleteSurveyPageComponent extends BaseRoutedComponent implements OnInit {
    public organisation?: Organisation;
    public survey?: Survey;

    public filter?: (entity: Survey) => boolean;
    public isMyMathsOk = false;
    public isComplete = false;
    public error?: string;
    public loading = false;

    @ViewChild(SelectSurveyComponent)
    public selectSurveyComponent?: SelectSurveyComponent;

    public constructor(
        injector: Injector,
        private commonDialogService: AdaptCommonDialogService,
        private commonDataService: CommonDataService,
        private stakeholderServicesService: StakeholderServicesService,
    ) {
        super(injector);

    }

    public ngOnInit() {
        this.notifyActivated();
    }

    public hasAllRequiredFields() {
        return !!this.organisation && !!this.survey;
    }

    @Autobind
    public validateSelection() {
        this.isComplete = this.hasAllRequiredFields();
    }

    @Autobind
    public async deleteSurvey() {
        if (this.survey) {
            const viewModel = {
                surveyId: this.survey.surveyId,
            };

            const dialogData = { title: `Delete Survey: ${this.survey.name}`, message: `Are you sure you want to delete the ${SurveyTypeLabel[this.survey.surveyType]} Survey: ${this.survey.name}`, confirmButtonText: "Delete" };
            const confirmed = await lastValueFrom(this.commonDialogService.openConfirmationDialogWithBoolean(dialogData));

            if (confirmed) {
                try {
                    await this.stakeholderServicesService.deleteSurvey(viewModel);

                    this.error = undefined;

                    this.commonDataService.detachEntityFromBreeze(this.survey);
                    this.survey = undefined;
                    this.selectSurveyComponent!.reload();

                    this.isComplete = false;
                    return lastValueFrom(this.commonDialogService.showMessageDialog("Success", "Survey has been successfully deleted!"));
                } catch (error) {
                    this.error = ErrorHandlingUtilities.getHttpResponseMessage(error);
                    return Promise.reject();
                }
            }
        }
    }
}
