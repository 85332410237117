import { ModuleWithProviders, NgModule } from "@angular/core";
import { SentryTracker } from "@common/track/sentry-tracker.service";
import { provideUserEventHandler } from "@common/user/user-event-handler.interface";
import { provideDialogEventHandler } from "@common/ux/adapt-common-dialog/dialog-event-handler.interface";

@NgModule()
export class AdaptTrackingModule {
    public static forRoot(): ModuleWithProviders<AdaptTrackingModule> {
        return {
            ngModule: AdaptTrackingModule,
            providers: [
                provideUserEventHandler(SentryTracker),
                provideDialogEventHandler(SentryTracker),
            ],
        };
    }
}
