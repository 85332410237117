<div class="move-board-component">
    <div role="alert"
         class="alert alert-info">
        Delete a Survey from a Organisation
    </div>

    <form role="form">
        <div class="row">
            <div class="col-12 col-lg-8">
                <div class="form-group">
                    <h4>Organisation</h4>
                    <adapt-select-organisation [(organisation)]="organisation"
                                               (organisationChange)="survey = undefined"></adapt-select-organisation>
                </div>

                <ng-container *ngIf="organisation">
                    <div class="form-group">
                        <h4>Survey</h4>
                        <adapt-select-survey [organisation]="organisation"
                                             [(survey)]="survey"
                                             (surveyChange)="validateSelection()"></adapt-select-survey>
                    </div>
                </ng-container>
                <ng-container *ngIf="survey">
                    <ul class="form-group list-group-flush">
                        <li>
                            <span class="fw-bold">Survey Type: </span>{{survey.surveyType}}
                        </li>
                        <li *ngIf="survey.team">
                            <span class="fw-bold">Team: </span>{{survey.team.name}}
                        </li>
                        <li>
                            <span class="fw-bold">Start Time: </span>{{survey.startTime | adaptDateTime}}
                        </li>
                        <li>
                            <span class="fw-bold">End Time: </span>{{survey.endTime | adaptDateTime}}
                        </li>
                        <li>
                            <span class="fw-bold">Status: </span>{{survey.status}}
                        </li>
                        <li>
                            <span class="fw-bold">Creator: </span>{{survey.createdBy?.fullName}}
                        </li>
                        <li>
                            <span class="fw-bold">Surveyor: </span>{{survey.surveyor?.fullName}}
                        </li>
                        <li>
                            <span class="fw-bold">Email Status: </span>{{survey.emailStatus}}
                        </li>
                    </ul>
                </ng-container>

                <div *ngIf="hasAllRequiredFields()"
                     class="form-group">
                    <adapt-check-my-maths (isMyMathsOk)="isMyMathsOk = $event">
                    </adapt-check-my-maths>
                </div>
            </div>
        </div>

        <button [adaptBlockingClick]="deleteSurvey"
                [disabled]="!isMyMathsOk || !isComplete"
                adaptButton="delete"> Survey</button>

        <div *ngIf="error"
             role="alert"
             class="alert alert-danger">{{error}}</div>
    </form>
</div>
