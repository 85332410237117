import { Component, Injector, OnInit } from "@angular/core";
import { MeetingAgendaTemplate } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-template";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { lastValueFrom } from "rxjs";
import { StakeholderServicesService } from "../stakeholder-services.service";

@Component({
    selector: "adapt-copy-meeting-agenda-template-page",
    templateUrl: "./copy-meeting-agenda-template-page.component.html",
})
export class CopyMeetingAgendaPageComponent extends BaseRoutedComponent implements OnInit {
    public sourceOrganisation?: Organisation;
    public sourceTeam?: Team;

    public meetingAgendaTemplate?: MeetingAgendaTemplate;

    public destinationOrganisation?: Organisation;
    public destinationTeam?: Team;

    public isMyMathsOk = false;
    public error?: string;

    constructor(
        private stakeholderServices: StakeholderServicesService,
        private commonDialogService: AdaptCommonDialogService,
        injector: Injector,
    ) {
        super(injector);
    }

    public ngOnInit() {
        this.notifyActivated();
    }

    public get isComplete() {
        return !!(this.sourceOrganisation && this.meetingAgendaTemplate && this.destinationOrganisation);
    }

    public updateAgendaTemplate(template?: MeetingAgendaTemplate) {
        // do it next digest cycle as this callback is from select box selection, which will cause ExpressionChanged...Error.
        setTimeout(() => {
            this.meetingAgendaTemplate = template;
            if (!template) {
                // if template is cleared - the org selection will be ngIf'ed off - so when template is selected again, the select box won't be
                // reflecting the already selected destination, which may end up with inconsistent team/org from the visual component.
                // - clearing these would be the simplest.
                this.destinationOrganisation = undefined;
                this.destinationTeam = undefined;
            }
        });
    }

    @Autobind
    public filterSourceOrganisation(organisation: Organisation) {
        return organisation.organisationId !== this.sourceOrganisation?.organisationId;
    }

    @Autobind
    public async promiseToCopyMeetingTemplate() {
        this.error = "";

        try {
            await lastValueFrom(this.stakeholderServices.copyMeetingTemplate({
                MeetingAgendaTemplateId: this.meetingAgendaTemplate!.meetingAgendaTemplateId,
                DestinationOrganisationId: this.destinationOrganisation!.organisationId,
                DestinationTeamId: this.destinationTeam?.teamId,
            }));

            await lastValueFrom(this.commonDialogService.showMessageDialog("Copy Successful", `${this.meetingAgendaTemplate?.name} has been successfully been copied to ${this.destinationOrganisation?.name}`));
        } catch (e) {
            this.error = ErrorHandlingUtilities.getHttpResponseMessage(e);
        }
    }

    @Autobind
    public onSourceOrganisationChanged(organisation: Organisation) {
        if (this.sourceOrganisation === organisation) {
            return;
        }

        this.sourceOrganisation = organisation;
        this.meetingAgendaTemplate = undefined;
        this.destinationOrganisation = undefined;
        this.destinationTeam = undefined;
    }
}
