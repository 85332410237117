import { Component, Injector, OnInit } from "@angular/core";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";

@Component({
    selector: "adapt-auth-audit-page",
    templateUrl: "./auth-audit-page.component.html",
})
export class AuthAuditPageComponent extends BaseRoutedComponent implements OnInit {

    public constructor(
        injector: Injector,
    ) {
        super(injector);
    }

    public ngOnInit() {
        this.notifyActivated();
    }

}
