/* eslint-disable import/order */
import "./vendor";
/* eslint-enable import/order */
import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { BreezeServiceEndpoint, ServiceUri } from "@common/configuration/service-uri";
import { Logger } from "@common/lib/logger/logger";
import { AppModule } from "app/app.module";
import { environment } from "environments/environment";

if (environment.production) {
    enableProdMode();
}

const logger = Logger.getLogger("main");

AdaptClientConfiguration.initialiseConfiguration(environment.clientConfiguration);
ServiceUri.updateFromConfiguration(AdaptClientConfiguration);
ServiceUri.setBreezeEndpoint(AdaptClientConfiguration, BreezeServiceEndpoint.Stakeholder);

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch((err) => logger.error("Encountered an error while bootstrapping", err));
