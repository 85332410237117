import { Component, Input, OnInit } from "@angular/core";
import { DeepDive, DeepDiveType } from "@common/ADAPT.Common.Model/embed/deep-dive";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IAdaptMenuItem, MenuComponent } from "@common/ux/menu/menu.component";
import { DeepDiveUiService } from "../deep-dive-ui.service";
import { DeepDiveActions } from "./deep-dive-actions.enum";


@Component({
    selector: "adapt-deep-dive-actions",
    templateUrl: "./deep-dive-actions.component.html",
})
export class DeepDiveActionsComponent extends BaseComponent implements OnInit {
    @Input() public deepDive!: DeepDive;
    public menuItems: IAdaptMenuItem[];

    private uploadMenuItem: IAdaptMenuItem = {
        text: DeepDiveActions.Upload,
        icon: "fal fa-fw fa-upload",
        onClick: () => this.deepDiveUiService.uploadDeepDiveContent(this.deepDive, false).subscribe(),
        visible: true,
    };

    public constructor(
        private deepDiveUiService: DeepDiveUiService,
    ) {
        super();

        this.menuItems = [{
            icon: MenuComponent.SmallRootMenu.icon,
            items: [
                {
                    text: DeepDiveActions.Edit,
                    icon: "fal fa-fw fa-cog",
                    onClick: () => this.deepDiveUiService.editDeepDive(this.deepDive).subscribe(),
                },
                this.uploadMenuItem,
                {
                    text: DeepDiveActions.SelectImage,
                    icon: "fal fa-fw fa-image",
                    onClick: () => this.deepDiveUiService.selectDeepDiveImage(this.deepDive).subscribe(),
                },
                {
                    text: DeepDiveActions.Delete,
                    icon: "fal fa-fw fa-trash",
                    onClick: () => this.deepDiveUiService.deleteDeepDive(this.deepDive).subscribe(),
                },
            ],
        }];
    }

    public ngOnInit() {
        this.updateMenu();
    }

    public updateMenu() {
        this.uploadMenuItem.visible = this.deepDive.type === DeepDiveType.Scorm;
    }
}
