<div adaptToolbarContent
     adaptBlockingButtonGroup>
    <button adaptButton="save"
            [adaptBlockingClick]="save"
            [disabled]="!editFeaturesComponent"></button>
    <button adaptButton="cancel"
            [adaptBlockingClick]="cancel"
            [disabled]="!editFeaturesComponent"></button>
</div>

<adapt-select-organisation (organisationChange)="selectedOrganisation = $event"></adapt-select-organisation>
<adapt-edit-organisation-features *ngIf="selectedOrganisation"
                                  [organisation]="selectedOrganisation"></adapt-edit-organisation-features>
