import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Survey, SurveyBreezeModel, SurveyTypeLabel } from "@common/ADAPT.Common.Model/organisation/survey";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IDxSelectBoxValueChangedEvent } from "@common/ux/dx.types";
import DataSource, { DataSourceOptions } from "devextreme/data/data_source";
import { lastValueFrom } from "rxjs";

@Component({
    selector: "adapt-select-survey",
    templateUrl: "./select-survey.component.html",
})
export class SelectSurveyComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() public organisation?: Organisation;
    @Input() public survey?: Survey;
    @Output() public surveyChange = new EventEmitter<Survey>();

    public surveys?: Survey[];
    public dataSource?: DataSource;

    public constructor(
        private commonDataService: CommonDataService,
    ) {
        super();
    }

    public ngOnInit() {
        this.setDataSource();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.organisation) {
            this.onOrganisationChanged();
        }
    }

    private async setDataSource() {
        this.surveys = await this.fetchSurveys();

        this.dataSource = new DataSource({
            store: this.surveys,
            sort: [{ selector: "startTime" }],
            group: this.group,
        } as DataSourceOptions<Survey>);
    }

    private async fetchSurveys() {
        if (!this.organisation) {
            return lastValueFrom(this.commonDataService.getAll(SurveyBreezeModel));
        }

        const predicate = new MethodologyPredicate<Survey>("organisationId", "==", this.organisation.organisationId);
        return lastValueFrom(this.commonDataService.getWithOptions(
            SurveyBreezeModel,
            predicate.getKey(SurveyBreezeModel.identifier),
            { predicate, navProperty: "team,createdBy,surveyor" }));
    }

    private onOrganisationChanged() {
        this.survey = undefined;

        this.surveyChange.emit(this.survey);

        this.setDataSource();
    }

    public async onSurveyChanged(e: IDxSelectBoxValueChangedEvent<Survey>) {
        if (e.value?.surveyId === e.previousValue?.surveyId) {
            return;
        }

        if (e.value) {
            this.survey = e.value;
        }

        this.surveyChange.emit(this.survey);
    }

    private group(item?: Survey) {
        if (item) {
            return SurveyTypeLabel[item.surveyType];
        }
    }

    public reload() {
        this.setDataSource();
    }
}
