<div>
    <div role="alert"
         class="alert alert-info">
        Merge two users into a single user and person.
        The log in email address for the old person will be removed from the database and any reference to the old person will be replaced
        by the new person.
    </div>

    <div role="alert"
         class="alert alert-warning">
        <h3>*** Note: This operation is not recoverable. ***</h3>
    </div>

    <form role="form">

        <div class="row">
            <div class="col-12 col-lg-8">

                <div class="form-group">
                    <h4>Old Person Id</h4>
                    <dx-number-box [(value)]="oldPersonId"
                                   (onValueChanged)="updateOldPerson()"
                                   placeholder="The PersonId of the person you are removing...">
                        <dx-validator>
                            <dxi-validation-rule type="required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-number-box>
                    <h5 *ngIf="oldPerson">{{oldPerson.fullName}}</h5>
                </div>
                <div class="form-group">
                    <h4>New Person Id</h4>
                    <dx-number-box [(value)]="newPersonId"
                                   (onValueChanged)="updateNewPerson()"
                                   placeholder="The PersonId of the person you are keeping...">
                        <dx-validator>
                            <dxi-validation-rule type="required">
                            </dxi-validation-rule>
                        </dx-validator>
                    </dx-number-box>
                    <h5 *ngIf="newPerson">{{newPerson.fullName}}</h5>
                </div>
                <div class="form-group">
                    <adapt-check-my-maths (isMyMathsOk)="isMyMathsOk = $event"></adapt-check-my-maths>
                </div>
            </div>
        </div>
        <button adaptButton="merge"
                [adaptBlockingClick]="mergeUsers"> Users</button>
        <div role="alert"
             class="alert alert-danger"
             *ngIf="error">{{error}}</div>
    </form>
</div>
