
export enum DocumentationSubscriptionLevel {
    Anonymous = "Anonymous",
    Coaches = "Coaches",
    OwnerDirectorCoaches = "OwnerDirectorCoaches",
}

export class DocumentationSubscriptionLevelLabel {
    private static labels: { [type in DocumentationSubscriptionLevel]: string } = {
        Anonymous: "Standard",
        Coaches: "Standard + Coach Kit",
        OwnerDirectorCoaches: "Standard + Coach Kit + Owner-Mentor Coach Kit",
    };

    public static label(level: DocumentationSubscriptionLevel) {
        return DocumentationSubscriptionLevelLabel.labels[level];
    }
}
