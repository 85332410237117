import { Component, Inject } from "@angular/core";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";

@Component({
    selector: "adapt-coach-connection-dialog",
    templateUrl: "./coach-connection-dialog.component.html",
})
export class CoachConnectionDialogComponent extends BaseDialogWithDiscardConfirmationComponent<Connection> {
    public readonly dialogName = "CoachConnection";
    protected readonly autoResolveData = this.connection;

    public isNew: boolean;
    public entitiesToConfirm: IBreezeEntity[];

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public connection: Connection,
    ) {
        super();

        this.isNew = connection.entityAspect.entityState.isAdded();

        this.entitiesToConfirm = [
            connection,
            ...connection.roleConnections,
        ];
    }
}
