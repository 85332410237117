<dx-data-grid [dataSource]="reviews"
              [allowColumnReordering]="true"
              [allowColumnResizing]="true"
              [columnAutoWidth]="true"
              [rowAlternationEnabled]="true"
              [showRowLines]="true"
              [hoverStateEnabled]="true"
              (onRowClick)="editRating($event.data)"
              [showBorders]="true"
              [wordWrapEnabled]="true">
    <!-- Options -->
    <dxo-header-filter [visible]="true">
    </dxo-header-filter>
    <dxo-filter-row [visible]="true">
    </dxo-filter-row>
    <dxo-column-chooser [enabled]="true">
    </dxo-column-chooser>
    <dxo-pager [showPageSizeSelector]="true"
               [allowedPageSizes]="[10, 25, 50, 100, 250]">
    </dxo-pager>

    <!-- Columns -->
    <dxi-column caption="Organisation"
                dataField="organisationId"
                cellTemplate="orgCellTemplate"
                dataType="number">
    </dxi-column>

    <dxi-column caption="Organisation name"
                dataField="organisationName"
                dataType="string"
                [visible]="false">
    </dxi-column>

    <dxi-column caption="Submission date"
                dataField="submittedOn"
                dataType="date"
                [format]="dateFormat"
                sortOrder="desc">
    </dxi-column>

    <dxi-column caption="Workflow ID"
                dataField="workflowId"
                dataType="string"
                [visible]="false">
    </dxi-column>

    <dxi-column caption="Pathway"
                dataField="workflowName"
                dataType="string">
    </dxi-column>

    <dxi-column caption="Liked"
                dataField="liked"
                width="150"
                dataType="string">
    </dxi-column>

    <dxi-column caption="Improve on"
                dataField="improveOn"
                width="150"
                dataType="string">
    </dxi-column>

    <dxi-column caption="Person"
                dataField="partitionKey"
                dataType="string"
                cellTemplate="personCellTemplate">
    </dxi-column>

    <dxi-column caption="Rating"
                dataField="rating"
                dataType="number"
                cellTemplate="ratingCellTemplate">
    </dxi-column>

    <dxi-column caption="Our comment"
                dataField="comment"
                width="200"
                cellTemplate="commentCellTemplate"
                dataType="text">
    </dxi-column>

    <dxi-column caption="Status"
                dataField="status"
                dataType="string"
                cellTemplate="statusCellTemplate">
    </dxi-column>

    <div *dxTemplate="let item of 'orgCellTemplate'">
        <adapt-link-organisation [organisation]="getOrganisation(item.data.organisationId)">
        </adapt-link-organisation>
    </div>

    <div *dxTemplate="let item of 'personCellTemplate'">
        <adapt-link-person [personId]="item.data.partitionKey"></adapt-link-person>
    </div>

    <div *dxTemplate="let item of 'ratingCellTemplate'">
        <adapt-rating-stars disabled
                            compact
                            [(rating)]="item.data.rating"></adapt-rating-stars>
    </div>

    <div *dxTemplate="let item of 'statusCellTemplate'">
        <div *ngIf="item.data.status !== undefined"
             class="badge round-pill"
             [ngClass]="pathwayReviewService.getStatus(item.data.status)">
            {{item.data.status}}</div>
    </div>

    <div *dxTemplate="let item of 'commentCellTemplate'"
         [froalaView]="item.data.comment">
    </div>
</dx-data-grid>
