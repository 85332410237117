import { Component, Injector, OnInit } from "@angular/core";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { lastValueFrom } from "rxjs";
import { StakeholderServicesService } from "../stakeholder-services.service";

enum SeedingType {
    CoachLed = 0,
    SelfManaged = 1,
}

@Component({
    templateUrl: "./seed-database-page.component.html",
    selector: "adapt-seed-database-page",
})
export class SeedDatabasePageComponent extends BaseRoutedComponent implements OnInit {
    public error?: string;

    public constructor(
        private stakeholderServicesService: StakeholderServicesService,
        private commonDialogService: AdaptCommonDialogService,
        injector: Injector,
    ) {
        super(injector);
    }

    public ngOnInit() {
        this.notifyActivated();
    }

    @Autobind
    public async startDatabaseSeed() {
        this.error = undefined;

        try {
            const seedingType = AdaptClientConfiguration.IsCurrentEmbedApiBaseUri
                ? SeedingType.CoachLed
                : SeedingType.SelfManaged;
            await this.stakeholderServicesService.seedDatabase({ seedingType });
            await lastValueFrom(this.commonDialogService.showMessageDialog("Test Organisation Seed Complete", "Test organisations have been seeded successfully.", "OK"));
        } catch (e) {
            this.error = ErrorHandlingUtilities.getHttpResponseMessage(e);
        }
    }
}
