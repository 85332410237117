<adapt-dialog [maxWidth]="600">
    <h3 adapt-dialog-title>Upload Deep Dive Content</h3>

    <ng-container adapt-dialog-content>
        <p>Uploading deep dive content for <b>{{data.deepDive.title}}</b>.</p>

        <form role="form"
              class="deep-dive-upload-form">
            <div class="form-group mb-0">
                <label for="file">SCORM Archive</label>
                <dx-file-uploader id="file"
                                  name="file"
                                  accept="application/zip,application/x-zip-compressed"
                                  [allowedFileExtensions]="['.zip']"
                                  [maxFileSize]="maxAllowedFileSize"
                                  [multiple]="false"
                                  uploadMode="useButtons"
                                  [uploadUrl]="uploadUrl"
                                  (onContentReady)="onUploaderContentReady($event)"
                                  (onValueChanged)="onValueChanged($event)"
                                  (onBeforeSend)="onBeforeSend($event)"
                                  (onUploaded)="onFileUploaded($event)"
                                  (onUploadError)="onUploadError($event)"></dx-file-uploader>
            </div>
        </form>
    </ng-container>

    <div adapt-dialog-footer
         class="ms-auto">
        <button class="btn btn-primary"
                [disabled]="uploadInProgress || !fileSelected"
                (click)="performUpload()">Upload & Close
        </button>
        <button adaptButton="cancel"
                (click)="onCancel()"></button>
    </div>
</adapt-dialog>
