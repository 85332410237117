<dx-check-box [value]="createSandbox"
              (onValueChanged)="createSandboxChanged($event)"
              text="Create a sandbox organisation"></dx-check-box>

<ng-container *ngIf="createSandbox && createdOrganisation">
    <p class="mt-3">
        The new coach will be added to the sandbox as an <b>Employee</b> with the following roles: <b>Organisation Leader</b>,
        <b>Adapt Administrator</b>.
    </p>
    <div class="col-12 col-md-6 form-group">
        <label for="organisation-name-input">Name</label>
        <dx-text-box id="organisation-name-input"
                     [(value)]="createdOrganisation.name"
                     [maxLength]="60"
                     (onValueChanged)="orgNameChanged($event)">
            <dx-validator>
                <dxi-validation-rule type="required"
                                     message="Organisation name is required"></dxi-validation-rule>
                <dxi-validation-rule [adaptEntity]="createdOrganisation"
                                     adaptEntityProperty="name"></dxi-validation-rule>
            </dx-validator>
        </dx-text-box>
    </div>
    <div class="col-12 col-md-6 form-group mt-3">
        <label for="url-id-input">Portal URL</label>
        <div class="input-group">
            <div class="input-group-prepend">
                <span class="input-group-text h-100">embed.adaptbydesign.com.au/</span>
            </div>
            <dx-text-box id="url-id-input"
                         class="flex-grow-1"
                         placeholder="yourOrganisation"
                         [(value)]="createdOrganisation.urlIdentifier"
                         [maxLength]="20">
                <dx-validator>
                    <dxi-validation-rule type="required"
                                         message="Portal URL is required"></dxi-validation-rule>
                    <!-- regex only allows lowercase alphanumerics -->
                    <dxi-validation-rule type="pattern"
                                         pattern="^[a-z0-9-]+$"
                                         message="Must be lowercase letters, numbers or dashes"></dxi-validation-rule>
                    <dxi-validation-rule [adaptEntity]="createdOrganisation"
                                         adaptEntityProperty="urlIdentifier"></dxi-validation-rule>
                </dx-validator>
            </dx-text-box>
        </div>
    </div>
</ng-container>

<hr />

<div class="col-12 col-md-6 form-group">
    <label>Join an existing sandbox organisation</label>
    <p class="my-3">
        The new coach will be added to the organisation as a <b>Coach</b>.
    </p>
    <adapt-select-organisation [filter]="filterSandbox"
                               [showClearButton]="true"
                               [(organisation)]="joinOrganisation"
                               (organisationChange)="joinOrganisationChange.emit($event)"
                               sort="name"></adapt-select-organisation>
</div>
