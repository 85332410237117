<div class="col-12">
    <dx-data-grid *adaptLoading="!organisation"
                  [dataSource]="dataList"
                  [allowColumnReordering]="true"
                  [allowColumnResizing]="true"
                  [columnAutoWidth]="true"
                  [rowAlternationEnabled]="true"
                  [showRowLines]="true"
                  [showBorders]="true">
        <!-- Options -->
        <dxo-header-filter [visible]="true">
        </dxo-header-filter>
        <dxo-filter-row [visible]="true">
        </dxo-filter-row>
        <dxo-column-chooser [enabled]="false">
        </dxo-column-chooser>
        <dxo-paging [pageSize]="100"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true"
                   [allowedPageSizes]="[10, 25, 50, 100, 250]">
        </dxo-pager>
        <dxo-group-panel [visible]="true">
        </dxo-group-panel>
        <dxo-grouping [autoExpandAll]="true">
        </dxo-grouping>
        <dxo-export [enabled]="true"
                    fileName="pathways"></dxo-export>

        <!-- Columns -->
        <dxi-column caption="Pathway"
                    dataField="workflowConnectionId"
                    [customizeText]="getWorkflowId"
                    [groupIndex]="0"
                    sortOrder="desc"
                    [allowGrouping]="true">
        </dxi-column>

        <dxi-column caption="Pathway step"
                    dataField="workflowStepId">
        </dxi-column>

        <dxi-column caption="Status"
                    dataField="status">
        </dxi-column>

        <dxi-column caption="Team"
                    [calculateCellValue]="getTeamName">
        </dxi-column>

        <dxi-column caption="Person"
                    cellTemplate="connectionNameLinkCellTemplate">
        </dxi-column>

        <dxi-column caption="Started"
                    dataField="startTime"
                    [format]="dateFormat">
        </dxi-column>

        <dxi-column caption="Started by"
                    cellTemplate="startedByNameLinkCellTemplate">
        </dxi-column>

        <dxi-column caption="Completed"
                    dataField="completionTime"
                    [format]="dateFormat">
        </dxi-column>

        <dxi-column caption="Completed by"
                    cellTemplate="completedByNameLinkCellTemplate">
        </dxi-column>

        <div *dxTemplate="let data of 'completedByNameLinkCellTemplate'">
            <adapt-link-person [person]="data.data.completedBy"></adapt-link-person>
        </div>

        <div *dxTemplate="let data of 'startedByNameLinkCellTemplate'">
            <adapt-link-person [person]="data.data.startedBy"></adapt-link-person>
        </div>

        <div *dxTemplate="let data of 'connectionNameLinkCellTemplate'">
            <adapt-link-person [person]="data.data.connection?.personId"></adapt-link-person>
        </div>
    </dx-data-grid>
</div>
