<div adaptToolbarContent>
    <button adaptButtonIcon="add"
            [adaptBlockingClick]="addNewCoach">Add new coach partner</button>
    <span class="dx-datagrid-export-button">
        <button adaptButtonIcon="dx-icon dx-icon-xlsxfile"
                adaptTooltip="Export all data"
                (click)="exportAllData()"></button>
    </span>
    <button adaptButtonIcon="dx-icon dx-icon-column-chooser"
            adaptTooltip="Column chooser"
            (click)="showColumnChooser()"></button>
    <button adaptButtonIcon="dx-icon dx-icon-revert"
            adaptTooltip="Reset columns"
            (click)="resetGridState()"></button>
</div>

<dx-data-grid [dataSource]="coaches"
              [stateStoring]="gridHelper.stateStoringOptions"
              [allowColumnReordering]="true"
              [allowColumnResizing]="true"
              [columnAutoWidth]="true"
              [rowAlternationEnabled]="true"
              [wordWrapEnabled]="true"
              (onInitialized)="onGridInitialized($event)">

    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>

    <dxi-column dataField="coach.practitionerId"
                dataType="number"
                caption="Id"
                [width]="80"></dxi-column>
    <dxi-column dataField="coach.name"
                dataType="string"
                caption="Name"
                cellTemplate="coachNameLinkCellTemplate"
                [calculateCellValue]="calculateCoachNameCellValue"
                [width]="180"></dxi-column>
    <dxi-column caption="Organisations"
                dataField="dummy_organisations"
                dataType="string"
                cellTemplate="organisationsCellTemplate"
                [allowFiltering]="true"
                [calculateCellValue]="calculateOrganisationsCellValue"
                [width]="180"></dxi-column>
    <dxi-column caption="Accreditations"
                dataField="dummy_accreditations"
                dataType="string"
                cellTemplate="accreditationsCellTemplate"
                [allowFiltering]="true"
                [headerFilter]="accreditationFilter"
                [visible]="true"></dxi-column>
    <dxi-column dataField="coach.notes"
                dataType="string"
                caption="Notes"
                cellTemplate="richTextTemplate"
                [width]="500"></dxi-column>
    <dxi-column dataField="coach.isSubscribing"
                dataType="boolean"
                caption="Subscribing"
                [width]="150"></dxi-column>
    <dxi-column caption="Helpjuice Access"
                dataField="dummy_helpjuice"
                dataType="string"
                [calculateCellValue]="calculateSubscriptionLevelCellValue"
                [allowFiltering]="true"
                [allowSorting]="true"
                [width]="150"></dxi-column>
    <dxi-column dataField="coach.partner"
                dataType="string"
                caption="Partner"
                [calculateCellValue]="calculatePartnerCellValue"
                [visible]="false"></dxi-column>
    <dxi-column caption="Active"
                dataField="dummy_active"
                dataType="boolean"
                [allowFiltering]="true"
                [calculateCellValue]="calculateActiveCellValue"
                [filterValue]="true"></dxi-column>
    <dxi-column dataField="coach.startDate"
                dataType="date"
                caption="Start Date"
                [format]="dateFormat"
                [visible]="false"></dxi-column>
    <dxi-column dataField="coach.endDate"
                dataType="date"
                caption="End Date"
                [format]="dateFormat"
                [visible]="false"></dxi-column>
    <dxi-column dataField="coach.coachType"
                dataType="string"
                caption="Coach Type"
                [allowFiltering]="true"
                [calculateDisplayValue]="displayCoachType"
                [visible]="false"></dxi-column>

    <div *dxTemplate="let data of 'coachNameLinkCellTemplate'">
        <adapt-link-coach [coach]="data.data.coach"></adapt-link-coach>
    </div>
    <div *dxTemplate="let data of 'organisationsCellTemplate'">
        <ul>
            <li *ngFor="let connection of data.data.connections">
                <adapt-link-organisation [organisation]="connection.organisation"></adapt-link-organisation>
            </li>
        </ul>
    </div>
    <ul *dxTemplate="let data of 'accreditationsCellTemplate'"
        class="list-content">
        <li *ngIf="data.data.coach.isAccreditedRBCoach">Resilient Business Coach</li>
        <li *ngIf="data.data.coach.isAccreditedOwnerMentor">Owner Mentor</li>
    </ul>
    <div *dxTemplate="let data of 'richTextTemplate'">
        <div [froalaView]="data.value"></div>
    </div>
</dx-data-grid>
