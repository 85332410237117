import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { PersonDetailNames } from "@common/ADAPT.Common.Model/person/person-detail";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { DateFormats } from "@common/ux/date-formats";

@Component({
    selector: "adapt-person-connections",
    templateUrl: "./person-connections.component.html",
})
export class PersonConnectionsComponent extends BaseComponent implements OnChanges {
    @Input() public person?: Person;

    public data?: Connection[];

    public dateFormat = DateFormats.globalize.short;
    public statuses = [{ value: "Active" }, { value: "Inactive" }];

    constructor() {
        super();
    }

    public ngOnChanges(_: SimpleChanges) {
        this.data = this.person?.connections;
    }

    public getOrganisationName(connection: Connection) {
        return connection.organisation.name;
    }

    public getIsActive(connection: Connection) {
        return connection.isActive();
    }

    public getEmailConfirmed(connection: Connection) {
        return connection.person.methodologyUser?.emailConfirmed;
    }

    public getLastAccess(connection: Connection) {
        const lastAccess = connection.person && connection.person.getDetailValue(PersonDetailNames.LastAccess);

        return lastAccess ? new Date(lastAccess) : undefined;
    }
}
