<div class="card card-info">
    <div class="card-header">
        <h3 class="card-title">Account & contact details</h3>

        <div adaptBlockingButtonGroup
             class="card-header-buttons">
            <button *ngIf="!editMode"
                    adaptButton="edit"
                    (click)="editPerson()"></button>
            <button *ngIf="editMode"
                    adaptButton="save"
                    [adaptBlockingClick]="savePerson"></button>
            <button *ngIf="editMode"
                    adaptButton="cancel"
                    [adaptBlockingClick]="cancelPersonEdit"></button>
        </div>
    </div>
    <div class="card-body row">
        <div role="alert"
             class="alert alert-danger"
             *ngIf="errorMessage">{{errorMessage}}</div>
        <div class="col-md-6">
            <dl class="dl-horizontal">
                <dt>Person Id:</dt>
                <dd>{{person.personId}}</dd>
            </dl>
            <dl class="dl-horizontal">
                <dt>Name:</dt>
                <dd>{{person.fullName}}</dd>
            </dl>
            <dl class="dl-horizontal">
                <dt>Login Email Address:</dt>
                <dd>
                    <input id="emailInput"
                           name="emailInput"
                           class="form-control"
                           type="email"
                           required
                           [(ngModel)]="logInEmailAddress"
                           [disabled]="!editMode" />
                </dd>
            </dl>
        </div>
        <div class="col-md-6">
            <dl class="dl-horizontal">
                <dt>Phone: </dt>
                <dd *ngFor="let phone of phones">
                    <adapt-contact-detail [detail]="phone"></adapt-contact-detail>
                </dd>
            </dl>
            <dl class="dl-horizontal">
                <dt>Email: </dt>
                <dd *ngFor="let email of emails">
                    <adapt-contact-detail [detail]="email"></adapt-contact-detail>
                </dd>
            </dl>
            <dl class="dl-horizontal">
                <dt>Address: </dt>
                <dd *ngFor="let address of addresses">
                    <adapt-contact-detail [detail]="address"></adapt-contact-detail>
                </dd>
            </dl>
        </div>
    </div>
</div>
