<div class="copy-system-component">
    <div role="alert"
         class="alert alert-info">
        Copy a system from one organisation to another.
    </div>

    <form role="form">
        <div class="row">
            <div class="col-12 col-lg-8">
                <div class="form-group">
                    <h4>Source Organisation</h4>
                    <adapt-select-organisation (organisationChange)="sourceOrganisation = $event"></adapt-select-organisation>
                </div>

                <div *ngIf="sourceOrganisation">
                    <div class="form-group">
                        <h4>System</h4>
                        <adapt-select-system [organisation]="sourceOrganisation"
                                             (systemChange)="onSystemChanged($event)"></adapt-select-system>
                    </div>

                    <div *ngIf="system">
                        <div class="form-group">
                            <h4>Destination Organisation</h4>
                            <adapt-select-organisation [organisation]="destOrganisation"
                                                       (organisationChange)="onDestinationOrganisationChanged($event)"
                                                       [filter]="excludeSourceOrganisation"></adapt-select-organisation>
                        </div>

                        <div class="form-group">
                            <h4>Destination system name</h4>

                            <dx-text-box [showClearButton]="true"
                                         [value]="destSystemName"
                                         (valueChange)="updateSystemName($event)"
                                         placeholder="Enter new system name">
                                <dx-validator>
                                    <dxi-validation-rule type="required"
                                                         message="System name is required"></dxi-validation-rule>
                                </dx-validator>
                            </dx-text-box>

                        </div>
                    </div>

                    <div class="form-group">
                        <ng-container *ngIf="roleAssignments && processMapRoles && processMapRoles.length > 0">
                            <h4>Role Mapping</h4>
                            <div *ngFor="let role of processMapRoles"
                                 class="row mb-2">
                                <ng-container *ngIf="getAssignedRole(role.RoleId) as assignment">
                                    <div class="col-12 col-md-3 align-self-center">{{role.Label}}</div>
                                    <dx-radio-group class="col-md align-self-end"
                                                    [items]="RoleActionSelection"
                                                    [value]="assignment.DestinationRoleName === undefined ? RoleAction.Existing : RoleAction.CreateNew"
                                                    (onValueChanged)="updateAssignment(assignment, $event.value ?? RoleAction.Existing)"
                                                    (onInitialized)="updateAssignment(assignment, $event.component!.option('value'))"
                                                    layout="horizontal"
                                                    valueExpr="value"
                                                    displayExpr="text"></dx-radio-group>
                                    <div class="col-12 col-md align-self-center">
                                        <adapt-select-role *ngIf="assignment.DestinationRoleName === undefined"
                                                           [roleId]="assignment.DestinationRoleId"
                                                           (roleChange)="updateAssignedRole(assignment, $event)"
                                                           [organisation]="destOrganisation"
                                                           [filter]="roleFilter"></adapt-select-role>
                                        <dx-text-box *ngIf="assignment.DestinationRoleName !== undefined"
                                                     [showClearButton]="true"
                                                     [value]="assignment.DestinationRoleName"
                                                     (valueChange)="updateAssignedRoleName(assignment, $event)"
                                                     placeholder="Enter new role name">
                                            <dx-validator>
                                                <dxi-validation-rule type="required"
                                                                     message="Role name is required"></dxi-validation-rule>
                                            </dx-validator>
                                        </dx-text-box>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div *ngIf="isComplete"
                     class="form-group">
                    <adapt-check-my-maths (isMyMathsOk)="isMyMathsOk=$event">
                    </adapt-check-my-maths>
                </div>
            </div>
        </div>

        <button [adaptBlockingClick]="promiseToCopySystem"
                [disabled]="!isMyMathsOk || !isComplete"
                adaptButton="copy"> System</button>

        <div *ngIf="error"
             role="alert"
             class="alert alert-danger">{{error}}</div>
    </form>
</div>
