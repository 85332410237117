<div class="check-my-maths-directive">
    <h4>Solve some maths for me - to prove you really want to do this!</h4>
    <div>What is the answer to: {{question}}</div>
    <dx-number-box type="number"
                   [min]="0"
                   [max]="9999"
                   [width]="200"
                   [(value)]="response"
                   (onValueChanged)="processChange()">
        <dx-validator>
            <dxi-validation-rule type="required">
            </dxi-validation-rule>
        </dx-validator>
    </dx-number-box>
</div>
