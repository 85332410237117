import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Practitioner } from "@common/ADAPT.Common.Model/practitioner/practitioner";
import validationEngine from "devextreme/ui/validation_engine";
import { CoachService } from "../coach.service";

@Component({
    selector: "adapt-select-coach",
    templateUrl: "./select-coach.component.html",
})
export class SelectCoachComponent implements OnChanges {
    public readonly validationGroup = "required";

    public items: Practitioner[] = [];

    @Input() public person?: Person;
    @Output() public personChange = new EventEmitter<Person>();

    @Input() public coach?: Practitioner;
    @Output() public coachChange = new EventEmitter<Practitioner>();

    @Input() public required = false;

    constructor(private coachService: CoachService) {}

    public async ngOnChanges(changes: SimpleChanges) {
        this.coachService.getAllCoaches().subscribe((coaches) => {
            this.items = coaches.filter((coach) => coach.isActive());
        });

        if (changes.person && !this.coach) {
            this.coach = this.items.find((i) => i.person === this.person);
        }

        // we need to manually manage validation for the dxSelectBox when using itemTemplate
        // otherwise validation doesn't work.
        // make sure validation group exists first
        if (changes.required && validationEngine.getGroupConfig(this.validationGroup)) {
            // update validation on the validation group
            validationEngine.validateGroup(this.validationGroup);
        }
    }

    public itemChange(selection: Practitioner) {
        this.coachChange.emit(selection);
        this.personChange.emit(selection?.person);

        validationEngine.validateGroup(this.validationGroup);
    }
}
