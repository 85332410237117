import { Component, Injector, OnInit } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { OrganisationsService } from "app/features/organisations/organisations.service";
import { NimbusDataService } from "app/services/nimbus-data.service";
import { dxTabPanelItem } from "devextreme/ui/tab_panel";
import { forkJoin } from "rxjs";
import { PeopleService } from "../people.service";

@Component({
    selector: "adapt-person-page",
    templateUrl: "./person-page.component.html",
})
export class PersonPageComponent extends BaseRoutedComponent implements OnInit {
    private static TabParam = "tab";
    public items = [
        {
            title: "General",
            template: "generalTemplate",
        },
        {
            title: "Account Management",
            template: "accountTemplate",
        },
        {
            title: "Auth Audit",
            template: "authTemplate",
        },
        {
            title: "Data Audit",
            template: "dataTemplate",
        },
    ];

    public person?: Person;
    public selectedTab = this.items[0];

    constructor(
        private peopleService: PeopleService,
        private nimbusDataService: NimbusDataService,
        private organisationsService: OrganisationsService,
        injector: Injector,
    ) {
        super(injector);
    }

    public async ngOnInit() {
        const personId = this.routeService.getRouteParamInt("personId");

        forkJoin([
            this.peopleService.getPersonById(personId!),
            this.nimbusDataService.getConnections(),
            this.nimbusDataService.getPractitioners(),
            this.organisationsService.getOrganisations(),
            this.organisationsService.getOrganisationSupplementaryData(),
        ]).subscribe(([person]) => this.person = person);

        this.notifyActivated();
    }

    public onContentReady() {
        const tabTitle = this.routeService.getSearchParameterValue(PersonPageComponent.TabParam);
        const tab = this.items.find((i) => i.title === tabTitle);
        if (tab) {
            this.selectedTab = tab;
        }
    }

    public onSelectedItemChanged(item: dxTabPanelItem) {
        const tabName = this.routeService.getSearchParameterValue(PersonPageComponent.TabParam);
        if (tabName !== item.title) {
            this.setSearchParameterValue(PersonPageComponent.TabParam, item.title);
        }
    }
}
