import { Component, Injector, OnInit } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { ValueStream } from "@common/ADAPT.Common.Model/organisation/value-stream";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { OrganisationsService } from "app/features/organisations/organisations.service";
import { lastValueFrom } from "rxjs";
import { IEndedRole, StakeholderServicesService } from "../stakeholder-services.service";

@Component({
    selector: "adapt-archive-value-stream-page",
    templateUrl: "./archive-value-stream-page.component.html",
})
export class ArchiveValueStreamPageComponent extends BaseRoutedComponent implements OnInit {
    public organisation?: Organisation;
    public valueStream?: ValueStream;
    public endDate?: Date;
    public error?: string;

    public constructor(
        private commonDialogService: AdaptCommonDialogService,
        private organisationsService: OrganisationsService,
        private stakeholderServicesService: StakeholderServicesService,
        injector: Injector,
    ) {
        super(injector);
    }

    public ngOnInit() {
        this.notifyActivated();
    }

    @Autobind
    public async archive() {
        if (!this.organisation || !this.valueStream || !this.endDate) {
            return await lastValueFrom(this.commonDialogService.showMessageDialog("Error", "Please fill in all fields"));
        }

        const dialogData: IConfirmationDialogData = {
            title: "Archive Value Stream",
            message: "Are you sure you want to archive this value stream?",
            confirmButtonText: "Archive",
        };

        const confirmed = await lastValueFrom(this.commonDialogService.openConfirmationDialogWithBoolean(dialogData));
        if (confirmed) {
            try {
                const roles = await this.doSave();
                await this.notify(roles);
            } catch (e: any) {
                this.error = e;
            }
        }
    }

    private doSave() {
        const viewModel = {
            valueStreamId: this.valueStream!.valueStreamId,
            endDate: this.endDate!.toISOString(),
        };

        try {
            return this.stakeholderServicesService.archiveValueStream(viewModel);
        } catch (e) {
            const message = ErrorHandlingUtilities.getHttpResponseMessage(e);
            return Promise.reject(message);
        }
    }

    @Autobind
    private async notify(roles: IEndedRole[]) {
        this.error = undefined;
        this.valueStream = undefined;
        this.endDate = undefined;

        const message = await this.generateArchiveMessage(roles);
        return await lastValueFrom(this.commonDialogService.showMessageDialog("Success!", message));
    }

    private async generateArchiveMessage(endedRoles: IEndedRole[]) {
        const organisations: Organisation[] = await lastValueFrom(this.organisationsService.getOrganisations());

        const messages = endedRoles.map((role) => this.generateRoleMessage(role, organisations));
        if (messages.length) {
            const messageList = `<ul>${messages.join("\n")}</ul>`;
            return "The following roles were also archived as the only tasks they were assigned to"
                + " were in the archived value stream."
                + messageList;
        } else {
            return "The value stream was archived.";
        }
    }

    private generateRoleMessage(role: IEndedRole, organisations: Organisation[]) {
        const roleOrg = organisations.find((o) => o.organisationId === role.OrganisationId)!;
        const roleUrl = `${AdaptClientConfiguration.FrontEndBaseUri}/${roleOrg.urlIdentifier}/architecture/roles/${role.RoleId}`;

        return `<li>${role.Label} - <a href=${roleUrl}>${roleUrl}</a></li>`;
    }
}
