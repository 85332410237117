import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";

export class Partner extends BaseEntity<Partner> {
    public partnerId!: number;
    public name!: string;
    public defaultSubscriptionTemplate?: string;
    public notes?: string;
}

export const PartnerBreezeModel = new BreezeModelBuilder("Partner", Partner)
    .hasSource()
    .withIdField()
    .build();
