import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AdaptCommonUserModule } from "@common/user/adapt-common-user.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptOrganisationsSharedModule } from "../organisations/organisations-shared.module";
import { LinkPersonComponent } from "./link-person/link-person.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        AdaptButtonModule,
        AdaptCommonUserModule,
        AdaptOrganisationsSharedModule,
        AdaptLoadingSpinnerModule,
        AdaptTooltipModule,
    ],
    exports: [
        LinkPersonComponent,
    ],
    declarations: [
        LinkPersonComponent,
    ],
})
export class AdaptPeopleSharedModule {
}
