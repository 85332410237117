import { Component, Injector, OnInit } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { lastValueFrom } from "rxjs";
import { StakeholderServicesService } from "../stakeholder-services.service";

interface IOrphanPerson {
    Id: number;
    Value: string;
}

@Component({
    selector: "adapt-delete-orphan-people",
    templateUrl: "./delete-orphan-people-page.component.html",
})
export class DeleteOrphanPeopleComponent extends BaseRoutedComponent implements OnInit {
    public people?: IOrphanPerson[];
    public error?: string;

    public constructor(
        private stakeholderServicesService: StakeholderServicesService,
        private commonDialogService: AdaptCommonDialogService,
        injector: Injector,
    ) {
        super(injector);
    }

    public ngOnInit() {
        this.notifyActivated();
    }

    @Autobind
    public async doCheck() {
        this.error = undefined;

        try {
            this.people = await this.stakeholderServicesService.getOrphanPeople();
        } catch (e) {
            this.error = ErrorHandlingUtilities.getHttpResponseMessage(e);
        }
    }

    @Autobind
    public async doDelete() {
        this.error = undefined;

        try {
            await this.stakeholderServicesService.deleteOrphanPeople();
            await lastValueFrom(this.commonDialogService.showMessageDialog("Delete Ok", "Orphan People were deleted successfully"));
            this.people = [];
        } catch (e) {
            this.error = ErrorHandlingUtilities.getHttpResponseMessage(e);
        }
    }
}
