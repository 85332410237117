import { Component, Injector, OnInit, ViewChild } from "@angular/core";
import { FeatureStatus } from "@common/ADAPT.Common.Model/organisation/feature-status";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { EditOrganisationFeaturesComponent } from "@common/feature/edit-organisation-features/edit-organisation-features.component";
import { FeatureService } from "@common/feature/feature.service";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { switchMap, tap } from "rxjs/operators";

@Component({
    selector: "adapt-configure-organisation-features",
    templateUrl: "./configure-organisation-features.component.html",
})
export class ConfigureOrganisationFeaturesComponent extends BaseRoutedComponent implements OnInit {
    public selectedOrganisation?: Organisation;
    public featureStatusesToSave: FeatureStatus[] = [];


    public editFeaturesComponent?: EditOrganisationFeaturesComponent;
    private updater = this.createThrottledUpdater((component: EditOrganisationFeaturesComponent) => this.editFeaturesComponent = component);
    @ViewChild(EditOrganisationFeaturesComponent) public set component(component: EditOrganisationFeaturesComponent) {
        this.updater.next(component);
    }

    constructor(
        private featureService: FeatureService,
        injector: Injector,
    ) {
        super(injector);
    }

    public ngOnInit() {
        this.notifyActivated();
    }

    @Autobind
    public save() {
        return this.entitiesToSave$.pipe(
            switchMap((entities) => this.featureService.saveEntities(entities)),
            tap(() => this.assertedEditFeaturesComponent.intialiseState()),
        );
    }

    @Autobind
    public cancel() {
        return this.entitiesToSave$.pipe(
            switchMap((entities) => this.featureService.rejectChanges(entities)),
            tap(() => this.assertedEditFeaturesComponent.intialiseState()),
        );
    }

    private get entitiesToSave$() {
        return this.assertedEditFeaturesComponent.getEntitiesToSave();
    }

    private get assertedEditFeaturesComponent() {
        if (!this.editFeaturesComponent) {
            throw new Error("EditFeaturesComponent should be defined");
        }

        return this.editFeaturesComponent;
    }
}
