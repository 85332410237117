import { Injectable, Injector } from "@angular/core";
import { AccountBreezeModel } from "@common/ADAPT.Common.Model/account/account";
import { InvoiceBreezeModel } from "@common/ADAPT.Common.Model/account/invoice";
import { ConnectionBreezeModel } from "@common/ADAPT.Common.Model/organisation/connection";
import { Organisation, OrganisationBreezeModel } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Role, RoleBreezeModel } from "@common/ADAPT.Common.Model/organisation/role";
import { SystemEntity, SystemEntityBreezeModel } from "@common/ADAPT.Common.Model/organisation/system-entity";
import { SystemLocation, SystemLocationBreezeModel } from "@common/ADAPT.Common.Model/organisation/system-location";
import { Team, TeamBreezeModel } from "@common/ADAPT.Common.Model/organisation/team";
import { ValueStream, ValueStreamBreezeModel } from "@common/ADAPT.Common.Model/organisation/value-stream";
import { PractitionerBreezeModel } from "@common/ADAPT.Common.Model/practitioner/practitioner";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { BaseService } from "@common/service/base.service";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class NimbusDataService extends BaseService {
    constructor(injector: Injector) {
        super(injector);
    }

    public getAccounts = (forceRemote?: boolean) => this.commonDataService.getAll(AccountBreezeModel, forceRemote);
    public getInvoices = (forceRemote?: boolean) => this.commonDataService.getAll(InvoiceBreezeModel, forceRemote);
    public getConnections = (forceRemote?: boolean) => this.commonDataService.getAll(ConnectionBreezeModel, forceRemote);
    public getPractitioners = (forceRemote?: boolean) => this.commonDataService.getAll(PractitionerBreezeModel, forceRemote);

    public getConnectionsWithoutExpand() {
        return this.commonDataService.getWithOptions(ConnectionBreezeModel, "allConnectionsWithoutExpand", {
            navProperty: undefined,
        });
    }

    public getValueStreamsByOrganisation(organisation: Organisation) {
        const predicate = new MethodologyPredicate<ValueStream>("organisationId", "==", organisation.organisationId);

        return this.commonDataService.getByPredicate(ValueStreamBreezeModel, predicate);
    }

    public getTeamsByOrganisation(organisation: Organisation) {
        const predicate = new MethodologyPredicate<Team>("organisationId", "==", organisation.organisationId);

        return this.commonDataService.getByPredicate(TeamBreezeModel, predicate);
    }

    public getSystemLocationsForTeam(team: Team) {
        const predicate = new MethodologyPredicate<SystemLocation>("teamId", "==", team.teamId);

        return this.commonDataService.getWithOptions(SystemLocationBreezeModel, predicate.getKey(SystemLocationBreezeModel.identifier), {
            predicate,
            navProperty: "system",
        });
    }

    public getSystemsByOrganisation(organisation: Organisation) {
        const predicate = new MethodologyPredicate<SystemEntity>("organisationId", "==", organisation.organisationId);

        return this.commonDataService.getByPredicate(SystemEntityBreezeModel, predicate);
    }

    public getRolesByOrganisation(organisation: Organisation) {
        const predicate = new MethodologyPredicate<Role>("organisationId", "==", organisation.organisationId);

        return this.commonDataService.getWithOptions(RoleBreezeModel, predicate.getKey(RoleBreezeModel.identifier), {
            predicate,
            navProperty: "roleFeaturePermissions,roleType",
        });
    }

    public getOrganisationByUrlIdentifier(urlIdentifier: string) {
        const predicate = new MethodologyPredicate<Organisation>("urlIdentifier", "==", urlIdentifier);

        return this.commonDataService.getByPredicate(OrganisationBreezeModel, predicate).pipe(
            map(ArrayUtilities.getSingleFromArray),
        );
    }
}
