import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { ServiceUri } from "@common/configuration/service-uri";
import { IUserDetailBindingModel } from "@common/identity/user-detail-binding-model.interface";
import { lastValueFrom } from "rxjs";

export interface IEndedRole {
    RoleId: number;
    OrganisationId: number;
    Label: string;
}

export interface IPersonAspNetRole {
    PersonId: number;
    PersonName: string;
    RoleName: string;
}

export interface IImpactTask {
    label: string;
    taskId: number;
}

export interface IAccessToken {
    AccessToken: string;
}

export interface IRegisterOrganisationBindingModel {
    OrganisationName: string;
    UrlIdentifier: string;
}

export interface IAddCoach {
    PersonId?: number;
    PartnerId?: number;
    UserDetails?: IUserDetailBindingModel;
    NewOrganisation?: IRegisterOrganisationBindingModel;
    OrganisationId?: number;
    StartDate: Date;
    EndDate?: Date;
    Notes?: string;
}

export interface IAddCoachResponse {
    PersonId: number;
    PractitionerId: number;
    orgIds: number[];
}

export interface IRoleMapping {
    SourceRoleId: number;
    DestinationRoleId?: number;
    DestinationRoleName?: string;
}

export interface ICopySystem {
    SystemId: number;
    DestinationOrganisationId: number;
    DestinationSystemName?: string;
    RoleIdMapping: IRoleMapping[];
}

export interface ISystemRole {
    Label: string;
    RoleId: number;
}

export interface ICopyTeam {
    TeamId: number;
    DestinationOrganisationId: number;
    DestinationTeamName?: string;
    ExtendedCopy?: boolean;
    RoleIdMapping: IRoleMapping[];
}

export interface ICacheStatus {
    Name: string;
    Values: { Id: string; Value: any }[];
}

export interface ICopyMeetingTemplate {
    MeetingAgendaTemplateId: number,
    DestinationOrganisationId: number,
    DestinationTeamId?: number,
}

@Injectable()
export class StakeholderServicesService {
    public static readonly Id = "ADAPT.Nimbus.StakeholderServices.Service";

    public seedDatabase = this.generateServiceCallFunction("SeedDatabase");
    public seedTrainingOrgs = this.generateServiceCallFunction("SeedTrainingOrganisations");
    public deleteOrganisation = this.generateServiceCallFunction("DeleteOrganisation");
    public getOrphanUsers = this.generateServiceCallFunction("GetOrphanUsers");
    public getOrphanPeople = this.generateServiceCallFunction("GetOrphanPeople");
    public deleteOrphanUsers = this.generateServiceCallFunction("DeleteOrphanUsers");
    public deleteOrphanPeople = this.generateServiceCallFunction("DeleteOrphanPeople");
    public deleteValueStream = this.generateServiceCallFunction("DeleteValueStream");
    public deleteSurvey = this.generateServiceCallFunction("DeleteSurvey");
    public mergeUsers = this.generateServiceCallFunction("MergeUsers");
    public deleteUser = this.generateServiceCallFunction("DeleteUser");
    public updateLogInEmailAddress = this.generateServiceCallFunction("UpdateLogInEmailAddress");
    public archiveValueStream = this.generateServiceCallFunctionWithUnwrap<IEndedRole[]>("ArchiveValueStream");
    public archiveOrganisation = this.generateServiceCallFunction("ArchiveOrganisation");
    public cancelSubscription = this.generateServiceCallFunction("CancelSubscription");
    public resumeTrial = this.generateServiceCallFunction("ResumeTrial");
    public detachPaymentMethod = this.generateServiceCallFunction("DetachPaymentMethod");
    public getCacheStatus = this.generateServiceCallFunction<ICacheStatus[]>("GetCacheStatus");
    public clearCache = this.generateServiceCallFunction("ClearCache");
    public getSpeedCatchupStats = this.generateServiceCallFunction("GetSpeedCatchupStats");
    public resetPassword = this.generateServiceCallFunction("ResetPassword");
    public loginAs = this.generateServiceCallFunction<IAccessToken>("LoginAs");
    public getPrivilegedUserRoles = this.generateServiceCallFunctionWithUnwrap<IPersonAspNetRole[]>("GetPrivilegedUserRoles");
    public getActivationLink = this.generateServiceCallFunction<string>("GetActivationLink");
    public getProcessMapRolesFromSystem = this.generateServiceCallFunction<ISystemRole[]>("GetProcessMapRolesFromSystem");
    public generateAuthAuditTableSas = this.generateServiceCallFunction<string>("GenerateAuthAuditTableSas");
    public generateDataAuditTableSas = this.generateServiceCallFunction<string>("GenerateDataAuditTableSas");
    public getCoachFeedbackLink = this.generateServiceCallFunction<string>("GetCoachFeedbackLink");
    public generatePathwayRatingAuditTableSas = this.generateServiceCallFunction<string>("GeneratePathwayRatingAuditTableSas");

    public constructor(
        private httpClient: HttpClient,
    ) {
    }

    public rerankKanbanItems(org: Organisation) {
        const url = this.generateUrl("RerankKanbanItems");
        return this.httpClient.get<void>(url, {
            params: { organisationId: String(org.organisationId) },
        });
    }

    public createCoach(params: IAddCoach) {
        const url = this.generateUrl("CreateCoach");
        return this.httpClient.post<IAddCoachResponse>(url, params, { responseType: "json" });
    }

    public copySystem(params: ICopySystem) {
        const url = this.generateUrl("CopySystem");
        return this.httpClient.post<number>(url, params, { responseType: "json" });
    }

    public copyTeam(params: ICopyTeam) {
        const url = this.generateUrl("CopyTeam");
        return this.httpClient.post<number>(url, params, { responseType: "json" });
    }

    public copyMeetingTemplate(params: ICopyMeetingTemplate) {
        const url = this.generateUrl("CopyMeetingAgendaTemplate");
        return this.httpClient.post<number>(url, params, { responseType: "json" });
    }

    public triggerSurveySchedulerWebjob() {
        const url = this.generateUrl("TriggerSurveySchedulerWebjob");
        return this.httpClient.post<string>(url, undefined, { responseType: "json" });
    }

    public triggerEventSchedulerWebjob() {
        const url = this.generateUrl("TriggerEventSchedulerWebjob");
        return this.httpClient.post<string>(url, undefined, { responseType: "json" });
    }

    public triggerStewardshipActivityWebjob() {
        const url = this.generateUrl("TriggerStewardshipActivityWebjob");
        return this.httpClient.post<string>(url, undefined, { responseType: "json" });
    }

    public sendCoachFeedbackLink(coachSessionId: number) {
        const url = this.generateUrl("SendCoachFeedbackLink");
        return this.httpClient.post(url, undefined, {
            responseType: "text",
            params: { coachSessionId: String(coachSessionId) },
        });
    }

    private generateServiceCallFunctionWithUnwrap<T = any>(partialUri: string) {
        const serviceCall = this.generateServiceCallFunction<T>(partialUri);
        return (params: { [key: string]: any } = {}) => {
            return serviceCall(params);
        };
    }

    private generateServiceCallFunction<T = any>(partialUri: string) {
        return (params: { [key: string]: any } = {}) => {
            // TODO: still returning a promise here so that I don't have to go through all usage of this to find missing subscribe and change interfaces
            return lastValueFrom(this.httpClient.get<T>(this.generateUrl(partialUri), { params }));
        };
    }

    private generateUrl(partialUrl: string) {
        return `${ServiceUri.StakeholderServicesServiceBaseUri}/${partialUrl}`;
    }
}
