import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { DeepDive, DeepDiveGroupMapping, DeepDiveType, DeepDiveTypeMapping } from "@common/ADAPT.Common.Model/embed/deep-dive";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { UrlUtilities } from "@common/lib/utilities/url-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { IDxCustomRuleValidationCallbackEvent } from "@common/ux/dx.types";
import { DeepDiveService } from "@common/workflow/deep-dive.service";
import { ValueChangedEvent } from "devextreme/ui/radio_group";
import { DxValidationGroupComponent } from "devextreme-angular";
import { tap } from "rxjs/operators";

@Component({
    selector: "adapt-deep-dive-details-dialog",
    templateUrl: "./deep-dive-details-dialog.component.html",
    styleUrls: ["./deep-dive-details-dialog.component.scss"],
})
export class DeepDiveDetailsDialogComponent extends BaseDialogWithDiscardConfirmationComponent<DeepDive> implements OnInit {
    @ViewChild("validationGroup") public validationGroup: DxValidationGroupComponent | undefined;
    public readonly dialogName = "DeepDiveDetails";
    public readonly entitiesToConfirm = [this.deepDive];
    public readonly autoResolveData = this.deepDive;

    public deepDiveGroupMapping = DeepDiveGroupMapping;
    public deepDiveTypeMapping = DeepDiveTypeMapping;
    public deepDiveType = DeepDiveType;
    public isNew: boolean;

    public safeResourceLocation?: SafeResourceUrl;
    public saveDisabled = false;

    private allDeepDives?: DeepDive[];
    private readonly originalLocation: string;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public deepDive: DeepDive,
        private deepDiveService: DeepDiveService,
        private sanitizer: DomSanitizer,
    ) {
        super();
        this.isNew = deepDive.entityAspect.entityState.isAdded();
        this.originalLocation = deepDive.location;
    }

    public ngOnInit() {
        this.deepDiveService.getAllDeepDives().pipe(
            tap((deepDives) => this.allDeepDives = deepDives),
        ).subscribe();

        // sets the video preview URL
        this.onLocationChanged();
    }

    public validateGroup() {
        this.saveDisabled = !this.validationGroup || !this.validationGroup.instance.validate().isValid || this.entitiesAreUnmodifiedOrInvalid;
    }

    public onLocationChanged() {
        this.safeResourceLocation = this.sanitizer.bypassSecurityTrustResourceUrl(this.deepDive.extensions.frameUrl);
        this.validateGroup();
    }

    @Autobind
    public validateLocationIsUnique({ value }: IDxCustomRuleValidationCallbackEvent) {
        if (typeof value === "string") {
            return !this.allDeepDives?.find((dd) => dd.location === value && dd !== this.deepDive);
        }
        return false;
    }

    @Autobind
    public validateLocationIsUrl({ value }: IDxCustomRuleValidationCallbackEvent) {
        if (typeof value === "string") {
            return UrlUtilities.isValidHttpUrl(value);
        }
        return false;
    }

    @Autobind
    public validateGroupIsSelected({ value }: IDxCustomRuleValidationCallbackEvent) {
        return !!value;
    }

    public onTypeChanged(event: ValueChangedEvent) {
        if (event.previousValue !== event.value) {
            this.deepDive.location = event.value === DeepDiveType.Scorm
                ? this.originalLocation
                : "";
        }
    }
}
