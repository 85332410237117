import { Component, Input, OnChanges, ViewChild } from "@angular/core";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { WorkflowConnection } from "@common/ADAPT.Common.Model/organisation/workflow-connection";
import { WorkflowStatus } from "@common/ADAPT.Common.Model/organisation/workflow-status";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { DateFormats } from "@common/ux/date-formats";
import { IDxGridColumnCellInfo } from "@common/ux/dx.types";
import { PersonService } from "app/features/people/person.service";
import { DxDataGridComponent } from "devextreme-angular/ui/data-grid";
import moment from "moment";
import { lastValueFrom } from "rxjs";
import { WorkflowService } from "./workflow.service";

@Component({
    selector: "adapt-pathways",
    templateUrl: "./pathways.component.html",
})
export class PathwaysComponent implements OnChanges {
    @Input() public organisation!: Organisation;
    @ViewChild(DxDataGridComponent) public gridInstance?: DxDataGridComponent;

    public dateFormat = DateFormats.globalize.short;
    public dataList: IExtendedWorkflowStatus[] = [];

    public constructor(
        private workflowService: WorkflowService,
        private personService: PersonService,
    ) { }

    public async ngOnChanges() {
        if (this.organisation) {
            const connections = await lastValueFrom(this.workflowService.getWorkflowConnectionsByOrg(this.organisation.organisationId));
            this.dataList = await this.processConnections(connections);
            this.setDataListDates();
        }
    }

    private async processConnections(connections: WorkflowConnection[]) {
        const allTransformedStatuses = await Promise.all(
            connections.map(async (connection) =>
                await Promise.all(
                    connection.statuses
                        .filter((status) => status.workflowStepId)
                        .map(async (status: IExtendedWorkflowStatus) => {
                            status.workflowId = status.workflowId || connection.workflowId;
                            status.team = connection.team;
                            status.connection = connection.connection;

                            if (status.startedById) {
                                status.startedBy = await lastValueFrom(this.personService.getPerson(status.startedById));
                            }

                            if (status.completedById) {
                                status.completedBy = await lastValueFrom(this.personService.getPerson(status.completedById));
                            }

                            return status;
                        }),
                ),
            ),
        );

        return allTransformedStatuses.flat();
    }

    @Autobind
    public getWorkflowId(data: IDxGridColumnCellInfo) {
        const workflowConnection = this.dataList.find((d) => d.workflowConnectionId === data.value);
        return workflowConnection
            ? `${workflowConnection.workflowId} : ${moment(workflowConnection.startTime).format("DD/MM/YY")}`
            : "unknown";
    }

    public getTeamName(data: IExtendedWorkflowStatus) {
        return data.team?.name;
    }

    private setDataListDates() {
        const earliestStartTimes: { [workflowConnectionId: string]: Date } = {};
        this.dataList.forEach((element) => {
            const { workflowConnectionId, startTime } = element;

            if (startTime) {
                const currentEarliest = earliestStartTimes[workflowConnectionId];
                if (currentEarliest === undefined || moment(startTime).isBefore(moment(currentEarliest))) {
                    earliestStartTimes[workflowConnectionId] = startTime;
                }
            }
        });

        this.dataList.forEach((element) => {
            const { workflowConnectionId, startTime } = element;
            if (startTime === null) {
                element.startTime = earliestStartTimes[workflowConnectionId];
            }
        });
    }

}

export interface IExtendedWorkflowStatus extends WorkflowStatus {
    connection?: Connection;
    team?: Team;
}
