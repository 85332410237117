<adapt-dialog [maxWidth]="800">
    <h3 adapt-dialog-title>Add new coach partner</h3>

    <div adapt-dialog-content>
        <div class="d-flex flex-row justify-content-between mx-4 mb-3">
            <ng-container *ngFor="let stepName of stepNames; let i = index; let last = last">
                <div class="align-self-center d-flex">
                    <span class="align-self-baseline"
                          [ngClass]="{
                              'step-index': i === currentStepIndex,
                              'step-index-disabled': i !== currentStepIndex
                            }">{{i + 1}}</span>
                    <span class="ms-2 align-self-baseline"
                          [ngClass]="i === currentStepIndex ? 'step-text': 'step-text-disabled'">{{stepName}}</span>
                </div>
                <div *ngIf="!last"
                     class="align-self-center d-flex flex-grow-1 step-separator">&nbsp;
                </div>
            </ng-container>
        </div>

        <div role="alert"
             class="alert alert-danger"
             *ngIf="error">
            {{error}}
        </div>

        <div role="alert"
             class="alert alert-primary"
             *ngIf="completed">
            Coach added successfully.
        </div>

        <div class="mt-4 p-3 pt-0"
             [ngSwitch]="currentStep">
            <adapt-new-coach-person *ngSwitchCase="StepName.SetPerson"
                                    [(person)]="person"
                                    (personChange)="onPersonChange($event)"
                                    [(temporaryPerson)]="temporaryPerson"></adapt-new-coach-person>

            <adapt-new-coach-details *ngSwitchCase="StepName.CoachDetails"
                                     [practitioner]="practitioner"></adapt-new-coach-details>

            <adapt-new-coach-sandbox *ngSwitchCase="StepName.SetSandbox"
                                     [(createdOrganisation)]="createdOrganisation"
                                     [(joinOrganisation)]="joinOrganisation"></adapt-new-coach-sandbox>

            <adapt-new-coach-confirmation *ngSwitchCase="StepName.Confirmation"
                                          [completed]="completed"
                                          [person]="person"
                                          [temporaryPerson]="temporaryPerson"
                                          [practitioner]="practitioner"
                                          [createdOrganisation]="createdOrganisation"
                                          [joinOrganisation]="joinOrganisation"></adapt-new-coach-confirmation>
        </div>
    </div>

    <div adapt-dialog-footer
         adaptBlockingButtonGroup
         class="ms-auto">
        <ng-container *ngIf="!completed">
            <button adaptButtonIcon="fal fa-fw fa-arrow-circle-left"
                    (click)="previous()"
                    [disabled]="currentStepIndex < 1">
                Previous
            </button>
            <button adaptButtonIcon="fal fa-fw fa-arrow-circle-right"
                    (click)="next()"
                    [disabled]="!canGoNext || currentStepIndex >= stepNames.length - 1">
                Next
            </button>
            <button adaptButtonIcon="fal fa-fw fa-plus-square"
                    [adaptBlockingClick]="addCoach"
                    *ngIf="isCurrentStep(StepName.Confirmation)">
                Add new coach partner
            </button>
        </ng-container>
        <button adaptButtonIcon="cancel"
                [adaptBlockingClick]="cancel">
            {{completed ? "Close" : "Cancel"}}
        </button>
    </div>
</adapt-dialog>
