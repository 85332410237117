import { Component, Injector, OnInit } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { PeopleService } from "app/features/people/people.service";
import { lastValueFrom } from "rxjs";
import { StakeholderServicesService } from "../stakeholder-services.service";

@Component({
    selector: "adapt-merge-users-page",
    templateUrl: "./merge-users-page.component.html",
})
export class MergeUsersPageComponent extends BaseRoutedComponent implements OnInit {
    public oldPersonId?: number;
    public newPersonId?: number;

    public oldPerson?: Person;
    public newPerson?: Person;

    public isMyMathsOk = false;

    public error?: string;

    public constructor(
        private commonDialogService: AdaptCommonDialogService,
        private stakeholderServicesService: StakeholderServicesService,
        private peopleService: PeopleService,
        injector: Injector,
    ) {
        super(injector);
    }

    public ngOnInit() {
        this.notifyActivated();
    }

    @Autobind
    public async mergeUsers() {
        if (!this.oldPersonId || !this.newPersonId) {
            return lastValueFrom(this.commonDialogService.showMessageDialog("Error", "Select Person Ids!"));
        }

        if (this.oldPersonId === this.newPersonId) {
            return lastValueFrom(this.commonDialogService.showMessageDialog("Error", "Sorry, but merging someone into themselves is just asking for trouble!"));
        }

        if (!this.oldPerson || !this.newPerson) {
            return lastValueFrom(this.commonDialogService.showMessageDialog("Error", "One of these people doesn't exist!"));
        }

        if (!this.isMyMathsOk) {
            return lastValueFrom(this.commonDialogService.showMessageDialog("Error", "Check your maths!"));
        }

        try {
            const data: IConfirmationDialogData = {
                title: "Merge Users", message: "Are you sure you want to merge these users?", confirmButtonText: "Merge", cancelButtonText: "Cancel",
            };
            const confirmed = await lastValueFrom(this.commonDialogService.openConfirmationDialogWithBoolean(data));
            if (confirmed) {
                await this.doMerge();
                await this.notify();
            }
        } catch (error) {
            this.error = ErrorHandlingUtilities.getHttpResponseMessage(error);
        }
    }

    public async updateNewPerson() {
        this.newPerson = this.newPersonId
            ? await lastValueFrom(this.peopleService.getPersonById(this.newPersonId))
            : undefined;
    }

    public async updateOldPerson() {
        this.oldPerson = this.oldPersonId
            ? await lastValueFrom(this.peopleService.getPersonById(this.oldPersonId))
            : undefined;
    }

    private async doMerge() {
        const data = {
            oldPersonId: this.oldPersonId,
            newPersonId: this.newPersonId,
        };

        try {
            return await this.stakeholderServicesService.mergeUsers(data);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    private async notify() {
        this.error = undefined;
        this.oldPersonId = undefined;
        this.newPersonId = undefined;

        return lastValueFrom(this.commonDialogService.showMessageDialog("Success!", "Users were merged!"));
    }
}
