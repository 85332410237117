<div>
    <div role="alert"
         class="alert alert-info">
        Archiving a value stream will preserve all data in embedADAPT, but hide the value stream from the user
    </div>

    <form role="form">
        <div class="row">
            <div class="col-md-6 col-xl-4">
                <div class="form-group">
                    <h4>Organisation</h4>
                    <adapt-select-organisation (organisationChange)="organisation = $event"></adapt-select-organisation>
                </div>
                <div class="form-group">
                    <h4>Value Stream</h4>
                    <adapt-select-value-stream [organisation]="organisation"
                                               [(valueStream)]="valueStream"></adapt-select-value-stream>
                </div>
                <div class="form-group">
                    <h4>End Date</h4>
                    <dx-date-box [value]="endDate"
                                 (valueChange)="endDate=$any($event)"></dx-date-box>
                </div>
            </div>
        </div>
        <button adaptButtonIcon
                [adaptBlockingClick]="archive">Archive value stream</button>

        <div role="alert"
             class="alert alert-danger"
             *ngIf="!!error">{{error}}</div>
    </form>
</div>
