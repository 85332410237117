import { Component, Input, OnChanges } from "@angular/core";
import { Account } from "@common/ADAPT.Common.Model/account/account";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { OrganisationDetailNames } from "@common/ADAPT.Common.Model/organisation/organisation-detail";
import { AdaptClientConfiguration, AdaptProject, AdaptProjectLabel } from "@common/configuration/adapt-client-configuration";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { PeopleService } from "app/features/people/people.service";
import { lastValueFrom } from "rxjs";
import { OrganisationsService } from "../organisations.service";

@Component({
    selector: "adapt-organisation-details",
    templateUrl: "./organisation-details.component.html",
})
export class OrganisationDetailsComponent implements OnChanges {
    @Input() public organisation!: Organisation;
    public account!: Account;
    public embedLink?: string;
    public isEditingDetails = false;
    public error?: string;
    public referrer?: string;

    public organisationTypes = this.buildOrganisationTypes();

    public constructor(
        private commonDataService: CommonDataService,
        private organisationsService: OrganisationsService,
        private peopleService: PeopleService,
    ) { }

    public ngOnChanges() {
        if (!this.organisation) {
            throw new Error("Expected organisation to be defined");
        }

        this.referrer = this.organisation.getDetailValue(OrganisationDetailNames.Referrer);

        this.account = this.organisation.account;
        if (!this.account) {
            throw new Error("Expected account to be defined for organisation");
        }

        this.embedLink = this.organisationsService.getEmbedLinkForOrganisation(this.organisation);

        if (this.account.eulaPersonId) {
            // Prime this as it may not have been fetched yet
            this.peopleService.getPersonById(this.account.eulaPersonId).subscribe();
        }
    }

    public get projectLabel() {
        const project = AdaptClientConfiguration.IsCurrentEmbedApiBaseUri ? AdaptProject.Cumulus : AdaptProject.Alto;
        return AdaptProjectLabel[project];
    }

    public editDetails() {
        this.isEditingDetails = true;
    }

    @Autobind
    public async saveDetails() {
        this.error = undefined;

        try {
            await lastValueFrom(this.commonDataService.save());
            this.moveOutOfEditMode();
        } catch (error) {
            this.error = ErrorHandlingUtilities.getHttpResponseMessage(error);
        }
    }

    @Autobind
    public async cancelDetails() {
        await lastValueFrom(this.commonDataService.cancel());
        this.moveOutOfEditMode();
    }

    private moveOutOfEditMode() {
        this.isEditingDetails = false;
    }

    private buildOrganisationTypes() {
        return [
            {
                id: "Standard",
                name: "Standard",
            },
            {
                id: "Sandbox",
                name: "Sandbox",
            },
            {
                id: "Template",
                name: "Template",
            },
        ];
    }
}
