import { Component, Inject } from "@angular/core";
import { IWorkflowRating, PathwayRatingStatus } from "@common/ADAPT.Common.Model/organisation/workflow-rating";
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { PathwayReviewService } from "@common/workflow/components/pathway-review.service";
import { catchError, EMPTY, tap } from "rxjs";

@Component({
    selector: "adapt-pathway-review-edit",
    templateUrl: "./pathway-rating-edit.component.html",
})
export class PathwayRatingEditComponent extends BaseDialogComponent<IWorkflowRating> {
    public readonly dialogName = "Edit rating";
    public readonly PathwayRatingStatus = PathwayRatingStatus;

    public isEditorValid = true;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public workflowRating: IWorkflowRating,
        public pathwayReviewService: PathwayReviewService,
    ) {
        super();
        if (!workflowRating.comment) {
            this.workflowRating.comment = "";
        }
    }

    public save() {
        this.pathwayReviewService.updateRating(this.workflowRating).pipe(
            tap(() => this.resolve(this.workflowRating)),
            catchError((err: AdaptError) => {
                this.setErrorMessage(err.message);
                return EMPTY;
            }),
        ).subscribe();
    }
}
