import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { DxDataGridModule, DxSelectBoxModule, DxValidatorModule } from "devextreme-angular";
import { AdaptPeopleModule } from "../people/people.module";
import { AdaptPeopleSharedModule } from "../people/people-shared.module";
import { CoachRelationshipsComponent } from "./coach-relationships/coach-relationships.component";
import { LinkCoachComponent } from "./link-coach/link-coach.component";
import { SelectCoachComponent } from "./select-coach/select-coach.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,

        AdaptButtonModule,
        AdaptPeopleModule,
        AdaptPeopleSharedModule,

        DxDataGridModule,
        DxSelectBoxModule,
        DxValidatorModule,
    ],
    exports: [
        CoachRelationshipsComponent,
        LinkCoachComponent,
        SelectCoachComponent,
    ],
    declarations: [
        CoachRelationshipsComponent,
        LinkCoachComponent,
        SelectCoachComponent,
    ],
})
export class AdaptCoachSharedModule {
}
