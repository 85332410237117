import { Component, Input } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Practitioner } from "@common/ADAPT.Common.Model/practitioner/practitioner";
import { INewPersonModel } from "../new-coach.interface";

@Component({
    selector: "adapt-new-coach-confirmation",
    templateUrl: "./new-coach-confirmation.component.html",
    styleUrls: ["./new-coach-confirmation.component.scss"],
})
export class NewCoachConfirmationComponent {
    @Input() public person?: Person;
    @Input() public temporaryPerson?: INewPersonModel;
    @Input() public practitioner!: Practitioner;
    @Input() public createdOrganisation?: Organisation;
    @Input() public joinOrganisation?: Organisation;
    @Input() public completed!: boolean;
}
