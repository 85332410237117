import { Component, Injector } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { loginPageRoute } from "@common/identity/ux/login-page/login-page.route";
import { AngularGlobals } from "@common/lib/angular-globals/angular-globals";
import { GuardFailureType, IGuardFailureEvent, RouteEventsService } from "@common/route/route-events.service";
import { ShellStyleConstants } from "@common/shell/shell-style.constants";
import { filter, switchMap } from "rxjs/operators";
import { landingPageRoute } from "./services/landing-page/landing-page.route";

@Component({
    selector: "adapt-app",
    template: "<adapt-nimbus-shell-wrapper></adapt-nimbus-shell-wrapper>",
})
export class AppComponent {

    constructor(
        injector: Injector,
        private routeEventsService: RouteEventsService,
        private router: Router,
    ) {
        AngularGlobals.injector = injector;

        this.setupRouting();
    }

    private setupRouting() {
        this.setupRoutingSubscriptions();
    }

    private setupRoutingSubscriptions() {
        this.routeEventsService.guardFailureEvents$.pipe(
            switchMap((e) => this.handleGuardFailure(e)),
        ).subscribe();

        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
            )
            .subscribe(() => {
                this.routeChangeSuccessHandler();
            });
    }

    private routeChangeSuccessHandler() {
        // after a route change, force a scroll to the top
        const containerElements = window.document.getElementsByClassName(ShellStyleConstants.MainViewClass);
        if (containerElements.length > 0) {
            containerElements[0].scrollTop = 0;
        }
    }

    private handleGuardFailure(e: IGuardFailureEvent) {
        switch (e.type) {
            case GuardFailureType.AuthenticatedGuardFailed:
                return loginPageRoute.gotoRoute();
            default:
                // everything else go to landing page (e.g. Unauth)
                return landingPageRoute.gotoRoute();
        }
    }
}
