<dx-select-box [value]="person"
               (valueChange)="itemChange($event)"
               [dataSource]="dataSource"
               [showClearButton]="!required"
               displayExpr="fullName"
               itemTemplate="item"
               [searchEnabled]="true"
               [searchExpr]="$any('fullName')"
               placeholder="Select person...">
    <div *dxTemplate="let data of 'item'">
        {{data.fullName}}
    </div>
</dx-select-box>
