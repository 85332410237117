import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { ConnectionTypeLabel } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Role, RoleBreezeModel } from "@common/ADAPT.Common.Model/organisation/role";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IDxSelectBoxValueChangedEvent } from "@common/ux/dx.types";
import { NimbusDataService } from "app/services/nimbus-data.service";
import ArrayStore from "devextreme/data/array_store";
import DataSource, { DataSourceOptions } from "devextreme/data/data_source";
import { lastValueFrom } from "rxjs";

@Component({
    selector: "adapt-select-role",
    templateUrl: "./select-role.component.html",
})
export class SelectRoleComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() public organisation!: Organisation;
    @Input() public role?: Role;
    @Input() public roleId?: number;
    @Input() public filter?: (role: Role) => boolean;

    @Output() public roleChange = new EventEmitter<Role>();

    public dataSource?: DataSource;

    public constructor(
        private commonDataService: CommonDataService,
        private nimbusDataService: NimbusDataService,
    ) {
        super();
    }

    public ngOnInit() {
        this.setDataSource();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.organisation) {
            this.onOrganisationChanged();
        }

        if (changes.filter) {
            this.setDataSource();
        }

        if (changes.roleId) {
            this.onRoleIdChanged({ previousValue: changes.roleId.previousValue, value: this.roleId });
        }
    }

    private async setDataSource() {
        const roles = await this.fetchRoles();

        this.dataSource = new DataSource({
            store: new ArrayStore({
                data: roles,
                key: "roleId",
            }),
            filter: this.filter,
            group: (role) => role.connectionType
                ? `${ConnectionTypeLabel.singular(role.connectionType)} Roles`
                : undefined,
        } as DataSourceOptions<Role>);
    }

    private fetchRoles() {
        if (!this.organisation) {
            throw new Error("Organisation must be set");
        }

        return lastValueFrom(this.nimbusDataService.getRolesByOrganisation(this.organisation));
    }

    private onOrganisationChanged() {
        this.role = undefined;
        this.roleId = undefined;

        this.roleChange.emit(this.role);

        this.setDataSource();
    }

    public async onRoleIdChanged(e: IDxSelectBoxValueChangedEvent<number>) {
        if (e.value === e.previousValue) {
            return;
        }

        if (e.value) {
            this.role = await lastValueFrom(this.commonDataService.getById(RoleBreezeModel, e.value));
        } else {
            this.role = undefined;
            this.roleId = undefined;
        }

        this.roleChange.emit(this.role);
    }
}
