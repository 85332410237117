import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { Feature, FeatureModule } from "@common/ADAPT.Common.Model/embed/feature";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { IGroupedData } from "@common/lib/utilities/grouped-data.interface";
import { combineLatest, Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { FeaturePermissionTranslatorService } from "../feature-permission-translator.service";

interface IFeatureRow {
    feature: Feature;
    isEnabled: boolean;
}

interface IModuleRow {
    module: FeatureModule;
    featureRows: IFeatureRow[];
    totalFeatureCount: number;
    enabledFeatureCount: number;
}

@Component({
    selector: "adapt-select-features",
    templateUrl: "./select-features.component.html",
    styleUrls: ["./select-features.component.scss"],
    encapsulation: ViewEncapsulation.None, // So we can override DX styles
})
export class SelectFeaturesComponent {
    @Input() public set availableFeatures(value: Feature[]) {
        this.availableFeatures$.next(value);
    }
    @Input() public set selectedFeatures(value: Feature[]) {
        this.selectedFeatures$.next(value);
    }
    @Output() public featureAdded = new EventEmitter<Feature>();
    @Output() public featureRemoved = new EventEmitter<Feature>();

    public accordionData$: Observable<IModuleRow[]>;

    private availableFeatures$ = new Subject<Feature[]>();
    private selectedFeatures$ = new Subject<Feature[]>();

    public constructor(
        public translatorService: FeaturePermissionTranslatorService,
    ) {
        this.accordionData$ = combineLatest([this.availableFeatures$, this.selectedFeatures$]).pipe(
            map((v) => {
                const featureRows = v[0].map((f) => ({
                    feature: f,
                    isEnabled: v[1].indexOf(f) >= 0,
                }));

                const groupedFeatures = ArrayUtilities.groupArrayBy(featureRows, (r) => r.feature.featureModule);
                const moduleRows = groupedFeatures.map(this.createModuleRowFromFeatureGroup);
                return moduleRows;
            }),
        );
    }

    private createModuleRowFromFeatureGroup(featureGroup: IGroupedData<FeatureModule, IFeatureRow>): IModuleRow {
        return {
            module: featureGroup.key,
            featureRows: featureGroup.items,
            totalFeatureCount: featureGroup.items.length,
            enabledFeatureCount: featureGroup.items.filter((i) => i.isEnabled).length,
        };
    }

    public featureStatusChanged(module: IModuleRow, feature: Feature, isEnabled: boolean) {
        if (isEnabled) {
            module.enabledFeatureCount++;
            this.featureAdded.emit(feature);
        } else {
            module.enabledFeatureCount--;
            this.featureRemoved.emit(feature);
        }
    }
}
