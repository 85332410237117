import { HttpErrorResponse } from "@angular/common/http";
import { Component, Injector, OnInit } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { catchError, tap } from "rxjs/operators";
import { StakeholderServicesService } from "../stakeholder-services.service";

@Component({
    selector: "adapt-trigger-event-scheduler",
    templateUrl: "./trigger-event-scheduler.component.html",
})
export class TriggerEventSchedulerComponent extends BaseRoutedComponent implements OnInit {
    public eventWebjobTriggerResult = "";

    public constructor(
        private stakeholderServices: StakeholderServicesService,
        injector: Injector,
    ) {
        super(injector);
    }

    public ngOnInit() {
        this.notifyActivated();
    }

    @Autobind
    public triggerWebjob() {
        return this.stakeholderServices.triggerEventSchedulerWebjob().pipe(
            tap((result) => this.eventWebjobTriggerResult = result),
            catchError((error: HttpErrorResponse) => this.eventWebjobTriggerResult = error.error),
        );
    }
}
