import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { CoachSession } from "@common/ADAPT.Common.Model/organisation/coach-session";
import { CoachSessionStatus } from "@common/ADAPT.Common.Model/organisation/coach-session-status";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IAdaptMenuItem, MenuComponent } from "@common/ux/menu/menu.component";
import { finalize, from, of, switchMap } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { StakeholderServicesService } from "../../tools/stakeholder-services.service";
import { EditCoachSessionDialogComponent } from "../edit-coach-session-dialog/edit-coach-session-dialog.component";

@Component({
    selector: "adapt-coach-session-actions",
    templateUrl: "./coach-session-actions.component.html",
})
export class CoachSessionActionsComponent implements OnChanges {
    @Input({ required: true }) public coachSession!: CoachSession;

    @Output() public updated = new EventEmitter<void>();

    public menuItems: IAdaptMenuItem[] = [];

    public constructor(
        private dialogService: AdaptCommonDialogService,
        private stakeholderServices: StakeholderServicesService,
    ) {
    }

    public ngOnChanges() {
        const items: IAdaptMenuItem[] = [{
            text: "Edit",
            onClick: () => this.editCoachSession(this.coachSession).subscribe(),
        }];

        if (this.coachSession.status === CoachSessionStatus.Complete
            // show when feedback requested so they can re-send the email
            || this.coachSession.status === CoachSessionStatus.FeedbackRequested) {
            items.push({
                text: "Copy feedback link",
                onClick: () => this.copyFeedbackLink(this.coachSession).subscribe(),
            }, {
                text: "Send feedback form",
                onClick: () => this.sendFeedbackForm(this.coachSession).subscribe(),
            });
        }

        this.menuItems = [{
            icon: MenuComponent.SmallRootMenu.icon,
            items,
        }] as IAdaptMenuItem[];
    }

    private editCoachSession(coachSession: CoachSession) {
        return this.dialogService.open(EditCoachSessionDialogComponent, coachSession).pipe(
            finalize(() => this.updated.emit()),
        );
    }

    private copyFeedbackLink(coachSession: CoachSession) {
        return from(this.stakeholderServices.getCoachFeedbackLink({ coachSessionId: coachSession.coachSessionId })).pipe(
            switchMap(async (link) => {
                await navigator.clipboard.writeText(link);
                return link;
            }),
            switchMap((link) => this.dialogService.showMessageDialog("Success", `
                <p>Feedback link copied to clipboard.</p>
                <p><strong>NOTE:</strong> You must set the status of the session to "Feedback Requested", else this link will not work.</p>
                <p>Link: <a href="${link}">${link}</a></p>`)),
            catchError((e) => {
                const error = ErrorHandlingUtilities.getHttpResponseMessage(e);
                return this.dialogService.showMessageDialog("Error", `Failed getting feedback link for CoachSessionId=${coachSession.coachSessionId}: ${error}`);
            }),
        );
    }

    private sendFeedbackForm(coachSession: CoachSession) {
        const alreadySentPrompt = coachSession.status === CoachSessionStatus.FeedbackRequested
            ? this.dialogService.openConfirmationDialog({
                title: "Confirm coaching session feedback request",
                message: "You have already requested feedback for this coaching session. Please confirm you want to send the email again.",
                confirmButtonText: "Send email again",
            })
            : of(undefined);

        return alreadySentPrompt.pipe(
            switchMap(() => this.stakeholderServices.sendCoachFeedbackLink(coachSession.coachSessionId)),
            switchMap(() => this.dialogService.showMessageDialog("Success", `Feedback link sent for CoachSessionId=${coachSession.coachSessionId}.`)),
            catchError((e) => {
                const error = ErrorHandlingUtilities.getHttpResponseMessage(e);
                return this.dialogService.showMessageDialog("Error", `Failed sending feedback link for CoachSessionId=${coachSession.coachSessionId}: ${error}`);
            }),
            tap(() => this.updated.emit()),
        );
    }
}
