import { Component, Injector, OnInit } from "@angular/core";
import { Partner, PartnerBreezeModel } from "@common/ADAPT.Common.Model/partner/partner";
import { Practitioner } from "@common/ADAPT.Common.Model/practitioner/practitioner";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { EditSimpleValueBreezeEntityDialogComponent, IEditSimpleValueBreezeEntityDialogData, SimpleValueType } from "@common/ux/edit-simple-value-breeze-entity-dialog/edit-simple-value-breeze-entity-dialog.component";
import { catchError, EMPTY, forkJoin, of, switchMap, tap } from "rxjs";
import { map } from "rxjs/operators";
import { PartnerService } from "../partner.service";

interface IPartnerListData {
    partner: Partner;
    coaches: Practitioner[];
}

@Component({
    selector: "adapt-partner-list-page",
    templateUrl: "./partner-list-page.component.html",
})
export class PartnerListPageComponent extends BaseRoutedComponent implements OnInit {
    public partners: IPartnerListData[] = [];

    constructor(
        injector: Injector,
        private partnerService: PartnerService,
        private dialogService: AdaptCommonDialogService,
        private commonDataService: CommonDataService,
    ) {
        super(injector);
    }

    public ngOnInit() {
        this.updateData();
        this.notifyActivated();
    }

    @Autobind
    public addNewPartner() {
        return this.commonDataService.create(PartnerBreezeModel, {}).pipe(
            switchMap((partner) => this.openEditPartnerDialog(partner)),
            tap(() => this.updateData()),
        );
    }

    @Autobind
    public editPartner(partner: Partner) {
        this.openEditPartnerDialog(partner).pipe(
            tap(() => this.updateData()),
        ).subscribe();
    }

    private openEditPartnerDialog(partner: Partner) {
        const dlg: IEditSimpleValueBreezeEntityDialogData = {
            title: "Add/Edit Strategic Partner",
            entities: [{
                label: "Name",
                entity: partner,
                fieldName: "name",
                type: SimpleValueType.Text,
            }, {
                label: "Notes",
                entity: partner,
                fieldName: "notes",
                type: SimpleValueType.RichText,
            }],
            saveOnClose: true,
        };

        return this.dialogService.open(EditSimpleValueBreezeEntityDialogComponent, dlg);
    }

    @Autobind
    public deletePartner(partner: Partner) {
        this.commonDataService.remove(partner).pipe(
            switchMap(this.commonDataService.save),
            tap(() => this.updateData()),
            catchError((e) => {
                this.commonDataService.cancel().subscribe();
                const msg = ErrorHandlingUtilities.getHttpResponseMessage(e);
                alert("Error deleting partner - likely due to the partner being in use - remove the partner from coach first.\n\n" + msg);
                return EMPTY;
            }),
        ).subscribe();
    }

    private updateData() {
        this.partnerService.getAllPartners().pipe(
            switchMap((partners) => {
                const getCoachesRequests = partners.map((partner) => {
                    return this.partnerService.getAllCoachesForPartner(partner).pipe(
                        map((coaches) => ({ partner, coaches } as IPartnerListData)),
                    );
                });
                return getCoachesRequests.length > 0
                    ? forkJoin(getCoachesRequests)
                    : of(partners.map((partner) => ({ partner, coaches: [] } as IPartnerListData)));
            }),
        ).subscribe((partners) => {
            this.partners = partners;
        });
    }
}
