<div>
    <div role="alert"
         class="alert alert-info">
        Delete a Value Stream - and ALL associated data.
    </div>

    <div role="alert"
         class="alert alert-warning">
        <h3>*** Note: This operation is not recoverable. ***</h3>
    </div>

    <form role="form">
        <div class="row">
            <div class="col-12 col-lg-8">
                <div class="form-group">
                    <h4>Organisation</h4>
                    <adapt-select-organisation [(organisation)]="organisation"></adapt-select-organisation>
                </div>
                <div class="form-group">
                    <h4>Value Stream</h4>
                    <adapt-select-value-stream [organisation]="organisation"
                                               [(valueStream)]="valueStream"></adapt-select-value-stream>
                </div>
                <div class="form-group">
                    <adapt-check-my-maths (isMyMathsOk)="isMyMathsOk = $event">
                    </adapt-check-my-maths>
                </div>
            </div>
        </div>
        <button adaptButton="delete"
                [adaptBlockingClick]="deleteValueStream"> Value Stream</button>
        <div *ngIf="error"
             role="alert"
             class="alert alert-danger">{{error}}</div>
    </form>
</div>
