<div class="copy-team-component">
    <div role="alert"
         class="alert alert-info">
        Copy a team from one organisation to another.
        <br />
        This will copy the following entities:
        <ul>
            <li>Feature Status</li>
            <li>Purpose/Guidance</li>
            <li>Team Locations (zones)</li>
            <li>Values</li>
            <li>Meetings: Agenda Templates, Guidance</li>
            <li>Systems</li>
            <li>Labels</li>
        </ul>

        If Extended Copy is selected, the following entities will also be copied:
        <ul>
            <li>Actions: Boards, Actions, Comments, Links, Labels</li>
            <li>Meetings: Meetings, Attendees, Agenda Items, Notes, Item Links</li>
            <li>Objectives: Objectives, Key Results, Key Result Values, Comments, Links, Item Links, Labels</li>
        </ul>

        <p>
            <b class="d-block">Things NOT copied.</b>
        </p>
        <ul>
            <li>Team members. Please add the team members using the Configure Team page after the copy.</li>
            <li>Team Assessment</li>
            <li>PCU</li>
            <li>Linked Task Maps</li>
            <li>Role Task Labels</li>
        </ul>

        <p>
            <b class="d-block">Please make sure the people within the team are within the destination organisation.</b>
            If not, attendees, assignees and commenters may not show correctly.
            You can add the people to the organisation before/after the copy, which will resolve this.
        </p>
    </div>

    <form role="form">
        <div class="row">
            <div class="col-12 col-lg-8">
                <div class="form-group">
                    <h4>Source Organisation</h4>
                    <adapt-select-organisation (organisationChange)="sourceOrganisation = $event"></adapt-select-organisation>
                </div>

                <div *ngIf="sourceOrganisation">
                    <div class="form-group">
                        <h4>Team</h4>
                        <adapt-select-team [organisation]="sourceOrganisation"
                                           (teamChange)="onTeamChanged($event)"></adapt-select-team>
                    </div>

                    <div *ngIf="team">
                        <div class="form-group">
                            <h4>Destination Organisation</h4>
                            <adapt-select-organisation [organisation]="destOrganisation"
                                                       (organisationChange)="onDestinationOrganisationChanged($event)"
                                                       [filter]="excludeSourceOrganisation"></adapt-select-organisation>
                        </div>

                        <div class="form-group">
                            <h4>Destination Team Name</h4>

                            <dx-text-box [showClearButton]="true"
                                         [value]="destTeamName"
                                         (valueChange)="updateTeamName($event)"
                                         placeholder="Enter new team name">
                                <dx-validator>
                                    <dxi-validation-rule type="required"
                                                         message="Team name is required"></dxi-validation-rule>
                                </dx-validator>
                            </dx-text-box>

                        </div>

                        <div class="form-group">
                            <h4>Extended Copy</h4>

                            <dx-check-box text="Do extended copy"
                                          [value]="extendedCopy"
                                          (valueChange)="extendedCopy = $any($event)"></dx-check-box>
                        </div>
                    </div>

                    <div class="form-group">
                        <ng-container *ngIf="roleAssignments && processMapRoles && processMapRoles.length > 0">
                            <h4>Role Mapping</h4>
                            <div *ngFor="let role of processMapRoles"
                                 class="row mb-2">
                                <ng-container *ngIf="getAssignedRole(role.RoleId) as assignment">
                                    <div class="col-12 col-md-3 align-self-center">{{role.Label}}</div>
                                    <dx-radio-group class="col-md align-self-end"
                                                    [items]="RoleActionSelection"
                                                    [value]="assignment.DestinationRoleName === undefined ? RoleAction.Existing : RoleAction.CreateNew"
                                                    (onValueChanged)="updateAssignment(assignment, $event.value ?? RoleAction.Existing)"
                                                    (onInitialized)="updateAssignment(assignment, $event.component!.option('value'))"
                                                    layout="horizontal"
                                                    valueExpr="value"
                                                    displayExpr="text"></dx-radio-group>
                                    <div class="col-12 col-md align-self-center">
                                        <adapt-select-role *ngIf="assignment.DestinationRoleName === undefined"
                                                           [roleId]="assignment.DestinationRoleId"
                                                           (roleChange)="updateAssignedRole(assignment, $event)"
                                                           [organisation]="destOrganisation"
                                                           [filter]="roleFilter"></adapt-select-role>
                                        <dx-text-box *ngIf="assignment.DestinationRoleName !== undefined"
                                                     [showClearButton]="true"
                                                     [value]="assignment.DestinationRoleName"
                                                     (valueChange)="updateAssignedRoleName(assignment, $event)"
                                                     placeholder="Enter new role name">
                                            <dx-validator>
                                                <dxi-validation-rule type="required"
                                                                     message="Role name is required"></dxi-validation-rule>
                                            </dx-validator>
                                        </dx-text-box>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div *ngIf="isComplete"
                     class="form-group">
                    <adapt-check-my-maths (isMyMathsOk)="isMyMathsOk=$event">
                    </adapt-check-my-maths>
                </div>
            </div>
        </div>

        <button [adaptBlockingClick]="promiseToCopyTeam"
                [disabled]="!isMyMathsOk || !isComplete"
                adaptButton="copy"> Team
        </button>

        <div *ngIf="error"
             role="alert"
             class="alert alert-danger">{{error}}</div>
    </form>
</div>
