import { Component, EventEmitter, Output } from "@angular/core";
import { BaseComponent } from "@common/ux/base.component/base.component";

@Component({
    selector: "adapt-check-my-maths",
    templateUrl: "./check-my-maths.component.html",
    styleUrls: ["./check-my-maths.component.scss"],
})
export class CheckMyMathsComponent extends BaseComponent {
    @Output() public isMyMathsOk = new EventEmitter<boolean>();

    public question: string;
    public answer: number;

    public response: number = 0;

    public constructor() {
        super();

        const part1 = Math.floor(Math.random() * 100);
        const part2 = Math.floor(Math.random() * 100);

        this.question = `${part1} + ${part2}`;
        this.answer = part1 + part2;
    }

    public processChange() {
        const correct = this.response === this.answer;
        this.isMyMathsOk.next(correct);
    }
}
