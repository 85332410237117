import moment from "moment";

export class AuthAuditFilterBuilder {
    public auditFIlter: string = "true";

    public setStartDate(startDate: Date): AuthAuditFilterBuilder {
        const startTime = moment(startDate).startOf("day").toISOString();
        this.auditFIlter += ` and Timestamp gt datetime'${startTime}'`;
        return this;
    }

    public setEndDate(endDate: Date): AuthAuditFilterBuilder {
        const endTime = moment(endDate).endOf("day").toISOString();
        this.auditFIlter += ` and Timestamp lt datetime'${endTime}'`;
        return this;
    }

    public setUserName(userName: string): AuthAuditFilterBuilder {
        if (userName !== "") {
            this.auditFIlter += ` and PartitionKey eq '${userName}'`;
        }
        return this;
    }

    public setActions(login: boolean, logout: boolean): AuthAuditFilterBuilder {
        let actionFilter = "false";
        if (login) {
            actionFilter = `${actionFilter} or Action eq 'Login'`;
        }
        if (logout) {
            actionFilter = `${actionFilter} or Action eq 'Logout'`;
        }
        this.auditFIlter += ` and (${actionFilter})`;
        return this;
    }

    public setResults(success: boolean, failure: boolean): AuthAuditFilterBuilder {
        let resultFilter = "false";
        if (success) {
            resultFilter = `${resultFilter} or Result eq 'Success'`;
        }
        if (failure) {
            resultFilter = `${resultFilter} or Result eq 'Failure'`;
        }
        this.auditFIlter += ` and (${resultFilter})`;
        return this;
    }

    public setReasons(other: boolean, unknownUsr: boolean, incorrectPswd: boolean): AuthAuditFilterBuilder {
        let reasonFilter = "";
        if (other) {
            reasonFilter = "true";
            if (!unknownUsr) {
                reasonFilter = `${reasonFilter} and Reason ne 'Unknown user'`;
            }
            if (!incorrectPswd) {
                reasonFilter = `${reasonFilter} and Reason ne 'Incorrect password'`;
            }
        }
        else {
            reasonFilter = "false";
            if (unknownUsr) {
                reasonFilter = `${reasonFilter} or Reason eq 'Unknown user'`;
            }
            if (incorrectPswd) {
                reasonFilter = `${reasonFilter} or Reason eq 'Incorrect password'`;
            }
        }

        this.auditFIlter += ` and (${reasonFilter})`;
        return this;
    }

    public build(): string {
        return this.auditFIlter;
    }
}
