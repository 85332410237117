import { Component, Input } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { AuditService, IDataAudit, IDataFilters } from "../audit.service";

@Component({
    selector: "adapt-data-audit",
    templateUrl: "./data-audit.component.html",
})
export class DataAuditComponent {
    @Input() public person?: Person;
    @Input() public organisation?: Organisation;
    @Input() public showUserFilter?: boolean;
    @Input() public showOrgFilter?: boolean;

    public startDate = new Date();
    public endDate = new Date();
    public dataList: IDataAudit[] = [];

    public changeTypeAdded = true;
    public changeTypeDeleted = true;
    public changeTypeModified = true;

    public dataFilter = "";
    public entIdFilter = "";
    public entTypeFilter = "";

    constructor(
        private auditService: AuditService,
    ) { }

    @Autobind
    public async getDataResults() {
        this.dataList = [];
        const personId = this.person?.personId ?? 0;
        const organisationId = this.organisation?.organisationId ?? 0;

        const filters: IDataFilters = {
            personId,
            organisationId,
            startDate: this.startDate,
            endDate: this.endDate,

            changeTypeAdded: this.changeTypeAdded,
            changeTypeDeleted: this.changeTypeDeleted,
            changeTypeModified: this.changeTypeModified,

            dataFilter: this.dataFilter,
            entIdFilter: this.entIdFilter,
            entTypeFilter: this.entTypeFilter,
        };

        this.dataList = await this.auditService.getFilteredData(filters);
    }

    @Autobind
    public personFilter(person: Person) {
        // connections may not be present if loading page directly
        if (!this.organisation || person.connections.length === 0) {
            return true;
        }

        return person.connections.some((c) => c.organisationId === this.organisation?.organisationId);
    }
}
