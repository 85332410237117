import { Injectable } from "@angular/core";
import { TableClient, TableEntityResult } from "@azure/data-tables";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { StakeholderServicesService } from "app/features/tools/stakeholder-services.service";
import moment from "moment";
import { AuthAuditFilterBuilder } from "./authAuditFilterBuilder";
import { DataAuditFilterBuilder } from "./dataAuditFilterBuilder";

export interface IAuthAudit {
    Action: string;
    Origin: string;
    Reason: string;
    Result: string;
}

export interface IAuthFilters {
    userName: string;
    startDate: Date;
    endDate: Date;

    actionLogin: boolean;
    actionLogout: boolean;

    resultSuccess: boolean;
    resultFailure: boolean;

    reasonUnknownUsr: boolean;
    reasonIncorrectPswd: boolean;
    reasonOther: boolean;
}

export interface IDataAudit {
    ChangeType: string;
    Data: string;
    EntityId: string;
    EntityType: string;
    PersonId: string;
    UserAgent: string;
}

export interface IDataFilters {
    personId: number;
    organisationId: number;
    startDate: Date;
    endDate: Date;

    changeTypeAdded: boolean;
    changeTypeDeleted: boolean;
    changeTypeModified: boolean;

    dataFilter: string;
    entIdFilter: string;
    entTypeFilter: string;
}

export interface IDataAudit {
    PartitionKey: string;
    RowKey: string;
}

@Injectable({
    providedIn: "root",
})
export class AuditService {
    private authSasToken?: string;
    private dataSasToken?: string;
    private authTableName = AdaptClientConfiguration.AuthAuditTableName;
    private dataTableName = AdaptClientConfiguration.DataAuditTableName;
    private tablesUrl = AdaptClientConfiguration.IsCurrentEmbedApiBaseUri
        ? AdaptClientConfiguration.EmbedAzureTablesUrl
        : AdaptClientConfiguration.AltoAzureTablesUrl;
    private maxQuerySize = 1000;

    public constructor(private stakeholderServices: StakeholderServicesService) { }

    public async getFilteredAuths(filters: IAuthFilters) {
        if (!this.authSasToken || !this.sasIsCurrent(this.authSasToken)) {
            this.authSasToken = await this.stakeholderServices.generateAuthAuditTableSas();
        }

        const tableClient = new TableClient(`${this.tablesUrl}?${this.authSasToken}`, this.authTableName);

        const filter = new AuthAuditFilterBuilder()
            .setStartDate(filters.startDate)
            .setEndDate(filters.endDate)
            .setUserName(filters.userName)
            .setActions(filters.actionLogin, filters.actionLogout)
            .setReasons(filters.reasonOther, filters.reasonUnknownUsr, filters.reasonIncorrectPswd)
            .setResults(filters.resultSuccess, filters.resultFailure)
            .build();

        const listResults = tableClient.listEntities<IAuthAudit>({
            queryOptions: { filter },
        });

        let authList: TableEntityResult<IAuthAudit>[] = [];

        const iterator = listResults.byPage({ maxPageSize: this.maxQuerySize });

        for await (const page of iterator) {
            authList = page;
            break;
        }
        return authList;
    }

    public async getFilteredData(filters: IDataFilters) {
        if (!this.dataSasToken || !this.sasIsCurrent(this.dataSasToken)) {
            this.dataSasToken = await this.stakeholderServices.generateDataAuditTableSas();
        }

        const tableClient = new TableClient(`${this.tablesUrl}?${this.dataSasToken}`, this.dataTableName);

        const filter = new DataAuditFilterBuilder()
            .setStartDate(filters.startDate)
            .setEndDate(filters.endDate)
            .setPersonId(filters.personId)
            .setChangeTypes(filters.changeTypeAdded, filters.changeTypeModified, filters.changeTypeDeleted)
            .setData(filters.dataFilter)
            .setEntityIds(filters.entIdFilter)
            .setEntityTypes(filters.entTypeFilter)
            .setOrganisationId(filters.organisationId)
            .build();

        const listResults = tableClient.listEntities<IDataAudit>({
            queryOptions: { filter },
        });

        let dataList: TableEntityResult<IDataAudit>[] = [];

        const iterator = listResults.byPage({ maxPageSize: this.maxQuerySize });

        for await (const page of iterator) {
            dataList = page;
            break;
        }
        return dataList;
    }

    private sasIsCurrent(sasToken: string) {
        const params = new URLSearchParams(sasToken);
        const expiryTime = params.get("se");
        if (!expiryTime) {
            return false;
        }

        return moment.utc(expiryTime).isAfter(new Date());
    }
}
