import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Practitioner } from "@common/ADAPT.Common.Model/practitioner/practitioner";
import { CoachService } from "app/features/coaches/coach.service";

@Component({
    selector: "adapt-display-simple-organisation-list",
    templateUrl: "./display-simple-organisation-list.component.html",
})
export class DisplaySimpleOrganisationListComponent implements OnChanges {
    @Input() public coach!: Practitioner;

    public organisations: Organisation[] = [];

    constructor(
        private coachService: CoachService) { }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.coach.currentValue) {
            this.coachService.getAllOrganisationsForCoach(changes.coach.currentValue)
                .subscribe((organisations) => this.organisations = organisations);
        } else {
            throw new Error("todo");
        }
    }
}
