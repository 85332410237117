import { Injectable, Injector } from "@angular/core";
import { Partner, PartnerBreezeModel } from "@common/ADAPT.Common.Model/partner/partner";
import { Practitioner, PractitionerBreezeModel } from "@common/ADAPT.Common.Model/practitioner/practitioner";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { BaseService } from "@common/service/base.service";

@Injectable({
    providedIn: "root",
})
export class PartnerService extends BaseService {

    constructor(
        injector: Injector,
    ) {
        super(injector);
    }

    public getAllPartners() {
        return this.commonDataService.getAll(PartnerBreezeModel);
    }

    public getAllCoachesForPartner(partner: Partner) {
        const predicate = new MethodologyPredicate<Practitioner>("partnerId", "==", partner.partnerId);
        return this.commonDataService.getByPredicate(PractitionerBreezeModel, predicate);
    }
}
