<div>
    <div role="alert"
         class="alert alert-info">Completely remove an organisation from embedADAPT</div>

    <div role="alert"
         class="alert alert-warning">
        <h3>*** Note: This operation is not recoverable. ***</h3>
    </div>

    <form>
        <div class="row">
            <div class="col-12 col-lg-8">
                <div class="form-group">
                    <h4>Organisation</h4>
                    <adapt-select-organisation [(organisation)]="organisation"></adapt-select-organisation>
                    <div *ngIf="organisation">
                        <p><b>OrganisationId: </b><span>{{organisation.organisationId}}</span></p>
                        <p><b>URLIdentifier: </b><span>{{organisation.urlIdentifier}}</span></p>
                    </div>
                </div>
                <div class="form-group">
                    <adapt-check-my-maths (isMyMathsOk)="isMyMathsOk=$event">
                    </adapt-check-my-maths>
                </div>
            </div>
        </div>

        <button adaptButton="delete"
                [adaptBlockingClick]="deleteOrg"> Organisation</button>

        <div *ngIf="error"
             role="alert"
             class="alert alert-danger">{{error}}</div>
    </form>
</div>
