import { Component, Input } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { StakeholderServicesService } from "app/features/tools/stakeholder-services.service";
import { from } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";

@Component({
    selector: "adapt-reset-password",
    templateUrl: "./reset-password.component.html",
})
export class ResetPasswordComponent extends BaseComponent {
    @Input() public person?: Person;

    public password = "";
    public confirmPassword = "";

    constructor(private stakeholderService: StakeholderServicesService, private commonDialogService: AdaptCommonDialogService) {
        super();
    }

    @Autobind
    public resetPassword() {
        if (this.password !== this.confirmPassword) {
            return this.commonDialogService.showErrorDialog("Failed to reset", "Passwords must match!", "OK");
        }

        if (this.password.length < 5) {
            return this.commonDialogService.showErrorDialog("Failed to reset", "Password must be at least 5 characters!", "OK");
        }

        const data = {
            userId: this.person?.userId,
            password: this.password,
        };

        return from(this.stakeholderService.resetPassword(data))
            .pipe(
                switchMap(() => {
                    this.password = "";
                    this.confirmPassword = "";
                    return this.commonDialogService.showMessageDialog("Success", "Password reset!");
                }),
                catchError((e) => {
                    const error = ErrorHandlingUtilities.getHttpResponseMessage(e);
                    const message = "Unable to reset password: " + error;

                    return this.commonDialogService.showErrorDialog("Failed", message, "OK");
                }),
            );
    }
}
