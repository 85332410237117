import { HttpErrorResponse } from "@angular/common/http";
import { Component, Injector, OnInit } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { of } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { StakeholderServicesService } from "../stakeholder-services.service";

@Component({
    selector: "adapt-trigger-survey-scheduler",
    templateUrl: "./trigger-survey-scheduler.component.html",
})
export class TriggerSurveySchedulerComponent extends BaseRoutedComponent implements OnInit {
    public surveyWebjobTriggerResult = "";

    public constructor(
        private stakeholderServices: StakeholderServicesService,
        private dialogService: AdaptCommonDialogService,
        injector: Injector,
    ) {
        super(injector);
    }

    public ngOnInit() {
        this.notifyActivated();
    }

    @Autobind
    public triggerWebjob() {
        // warn about potential conflicts if you are running this at the same time as the webjob (staging/production only)
        // this is very much a rough heuristic of us assuming that the webjob runs every half hour, for a couple of minutes only.
        return of(new Date().getMinutes()).pipe(
            switchMap((minutes) => {
                if ((minutes < 5) || (minutes > 55) || (minutes > 25 && minutes < 35)) {
                    return this.dialogService.openConfirmationDialog({
                        title: "Possibly overlapping with WebJob",
                        message: "Are you sure you wish to trigger this?<br /><br />"
                            + "It is likely that the WebJob is already running (or just finished running).<br />"
                            + "On production instances be very careful not to run this twice as it may generate multiple survey recipient emails.",
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                    } as IConfirmationDialogData);
                } else {
                    return of(true);
                }
            }),
            switchMap(() => this.stakeholderServices.triggerSurveySchedulerWebjob()),
            tap((result) => this.surveyWebjobTriggerResult = result),
            catchError((error: HttpErrorResponse) => this.surveyWebjobTriggerResult = error.error),
        );
    }
}
