import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AdaptCoachAccessSharedModule } from "@common/coach-access/coach-access.module";
import { AdaptPaymentProcessingModule } from "@common/payment-processing/payment-processing.module";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptEntityValidatorModule } from "@common/ux/entity-validator/entity-validator.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptMenuModule } from "@common/ux/menu/menu.module";
import { AdaptSelectCountryModule } from "@common/ux/select-country/select-country.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptAuditModule } from "app/audit/audit.module";
import { DxButtonGroupModule, DxCheckBoxModule, DxDataGridModule, DxDateBoxModule, DxNumberBoxModule, DxSelectBoxModule, DxTabPanelModule, DxTextAreaModule, DxTextBoxModule } from "devextreme-angular";
import { AdaptCoachSharedModule } from "../coaches/coach-shared.module";
import { AdaptPeopleModule } from "../people/people.module";
import { AdaptPeopleSharedModule } from "../people/people-shared.module";
import { AccountDetailsComponent } from "./account-details/account-details.component";
import { CoachSessionActionsComponent } from "./coach-session-actions/coach-session-actions.component";
import { DisplaySimpleOrganisationListComponent } from "./display-simple-organisation-list/display-simple-organisation-list.component";
import { EditCoachSessionDialogComponent } from "./edit-coach-session-dialog/edit-coach-session-dialog.component";
import { LinkOrganisationEmbedComponent } from "./link-organisation-embed/link-organisation-embed.component";
import { OrganisationDetailsComponent } from "./organisation-details/organisation-details.component";
import { OrganisationPageComponent } from "./organisation-page/organisation-page.component";
import { organisationPageRoute } from "./organisation-page/organisation-page.route";
import { OrganisationsDashboardPageComponent } from "./organisations-dashboard-page/organisations-dashboard-page.component";
import { organisationsDashboardPageRoute } from "./organisations-dashboard-page/organisations-dashboard-page.route";
import { OrganisationsListComponent } from "./organisations-list/organisations-list.component";
import { AdaptOrganisationsSharedModule } from "./organisations-shared.module";
import { PathwaysComponent } from "./pathways/pathways.component";
import { PaymentsComponent } from "./payments/payments.component";
import { StartSubscriptionDialogComponent } from "./start-subscription-dialog/start-subscription-dialog.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        HttpClientModule,
        AdaptButtonModule,
        AdaptLoadingSpinnerModule,
        FormsModule,
        ReactiveFormsModule,
        AdaptDateModule,
        AdaptPaymentProcessingModule,
        AdaptEntityValidatorModule,
        AdaptSelectCountryModule,
        AdaptHtmlEditorModule,
        DxDataGridModule,
        DxTextBoxModule,
        DxTextAreaModule,
        DxNumberBoxModule,
        DxSelectBoxModule,
        DxCheckBoxModule,
        DxDateBoxModule,
        DxButtonGroupModule,
        AdaptCoachSharedModule,
        AdaptOrganisationsSharedModule,
        DxTabPanelModule,
        AdaptPeopleModule,
        AdaptShellModule,
        AdaptPaymentProcessingModule,
        AdaptCommonDialogModule,
        AdaptTooltipModule,
        AdaptAuditModule,
        AdaptPeopleSharedModule,
        AdaptMenuModule,
        AdaptCoachAccessSharedModule,
    ],
    exports: [
        LinkOrganisationEmbedComponent,
        DisplaySimpleOrganisationListComponent,
        PathwaysComponent,
    ],
    declarations: [
        PaymentsComponent,
        PathwaysComponent,
        LinkOrganisationEmbedComponent,
        DisplaySimpleOrganisationListComponent,
        AccountDetailsComponent,
        OrganisationDetailsComponent,
        OrganisationsListComponent,
        OrganisationsDashboardPageComponent,
        OrganisationPageComponent,
        StartSubscriptionDialogComponent,
        EditCoachSessionDialogComponent,
        CoachSessionActionsComponent,
    ],
})
export class AdaptOrganisationsModule {
    public static readonly Routes = [
        ...organisationPageRoute.routes,
        ...organisationsDashboardPageRoute.routes,
    ];
}
