<div adaptToolbarContent>
    <button adaptButtonIcon="add"
            [adaptBlockingClick]="addNewPartner">Add new strategic partner
    </button>
</div>

<dx-data-grid [dataSource]="partners"
              [allowColumnReordering]="true"
              [allowColumnResizing]="true"
              [columnAutoWidth]="true"
              [rowAlternationEnabled]="true">

    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>

    <dxi-column dataField="partner.partnerId"
                caption="Id"
                width="80"></dxi-column>
    <dxi-column dataField="partner.name"
                caption="Name"
                width="380"></dxi-column>
    <dxi-column dataField="coaches"
                dataType="string"
                cellTemplate="coachesCellTemplate"
                caption="Coaches"></dxi-column>
    <dxi-column dataField="partner.notes"
                dataType="string"
                caption="Notes"
                cellTemplate="richTextTemplate"
                [width]="500"></dxi-column>
    <dxi-column dataField="partnerEdit"
                dataType="string"
                cellTemplate="editPartnerTemplate"
                caption="Edit Partner"></dxi-column>

    <div *dxTemplate="let item of 'coachesCellTemplate'">
        <div *ngFor="let coach of item.data.coaches">
            <adapt-link-coach [coach]="coach"></adapt-link-coach>
        </div>
    </div>

    <div *dxTemplate="let data of 'richTextTemplate'">
        <div [froalaView]="data.value"></div>
    </div>

    <div *dxTemplate="let item of 'editPartnerTemplate'">
        <button adaptButtonIcon="edit"
                (click)="editPartner(item.data.partner)">Edit</button>
        <button adaptButtonIcon="delete"
                (click)="deletePartner(item.data.partner)">Delete</button>
    </div>
</dx-data-grid>
