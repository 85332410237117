import { Component, Input, OnInit } from "@angular/core";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { DateFormats } from "@common/ux/date-formats";
import { PeopleService } from "app/features/people/people.service";
import { switchMap, tap } from "rxjs/operators";
import { CoachService } from "../coach.service";
import { CoachUiService } from "../coach-ui.service";

@Component({
    selector: "adapt-coach-relationships",
    templateUrl: "./coach-relationships.component.html",
})
export class CoachRelationshipsComponent extends BaseComponent implements OnInit {

    @Input() public organisation?: Organisation;

    public connections: Connection[] = [];
    public dateFormat = DateFormats.globalize.short;

    constructor(
        private coachService: CoachService,
        private coachUiService: CoachUiService,
        private peopleService: PeopleService,
        rxJsBreezeService: RxjsBreezeService,
    ) {
        super();

        rxJsBreezeService.entityTypeChangedInSave(Connection).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.updateData());
    }

    public ngOnInit() {
        this.peopleService.getPeopleForOrganisationId(this.organisation!.organisationId)
            .pipe(
                switchMap(() => this.updateData()),
                this.takeUntilDestroyed(),
            ).subscribe();
    }

    @Autobind
    public addNewCoach() {
        if (!this.organisation) {
            throw new Error("Org should be defined");
        }

        return this.coachUiService.addNewCoachConnection(this.organisation).pipe(
            switchMap(() => this.updateData()),
            this.takeUntilDestroyed(),
        );
    }

    public calculateActiveCellValue(data: Connection) {
        return data.isActive();
    }

    public editPractitionerConnection(connection: Connection) {
        return this.coachUiService.editCoachConnection(connection).pipe(
            switchMap(() => this.updateData()),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public endCoachConnection(connection: Connection) {
        return this.coachUiService.endCoachConnection(connection).pipe(
            switchMap(() => this.updateData()),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public deletePractitionerConnection(connection: Connection) {
        return this.coachUiService.deleteCoachConnection(connection).pipe(
            switchMap(() => this.updateData()),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    private updateData() {
        return this.coachService.getAllCoachConnectionsForOrganisation(this.organisation!.organisationId).pipe(
            tap((result) => this.connections = result),
        );
    }
}
